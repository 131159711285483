import React from "react";
import styles from "./ImeiLookUpComponent.module.scss";
import { Box, Typography } from "@mui/material";
const ImeiComponent = (props: any) => {
  const { content } = props;
  return (
    <>
      <Box className={styles.activation_bottom}>
        <Box>
          {props?.isTitle ? (
            <Typography
              className={styles.activation_bottom_text1}
              sx={{
                mb: "5px",
                fontFamily: " var(--font_family_Semibold)",
              }}
            >
              {content?.title}
            </Typography>
          ) : null}

          <Typography
            className={styles.activation_bottom_text1}
            sx={{ fontFamily: " var(--font_family_Medium)" }}
          >
            {content?.desc}
          </Typography>
        </Box>

        <Box width={{ xs: "90%", sm: "fit-content" }}>
          {content?.iemiContent?.map((data: any) => {
            return (
              <Box
                className={styles.activation_version_container}
                mt={"20px"}
                key={data?.id}
              >
                <img
                  width={23}
                  height={23}
                  className={styles.os_img}
                  src={data?.img?.data?.attributes?.url}
                  alt={data?.img?.data?.attributes?.alternativeText}
                />
                <Box>
                  <Typography className={styles.os_text_1}>
                    {data?.title}
                  </Typography>
                  <Typography className={styles.os_text_2}>
                    {data?.desc}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default ImeiComponent;
