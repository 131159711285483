import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ActivationStep1 } from "./ActivationStep1/ActivationStep1";
import { ActivationStep2 } from "./ActivationStep2/ActivationStep2";
import { ActivationStep3 } from "./ActivationStep3/ActivationStep3";
import { ActivationStep4 } from "./ActivationStep4/ActivationStep4";
import { InitialActivation } from "./InitialActivation/InitialActivation";
import { InitialSecondayLine } from "./InitialActivation/InitialSecondayLine";
import { Grid } from "@mui/material";
import { DeeperStepperCust } from "../../widgets";
import ENVConfig from "../../../config/env.config.json";
import styles from "./S1_Activation.module.scss";
import T2_InitialActivation from "./InitialActivation/T2_InitialActivation";
import { useAppDispatch } from "../../../app/hooks";
import {
  clearOldLineData,
  showPaySuccessModalFn,
} from "../../../features/activation/activationSlice";
import SecondaryActivationData from "./InitialActivation/SecondaryActivationData";

export const ActivationSteps = (props: any) => {
  const {
    currentLine,
    activationIntroContent,
    step1Content,
    step2Content,
    step3Content,
    step4Content,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //payment check during siminhand flag
  const urlSearchParams = new URLSearchParams(window.location.search);
  const transId: any = urlSearchParams.get("transId");
  const x_rescode: any = urlSearchParams.get("x_response_code");
  const { simId, isMonthInAdvancePaid, isSimInHand } = currentLine;

  const currentStep = () => {
    let addCardActivation = localStorage.getItem("addCardActivation");
    let onTimeActivation = localStorage.getItem("oneTimeactivation");
    if (addCardActivation) {
      return 3;
    } else if (onTimeActivation) {
      return 1;
    } else if (currentLine?.esim) {
      return 0;
    } else {
      if (!currentLine?.imei) {
        return 0;
      } else if (!currentLine?.simId) {
        return 0;
      } else if (transId && x_rescode !== "0") {
        dispatch(showPaySuccessModalFn(true));
        return 4;
      } else if (
        simId &&
        isSimInHand &&
        !isMonthInAdvancePaid &&
        ENVConfig.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND &&
        ENVConfig.IS_MULTI_SIM_IN_HAND_ENABLED
      ) {
        return 0; 
      } else if (
        simId &&
        isSimInHand &&
        !isMonthInAdvancePaid &&
        ENVConfig.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND
      ) {
        return 2;
      } else if (simId && isSimInHand && !isMonthInAdvancePaid) {
        return 3;
      } else if (simId && !isMonthInAdvancePaid && currentLine?.simType === 'PHYSICAL' && !isSimInHand) {
        return 0;
      } else if (currentLine?.simId) {
        return 4;
      } else if (currentLine?.imei && currentLine?.zipcode) {
        return 3;
      } else if (currentLine?.imei) {
        return 2;
      } else {
        return 0;
      }
    }
  };
  const [step, setStep] = useState(() => currentStep());
  const [paymentDetails, setPaymentDetails] = useState<null | number>(null);
  const handleStep = (type: boolean) => {
    const currentStepNo = type ? step + 1 : step > 0 ? step - 1 : navigate(-1);
    setStep(currentStepNo || 0);
  };
  const [activationStepperContent, setActivationStepperContent] = useState<any>(
    [
      {
        title: "Initiate SIM activation",
        levels: 4,
        isFailing: false,
        currentLevel: 1,
      },
      {
        title: "Activation in progress",
        levels: 4,
        isFailing: false,
        currentLevel: 0,
      },
      {
        title: "Process your payment",
        levels: 4,
        isFailing: false,
        currentLevel: 0,
      },
      {
        title: "Payment successful",
        levels: 4,
        isFailing: false,
        currentLevel: 0,
      },
    ]
  );
  const stepperUpdater = (
    index: number,
    currentLevel?: number,
    isFailing?: boolean
  ) => {
    let newActivationSteps = [...activationStepperContent];
    newActivationSteps[index]["currentLevel"] = currentLevel;
    if (isFailing !== undefined) {
      newActivationSteps[index]["isFailing"] = isFailing;
    }
    setActivationStepperContent(newActivationSteps);
  };
  useEffect(() => {
    dispatch(clearOldLineData());
  }, []);
  useEffect(() => {
    StepperLevel();
  }, [step, paymentDetails]);
  const StepperLevel = () => {
    if (step === 0) {
      stepperUpdater(0, 1);
      stepperUpdater(1, 0);
      stepperUpdater(2, 0);
    }
    //in step 1, 2, 3
    else if (step === 1 || step === 2 || paymentDetails === 3) {
      stepperUpdater(0, 4);
      stepperUpdater(1, 0);
      stepperUpdater(2, 0);
    }
    //In payment screen
    else if (paymentDetails === 4) {
      stepperUpdater(0, 4);
      stepperUpdater(1, 4);
      stepperUpdater(2, 0);
    }
    //once payment done
    else if (paymentDetails === 5) {
      stepperUpdater(0, 4);
      stepperUpdater(1, 4);
      stepperUpdater(2, 4);
    }
  };
  let level1 = 1;
  let level2 = 0;
  let level3 = 0;
  const currentLevels = [level1, level2, level3];
  const updatedActivatedStepperContent = activationStepperContent.map(
    (obj: any, index: any) => {
      return {
        ...obj,
        currentLevel: currentLevels[index],
      };
    }
  );
  /**
   * stepper handler for SIM in hand journey
   */
  useEffect(() => {
    if (isSimInHand && simId && ENVConfig.IS_SIM_IN_HAND_ENABLED) {
      if (!isMonthInAdvancePaid || step === 3) {
        setPaymentDetails(4);
      }
      if (isMonthInAdvancePaid && step === 4) {
        setPaymentDetails(5);
      }
    }
  }, [currentLine, step]);
  return (
    <>
      {ENVConfig.IS_DISPLAY_STEPPER_IN_CHECKOUT && (
        <Grid item xs={12} pt={"56px"}>
          <DeeperStepperCust
            steps={activationStepperContent}
            stepperBarClassName={styles.stepper_cust_bar}
          />
        </Grid>
      )}
      {step === 0 &&
        (currentLine?.isPrimary ? (
          <InitialActivation
            handleStep={handleStep}
            content={activationIntroContent}
            {...props}
          />
        ) : (
          currentLine?.id ? 
          <InitialSecondayLine
            handleStep={handleStep}
            content={activationIntroContent}
            {...props}
          />
          : 
            <SecondaryActivationData currentLine={currentLine} content={activationIntroContent} handleStep={handleStep}/> 
        ))}

      {step === 1 && (
        <ActivationStep1
          handleStep={handleStep}
          content={step1Content}
          {...props}
        />
      )}
      {step === 2 && (
        <ActivationStep2
          handleStep={handleStep}
          content={step2Content}
          {...props}
        />
      )}
      {step === 3 && (
        <ActivationStep3
          handleStep={handleStep}
          content={step3Content}
          step4Content={step4Content}
          modalContent={step2Content}
          setPaymentDetails={setPaymentDetails}
          {...props}
        />
        // <PaymentLanding key="PaymentLanding"/>
      )}
      {step === 4 && (
        <ActivationStep4
          handleStep={handleStep}
          content={step4Content}
          modalContent={step2Content}
          setPaymentDetails={setPaymentDetails}
          {...props}
        />
      )}
    </>
  );
};

export default ActivationSteps;
