import styles from "./S1_CustomerService.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import EnvConfig from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useNavigate } from "react-router-dom";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import clsx from "clsx";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { LazyLoadImageCust } from "../../widgets";
import { useAppSelector } from "../../../app/hooks";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

export type CustomerServiceProps = {
  customerSupportContent?: any;
};

const CustomerServiceComponent = (props: CustomerServiceProps) => {
  const { title, description, button_1, button_2, image, nav_link } =
    props?.customerSupportContent || {};
  const navigate = useNavigate();
  const GAAndFBEventsHandler = (category: string, value: string) => {
    EnvConfig.IS_GA_EVENTS_REQUIRED &&
      pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
        appName: EnvConfig.brand,
        screenName: gaScreenNames.plan,
        category: category,
        buttonValue: value,
      });
  };
  return (
    props?.customerSupportContent && (
      <Grid
        container
        className={clsx(styles.cs_container)}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        // sx={{
        //   p: {
        //     xs: "calc(var(--mobile_section_padding)/2) 25px",
        //     sm: "calc(var(--ipad_section_padding)/2) 0",
        //     md: "calc(var(--desktop_section_padding)/2) 50px",
        //   },
        // }}
        sx={{
          px: { xs: "10px", md: 8 },
          my: { xs: 0.1, md: 10 },
          py: { xs: 1, sm: 4 },
        }}
      >
        <Grid
          item
          md={6}
          className={styles.content_section}
          sx={{
            m: "20px 0",
            px: { xs: "16px", sm: "35px" },
            display: { xs: "flex", md: "block" },
            justifyContent: { md: "space-around", xs: "center" },
            alignItems: "flex-start",
            flexDirection: { xs: "column" },
            
          }}
          order={{ xs: 2, md: 2 }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: {
                xs: "center",
                md: "left",
              },
              my: "15px",
              color: "var(--dark-charcoal-fade)",
              lineHeight: { xs: "30px", sm: "44px" },
              fontSize: { xs: "20px", md: "30px" },
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {BrandNameUpdate(title)}
          </Typography>
          <Typography
            component="div"
            sx={{
              textAlign: { xs: "center", md: "left" },
              fontSize: "16px",
              fontFamily: "var(--font_family_Regular)",
              color: "var(--text_color)",
              lineHeight: { xs: "28px" },
              my: "15px",
            }}
          >
            {BrandNameUpdate(description)}
          </Typography>
          {/* <Grid
            item
            container
            xs={12}
            className={styles.btn_section}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", md: "start" },
            }}
          > */}
            <Box
              sx={{
                // m: { xs: "10px auto", sm: "20px auto", lg: "0 auto" },
                // mr: { sm: "20px" },
                // my: { xs: "10px", lg: "0" },
                width: "250px",
              }}
              // display="flex"
              // justifyContent="center"
            >
              {button_1 && (
                <ButtonCust
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    if (nav_link) {
                      window.open(nav_link);
                    } else {
                      window.open(RoutingURLs.chatbot, "_self");
                      setTimeout(() => {
                        window.history.replaceState(
                          null,
                          "",
                          location.pathname
                        );
                      }, 1000);
                    }
                  }}
                  className={styles.btn_cmp1}
                >
                  {button_1}
                </ButtonCust>
              )}
            </Box>
          {/* </Grid> */}
        </Grid>
        <Grid
          item
          md={6}
          order={{ xs: 2, md: 3 }}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <LazyLoadImageCust
            alt={image?.data?.attributes?.alternativeText}
            src={image?.data?.attributes?.url || ""}
            classNames={styles.byod_home_img}
          />
        </Grid>
      </Grid>
    )
  );
};
export default CustomerServiceComponent;
