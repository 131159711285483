import { FormControlLabel, FormGroup, Switch } from "@mui/material";

type SwitchProps = {
  checked: boolean;
  handleSwitchToggle?: any;
  label?: any;
  isDisabled?: boolean;
  styles?: any;
  name?: string;
  sx?: any;
};

export const SwitchCust = (props: SwitchProps) => {
  const { checked, handleSwitchToggle, label, isDisabled, styles, name } =
    props;
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={checked}
            onChange={handleSwitchToggle}
            inputProps={{ "aria-label": "controlled" }}
            disabled={isDisabled || false}
          />
        }
        {...(label && { label: label })}
        sx={{
          borderRadius: 2,
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "var(--primary_brand_color)",
          },
          "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "var(--primary_brand_color_light)",
          },
          "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
            color: "var(--primary_brand_color_light)",
            opacity: "0.4",
          },
          "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
            opacity: "0.2",
            cursor: "not-allowed",
          },
          width: "max-content",
          ...styles,
          ...props?.sx
        }}
      />
    </FormGroup>
  );
};
