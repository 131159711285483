import { useState, useEffect } from "react";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import Config from "../../config/app.config.json";
import EnvConfig from "../../config/env.config.json";

import { SimTypes } from "../../features/checkout/Types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CarrierTypes } from "../../enums";
import { updateCartPayload } from "../../utils";
import { updateCartItems } from "../../features/shopWare/shopWareSlice";

const ChangeSimPreferenceCmp = isTemplateExisits(
  Config.Checkout.ChangeSimPreference
)
  ? require(`./${Config.Checkout.ChangeSimPreference.template}_ChangeSimPreferenceDialog`)
      .default
  : null;

export interface ChangeSimPreferenceProps {
  open?: boolean;
  onClose: any;
  content: any;
  simPreference: Array<any>;
  onChangeSimPreferenceHandler?: any;
  eSimClicked?: boolean;
  showConfirm?: boolean;
  same?: boolean;
  simClicked?: boolean;
  confirmHandler?: any;
  changeSimSelectionHandler?: any;
  setSimPreference: any;
  sameArr?: any;
  setSameArr?: any;
  checkCompatibility: any;
  simCharges: number;
}
export const ChangeSimPreferenceDialog = (props: ChangeSimPreferenceProps) => {
  const {
    changeSimSelectionHandler,
    simPreference,
    setSimPreference,
    onClose,
    sameArr,
    setSameArr,
  } = props;
  const dispatch = useAppDispatch();
  const { planDetails } = useAppSelector((state: any) => state.checkout);
  const [same, setSame] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(true);
  const [eSimClicked, setESimClicked] = useState<boolean>(false);
  const [simClicked, setSimClicked] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    const isEsimSelected = simPreference?.some(
      (data: any) => data.value === SimTypes.eSim
    );
    const isSimSelected = simPreference?.some(
      (data: any) => data.value === SimTypes.sim
    );
    if (isEsimSelected) setESimClicked(true);
    else setESimClicked(false);
    if (isSimSelected) setSimClicked(true);
    else setSimClicked(false);
  }, [simPreference]);
  useEffect(() => {
    let length: number = Object.keys(sameArr).length;
    for (let i = 0; i < length; i++) {
      if (Object.values(sameArr)[i] === false) {
        setSame(false);
      }
    }
  }, [sameArr]);
  const handleConfirmHideAndShow = (updatedSimPreferenceStore: any) => {
    let simPreferenceStoreData: any = JSON.parse(
      localStorage.getItem("simPreference") || "null"
    );
    // Set isSimDiff to false initially
    let isSimDiff = false;
    // Loop through each item in updatedSimPreferenceStore
    for (let i = 0; i < updatedSimPreferenceStore.length; i++) {
      const updatedItem = updatedSimPreferenceStore[i];
      const id = updatedItem.id;
      // Find the corresponding item in simPreferenceStore with the same ID
      const originalItem = simPreferenceStoreData.find(
        (item: any) => item.id === id
      );
      // If the eSimOpted property is true in updatedItem but not in originalItem, set isSimDiff to true
      if (updatedItem.eSimOpted && (!originalItem || !originalItem.eSimOpted)) {
        isSimDiff = true;
        break; // Exit the loop early since we've already found a difference
      }
    }
    setShowConfirm(!isSimDiff);
  };
  const onChangeSimPreferenceHandler = (e: any, _name: any, index: any) => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);

    let obj: any = {};
    let localState: boolean = true;
    simPreferenceStorage.map((el: any) => {
      if (el.id === index) {
        if (el.value === e.target.value) obj = { [index]: true };
        else {
          if (e.target.value === SimTypes.sim) obj = { [index]: true };
          else obj = { [index]: false };
        }
      }
      return el;
    });
    setSameArr({ ...sameArr, ...obj });
    setSame(localState);
    const updatedSimPreference = simPreference.map((data: any) => {
      if (data.id === index) {
        setCount(count + 1);
        return {
          ...data,
          eSimOpted: _name === SimTypes.eSim ? true : false,
          eSimCompatible:
            _name === SimTypes.eSim
              ? EnvConfig.OPERATOR.toLowerCase() === CarrierTypes.ATT
                ? true
                : simPreferenceStorage?.[index]?.eSimCompatible
              : simPreferenceStorage?.[index]?.eSimCompatible,
          value: _name,
          imei: simPreferenceStorage?.[index]?.imei || "",
          make: simPreferenceStorage?.[index]?.make || "",
          model: simPreferenceStorage?.[index]?.model || "",
        };
      }
      return data;
    });
    setSimPreference(updatedSimPreference);
  };
  const confirmHandler = () => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    localStorage.setItem("simPreference", JSON.stringify(simPreference));
    localStorage.setItem("simSelection", JSON.stringify({ esim: false }));
    let payload = updateCartPayload();
    dispatch(updateCartItems(payload));
    setShowConfirm(true); // setting back to confirm button on confirm check coverage evet
    changeSimSelectionHandler();
    onClose();
  };
  const closeSimPreferenceDialog = () => {
    /**
     * Setting back previous data when user click on close dialog
     */
    let simPreferenceStorage: any = JSON.parse(
      localStorage.getItem("simPreference") || "null"
    );
    setShowConfirm(true); // setting back to confirm button on close sim preference dialog
    setSimPreference(simPreferenceStorage);
    onClose();
  };
  return (
    <ChangeSimPreferenceCmp
      onChangeSimPreferenceHandler={onChangeSimPreferenceHandler}
      eSimClicked={eSimClicked}
      same={same}
      showConfirm={showConfirm}
      simClicked={simClicked}
      confirmHandler={confirmHandler}
      {...props}
      onClose={closeSimPreferenceDialog}
    />
  );
};
