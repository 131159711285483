import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import styles from "./S2_Terms.module.scss";
import { Box, Typography } from "@mui/material";

type Props = {
  content: any;
};
const TermsComponent = (props: Props) => {
  const {content} = props;
  return (
    <Box className={styles.terms_page_content}>
      <Typography variant="h1" className={styles.terms_title}>
        {content?.title}
      </Typography>
      {/* <Box className={styles.terms_line}></Box> */}
      <Box className={styles.content}>
        {content &&
          content?.Terms_content?.length > 0 &&
          content?.Terms_content?.map((items: any, index: number) => {
            return (
              <Box key={index}>
                <Typography className={styles.terms_headings}>
                  {index + 1}. {BrandNameUpdate(items?.heading)}
                </Typography>
                <Box
                  className={styles.terms_para}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(items?.description),
                  }}
                ></Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
export default TermsComponent;
