import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import styles from "./ManageCards.module.scss";
import { ButtonCust } from "../Button/ButtonCust";
import {
  getIsCardExpired,
  getResolutionDetails,
} from "../../../utils/commonFunctions/ReusableFunctions";
import EnvConfig from "../../../config/env.config.json";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { EclipsedUsername } from "../Eclipsed";
import { CardTypes } from "../../../enums";
import { brandNames } from "../../../enums";

type ManageProps = {
  manageCardDetails?: any;
  isDescription?: any;
  getCustomer?: any;
  cardData?: any;
  payType?: any;
  selectOpenHandler?: any;
  openHandler?: any;
  addNewCardHandler?: any;
  getCardsInOrder?: any;
  isLoading?: boolean;
  isModalRequired?: any;
  ManageCardCloseHandler?: any;
  pageName?: any;
  isCardElevated?: boolean;
};
const getButtonVariant = () => {
  switch (EnvConfig.DOMAIN) {
    case "WOW":
      return "primary_contained_rounded";

    default:
      return "custom_secondary_transparent_brand_rounded";
  }
};
export const CardChangeModal = (props: ManageProps) => {
  const navigate = useNavigate();
  const {
    manageCardDetails,
    isDescription,
    cardData,
    payType,
    selectOpenHandler,
    addNewCardHandler,
    getCardsInOrder,
    openHandler,
    getCustomer,
    isLoading,
  } = props;
  const getAddNewStyles = () => {
    switch (EnvConfig.DOMAIN) {
      case brandNames.GHOST:
        return styles.add_new_card_ghost;
      case brandNames.WOW:
        return styles.add_new_card_wow;
      default:
        return "";
    }
  };
  const { mobile } = getResolutionDetails();
  return (
    <Box className={styles.manage_card_container}>
      <Typography className={styles.card_heading} my={"10px"}>
        {manageCardDetails?.heading}{" "}
      </Typography>

      {manageCardDetails?.description ? (
        <Typography
          className={styles.description}
          sx={{
            ...(EnvConfig.DOMAIN === "GHOST" && {
              mb: "20px",
            }),
            fontFamily: "var(--font_family_Regular)",
            fontSize: "16px",
            fontWeight: "var(--font_weight_0)",
            lineHeight: "30px !important",
            color: "var(--text_color)",
            my: { xs: "10px", md: "10px" },
            mx: {
              xs: "10px",
              md: manageCardDetails?.cncl_btn ? "20px" : "60px",
            },
          }}
        >
          {manageCardDetails?.description?.replace(
            "{0}",
            `${new Date(getCustomer?.nextBillingDate).getDate()}`
          )}
        </Typography>
      ) : null}
      <Card
        sx={{ width: "100%", py: "0px" }}
        elevation={props?.isModalRequired || !props?.isCardElevated ? 0 : 2}
      >
        <Box className={styles.bottom_section}>
          {cardData ? (
            <Typography
              className={styles.save_card_text}
              sx={{ ml: { xs: "0 !important", sm: "16px !important" } }}
            >
              {manageCardDetails?.save_cards}
            </Typography>
          ) : (
            <Typography className={styles.save_card_text}>
              {manageCardDetails?.no_cards}
            </Typography>
          )}
          <>
            <Box
              sx={
                props?.pageName === RoutingURLs?.billing
                  ? {
                      display: "flex",
                      gap: "20px",
                      flexWrap: "wrap",
                      flexDirection: { xs: "column", md: "row" },
                    }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: "10px", sm: "15px" },
                    }
              }
            >
              {cardData?.length > 0 &&
                getCardsInOrder(cardData).map((each: any, ind: number) => {
                  let cardIsExpired = getIsCardExpired(each);
                  return (
                    <Box
                      className={styles.number_container}
                      key={ind}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: {
                          xs: "99%",
                          sm: "95%",
                          md:
                            props?.pageName === RoutingURLs?.billing
                              ? "48%"
                              : "95%",
                        },
                        border: each.isDefault
                          ? "1px solid var(--primary_color)"
                          : "1px solid var(--card-color)",
                        borderRadius: "10px",
                        gap:
                          props?.pageName === RoutingURLs?.billing
                            ? "20px"
                            : "0px",
                      }}
                    >
                      <Stack
                        sx={{
                          py: "5px",
                          px: { xs: "8px", sm: "20px" },
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            alt="visa_image"
                            sx={{
                              width: { xs: "35px", sm: "47px" },
                              height: { xs: "24px", sm: "33px" },
                              marginRight: "10px",
                              alignSelf: "flex-start",
                            }}
                            src={
                              each?.type?.toUpperCase() === CardTypes?.VISA
                                ? manageCardDetails?.visa?.data?.attributes?.url
                                : each?.type?.toUpperCase() ===
                                  CardTypes?.MASTERCARD
                                ? manageCardDetails?.master_card?.data
                                    ?.attributes?.url
                                : each?.type?.toUpperCase() === CardTypes?.JCB
                                ? manageCardDetails?.jcb?.data?.attributes?.url
                                : each?.type?.toUpperCase() ===
                                    CardTypes?.AMERICANEXPRESS ||
                                  each?.type?.toUpperCase() === CardTypes?.AMEX
                                ? manageCardDetails?.american_express?.data
                                    ?.attributes?.url
                                : manageCardDetails?.discover?.data?.attributes
                                    ?.url
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              // className={styles.card_number}
                              sx={{
                                fontSize: {
                                  xs: "16px !important",
                                  sm: "18px !important",
                                },
                                fontFamily: "var(--font_family_Regular)",
                                color: "var(--black)",
                              }}
                            >
                              XXXX-XXXX-XXXX-{each.ccNumberMask}
                            </Typography>
                            {each.isDefault && payType && (
                              <Box
                                component="img"
                                src={
                                  manageCardDetails?.right_mark?.data
                                    ?.attributes?.url
                                }
                                alt="tick_icon"
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  alignSelf: "center",
                                  margin: {
                                    xs: "0px 0px 0px 10px",
                                    sm: "0px 10px 0px 10px",
                                  },
                                  paddingTop: "0px",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: manageCardDetails?.cncl_btn
                              ? "100%"
                              : "fit-content",
                          }}
                        >
                          <Typography
                            className={styles.expiry}
                            sx={{
                              fontWeight: "var(--font_weight_0)",
                              padding: manageCardDetails?.cncl_btn
                                ? "10px 5px 10px 0px"
                                : "0px",
                              display: "flex",
                            }}
                            mb="3px"
                          >
                            {manageCardDetails?.cncl_btn ? (
                              <>
                                <Box
                                  component={"span"}
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  <EclipsedUsername
                                    creditsCards={true}
                                    lineName={each?.nameOnCard}
                                  />
                                </Box>
                              </>
                            ) : (
                              <>{each && each.nameOnCard} &nbsp;</>
                            )}
                            {cardIsExpired ? (
                              <Box
                                component={"span"}
                                sx={{ color: "red", lineHeight: "28px" }}
                              >
                                |&nbsp;Expired date{" "}
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(0, 2)}
                                /
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(2)}
                              </Box>
                            ) : (
                              <Box
                                component={"span"}
                                className="cardNotExpired"
                                sx={{
                                  fontFamily: "var(--font_family_Regular)",
                                  lineHeight: "28px",
                                }}
                              >
                                |&nbsp;Expiration date{" "}
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(0, 2)}
                                /
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(2)}
                              </Box>
                            )}
                          </Typography>
                          <Divider
                            sx={{
                              width: "100%",
                            }}
                          />
                          {/* <Box
                        sx={{
                          opacity: "0.3",
                          borderTop: "1px solid var(--grey_color_1)",
                          margin: "0 auto 15px",
                        }}
                      ></Box> */}

                          {payType ? (
                            <Box
                              display="flex"
                              width={"100%"}
                              flexDirection={!each.isAutoPay ? "row" : "column"}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {!each.isAutoPay && (
                                <Typography
                                  onClick={() => {
                                    if (!cardIsExpired)
                                      selectOpenHandler(
                                        manageCardDetails?.set_default,
                                        each,
                                        true
                                      );
                                  }}
                                  className={styles.update_text}
                                  sx={{
                                    opacity: cardIsExpired ? 0.5 : 1,
                                    padding: manageCardDetails?.cncl_btn
                                      ? "12px  0px"
                                      : "0px",
                                    cursor: `${
                                      cardIsExpired
                                        ? "not-allowed !important"
                                        : "pointer"
                                    }`,
                                    pb: 0,
                                  }}
                                >
                                  {manageCardDetails?.set_default}
                                </Typography>
                              )}
                              <Typography
                                onClick={() => openHandler(each)}
                                className={styles.update_text}
                                sx={{
                                  padding: manageCardDetails?.cncl_btn
                                    ? "12px  0px"
                                    : "0px",
                                  marginLeft:
                                    each.isAutoPay &&
                                    manageCardDetails?.cncl_btn &&
                                    props?.pageName !== RoutingURLs?.billing
                                      ? "0px"
                                      : "0px",
                                  pb: 0,
                                }}
                              >
                                {manageCardDetails?.update}
                              </Typography>
                              {each.isAutoPay &&
                                EnvConfig.DOMAIN === "GHOST" && (
                                  <Box>
                                    <span className={styles.expiry}>
                                      {manageCardDetails?.auto_pay}
                                    </span>{" "}
                                    <span
                                      className={styles.update_text}
                                      onClick={() => {
                                        navigate(RoutingURLs.helpCenter);
                                      }}
                                    >
                                      {manageCardDetails?.cus_care}
                                    </span>
                                  </Box>
                                )}
                              {!each.isAutoPay && cardData?.length > 1 && (
                                <Typography
                                  onClick={() =>
                                    selectOpenHandler(
                                      manageCardDetails?.remove_card,
                                      each,
                                      false
                                    )
                                  }
                                  sx={{
                                    padding: manageCardDetails?.cncl_btn
                                      ? "12px  0px"
                                      : "0px",
                                    pb: 0,
                                  }}
                                  className={styles.update_text}
                                >
                                  {manageCardDetails?.remove}
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            !cardIsExpired && (
                              <Typography
                                className={styles.update_text}
                                onClick={() =>
                                  selectOpenHandler("", each, true)
                                }
                                sx={{
                                  padding: manageCardDetails?.cncl_btn
                                    ? "10px 0px 0px"
                                    : "0px",
                                }}
                              >
                                {manageCardDetails?.pay_using}
                              </Typography>
                            )
                          )}
                        </Box>
                        {/* {each.isDefault && payType && (
                      <Box
                        component="img"
                        src={
                          manageCardDetails?.right_mark?.data?.attributes?.url
                        }
                        alt="tick_icon"
                        sx={{
                          width: "20px",
                          height: "15px",
                          alignSelf: "center",
                          margin: "0px 10px 0px 10px",
                        }}
                      />
                    )} */}
                      </Stack>
                    </Box>
                  );
                })}
            </Box>
          </>

          {!cardData && (
            <>
              <Typography className={styles.card_details_missing}>
                {manageCardDetails?.card_miss_desc}
                <span
                  className={styles.cus_care}
                  onClick={() => {
                    navigate(RoutingURLs.contactUs);
                  }}
                >
                  {manageCardDetails?.cus_care}
                </span>
              </Typography>
            </>
          )}
        </Box>
        <ButtonCust
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
          onClick={addNewCardHandler}
          loading={isLoading}
          className={getAddNewStyles()}
          sx={{
            padding: "13px 23px 12px",
            my: "15px",
            width: "250px",
          }}
        >
          <Typography
            fontSize="25px"
            mr="5px"
            fontFamily="var(--font_family_Bold)"
          >
            {manageCardDetails?.plus}
          </Typography>
          <Typography className={styles.add_card}>
            {manageCardDetails?.add_new}
          </Typography>
        </ButtonCust>
        {manageCardDetails?.cncl_btn &&
        RoutingURLs.billing !== props?.pageName ? (
          <Typography
            sx={{
              marginY: "20px",
              fontSize: "16px",
              lineHeight: "26px",
              fontWeight: "var(--font_weight_2)",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--primary_color)",
              cursor: "pointer",
              margin: "13px 0px 0px",
            }}
            onClick={props.ManageCardCloseHandler}
          >
            {manageCardDetails?.cncl_btn}
          </Typography>
        ) : null}
      </Card>
    </Box>
  );
};

export default CardChangeModal;
