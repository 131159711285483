import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../config/RoutingURLs";
import EnvConfig from "../../config/env.config.json";
import styles from "./S1_DeviceCheckConfirm.module.scss";
import { DeviceCheckProps } from "./DeviceCheck";
import { ButtonCust } from "../widgets";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";

const DeviceCheck = (props: DeviceCheckProps) => {
  const { content } = props || {};
  const {
    back_btn,
    header,
    back_icon,
    header_p1,
    content_p1,
    link_url_p1,
    link_p1,
    link_extra_contnet,
    header_p2,
    content_p2,
    link_url_p2,
    link_p2,
    continue_btn,
  } = content || {};
  const { ns_link } = styles || {};
  const navigate = useNavigate();
  const secondaryCustomer = JSON.parse(
    localStorage.getItem("secondaryCustomerDetail") || "null"
  );
  const primaryCustomer = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const isAllowedToNavigationBack =
    primaryCustomer?.isSimInHand && !secondaryCustomer && !EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED;
  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 350px)" }}>
        <Box pt={{ xs: "35px", sm: "80px" }}>
          <Box
            mb={"-22px"}
            pl={{ xs: "17px", md: "65px" }}
            className={isAllowedToNavigationBack ? styles.disable_btn : ""}
            sx={{ display: "flex", justifyContent: "left", cursor: "pointer" }}
            onClick={() => {
              if (secondaryCustomer) {
                secondaryCustomer?.esim
                  ? navigate(RoutingURLs.activateEsim)
                  : secondaryCustomer?.isSimInHand && EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED ? navigate(RoutingURLs.myAccount) : navigate(RoutingURLs.trackOrder);
              } else if((primaryCustomer?.isSimInHand || secondaryCustomer?.isSimInHand) && EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED) {
                navigate(RoutingURLs.myAccount)
              }else {
                primaryCustomer?.esim
                  ? navigate(RoutingURLs.activateEsim)
                  : primaryCustomer?.isSimInHand
                  ? null
                  : navigate(RoutingURLs.trackOrder);
              }
            }}
          >
            <Box
              component="img"
              alt={back_btn || ""}
              src={back_icon.data.attributes?.url || ""}
            />
            <Typography sx={{ marginLeft: "9px" }}>{back_btn || ""}</Typography>
          </Box>
          <Box sx={{ justifyContent: "center" }}>
            {/* <Box> */}
            <Typography
              sx={{
                fontFamily: "var(--font_family_Bold)",
                fontSize: { xs: "20px", sm: "30px" },
                lineHeight: { xs: "30px", sm: "22px" },
                fontWeight: "800",
                textAlign: "center",
                paddingTop: { xs: "50px", sm: 0 },
                paddingX: { xs: "70px", sm: "auto" },
              }}
            >
              {BrandNameUpdate(header)}
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                // margin: { xs: "0px 17px", sm: "0px 30%" },
                margin: "auto",
                width: { xs: "100%", sm: "574px" },
                paddingX: { xs: "17px", sm: "auto" },
              }}
            >
              <Box mt={"36px"} textAlign={"justify"}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "var(--font_family_Bold)",
                  }}
                >
                  {header_p1}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {content_p1}&nbsp;
                </Typography>
                <Box
                  component="a"
                  className={ns_link}
                  href={link_url_p1}
                  target="_blank"
                >
                  {link_p1}&nbsp;
                </Box>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {link_extra_contnet}
                </Typography>
              </Box>
              <Box mt={"29px"} textAlign={"justify"}>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "var(--font_family_Bold)",
                  }}
                >
                  {header_p2}&nbsp;
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {content_p2}&nbsp;
                </Typography>
                <Box
                  component="a"
                  className={ns_link}
                  href={link_url_p2}
                  target="_blank"
                >
                  {link_p2}
                </Box>
              </Box>
              <Box mt={"36px"} paddingBottom={"50px"}>
                <ButtonCust
                  sx={{ width: { xs: "200px", sm: "300px", md: "430px" } }}
                  onClick={() => {
                    navigate(RoutingURLs.activation);
                  }}
                  variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
                >
                  {continue_btn}
                </ButtonCust>
              </Box>
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
        <Typography></Typography>
      </Box>
    </>
  );
};
export default DeviceCheck;
