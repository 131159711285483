import { useState, useEffect } from "react";
import styles from "./S6_ReviewPlanDetails.module.scss";
import { Typography, Grid, Box, Checkbox, Skeleton } from "@mui/material";
import data from "../../../config/data.json";
import { sortByKey } from "../../../utils/commonFunctions/SortByKey";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAdditionalLineCharges,
  getLineCharges,
  getPlanTaxableAmount,
} from "./BillService";
import firebase from "../../../adapters/firebase";
import {
  getActivationTax,
  getOneTimeShippingTaxCharges,
  getPlanTaxCharges,
  resetCoupon,
  resetOneTimeShippingTaxCharges,
  resetOneTimeSimTaxCharges,
  resetPlanTaxCharges,
  updateCouponDetails,
} from "../../../features/checkout/checkoutSlice";
import { addressType, SimTypes } from "../../../features/checkout/Types";
import {
  isInValidAddressInfo,
  isInvalid,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NotificationCust } from "../../widgets/NotificationCust/NotificationCust";
import EnvConfig from "../../../config/env.config.json";
import { CouponTypes } from "../../../features/checkout/CouponTypes";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ButtonCust } from "../../widgets";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import { BrandNameUpdate, useNonInitialEffect } from "../../../utils/commonFunctions";
import { priceCalculationHandler } from "../../home/PlanCard/PlanCardsComponent/T13_PlanCard";
import { PaymentButtons } from "../PaymentButtons/PaymentButtons";
import unlimitedPlanDiscount from "../../../config/discountCouponMNM.json";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";

const UNLIMITED_PLAN_DISCOUNT_COUPON = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../config/discountCouponsUnlimited.json")
  : null;
const SHARABLE_PLAN_DISCOUNT_COUPON = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../config/discountCouponsRegular.json")
  : null;
type Props = {
  formik: any;
  shipmentPlans: any;
  content: any;
  simPrefenceContent?: any;
  simPreference: any;
  setSimPreference: any;
  simInHandCheck: any;
  formikForPlanCard?: any;
  step?: any;
  handleNextStep?: any;
  paymentClicked?: any;
  navigate?: any;
  primaryLineError?: any;
  multiSimInHandCheck?: any;
  checkoutPageOpsMessages?: any;
};

export const costCalculation = (
  data: any = null,
  planDetails: any,
  contents: any
) => {
  let couponData: any = data ? data : null;
  let coupontype: any = CouponTypes;
  let plan: any = planDetails?.selectedPlan;
  let line: number = planDetails.numberOfLines;
  let basePrice: number = plan && plan.baseLinePrice;
  let additionalPrice: number = getAdditionalLineCharges(planDetails);
  let totalPrice: number = basePrice + additionalPrice;
  let discount: number = 0;
  let primaryDiscountAmount = 0;
  let secondaryDiscountAmount = 0;
  /**
   * The brand which is having fixed discounts for unlimited and shared plans.
   * */
  if (couponData && EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE && plan?.name) {
    if (plan?.name === EnvConfig.SPECIAL_DISCOUNT_PLAN_NAME) {
      primaryDiscountAmount = couponData?.discountInDollar || 0;
      if (line > 1) {
        secondaryDiscountAmount =
          (line - 1) * couponData?.secondaryDiscountInDollar || 0;
      }
    } else {
      primaryDiscountAmount = plan?.isUnlimited
        ? UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.discountInDollar
        : SHARABLE_PLAN_DISCOUNT_COUPON?.data?.discountInDollar;
      secondaryDiscountAmount = plan?.isUnlimited
        ? (line - 1) *
          UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.secondaryDiscountInDollar
        : (line - 1) *
          SHARABLE_PLAN_DISCOUNT_COUPON?.data?.secondaryDiscountInDollar;
    }
  }
  if (couponData && !EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE) {
    if (couponData && couponData.expired) {
      NotificationCust({
        message: contents?.invalid_coupon_err,
        type: "danger",
        duration: 2000,
        id: "CouponExpired",
        showIcon: true,
      });
    } else {
      localStorage.setItem("couponDetails", JSON.stringify(couponData));
      if (coupontype[couponData && couponData.type]?.type === "PROMO") {
        //promo
        if (couponData && couponData.discountInDollar) {
          if (totalPrice > couponData.discountInDollar) {
            discount = couponData.discountInDollar;
          } else {
            discount = totalPrice;
          }
        }
        if (couponData && couponData.planDiscount) {
          discount += (couponData.planDiscount * basePrice) / 100;
          if (couponData && couponData.secondaryDiscount) {
            discount += (couponData.secondaryDiscount * additionalPrice) / 100;
          }
          if (
            couponData.maxDiscountInDollar &&
            discount > couponData.maxDiscountInDollar
          ) {
            discount = couponData.maxDiscountInDollar;
          }
        }
      } else if (
        coupontype[couponData && couponData.type]?.type === "REFERRAL"
      ) {
        //referal
        let coupon1: any = { couponCode: couponData.coupon, type: 3 };
        let coupon2: any = { couponCode: couponData.coupon };

        localStorage.setItem("reachPurhcaseReferralUserCoupon", coupon1);
        localStorage.setItem("reachPurhcaseUserCoupon", coupon2);
        if (couponData && couponData.refereeDiscInDollar) {
          discount = couponData.refereeDiscInDollar;
        } else {
          if (couponData && couponData.refereeDiscount) {
            discount = (couponData.refereeDiscount * basePrice) / 100;
          }
          // if (couponData && couponData.maxDiscountInDollar) {
          //   discount = Math.min(couponData.maxDiscountInDollar, discount);
          // }
          if (
            couponData.maxDiscountInDollar &&
            discount > couponData.maxDiscountInDollar
          ) {
            discount = couponData.maxDiscountInDollar;
          }
        }
      } else {
        if (couponData && couponData?.planDiscount) {
          discount += (couponData.planDiscount * basePrice) / 100;
          if (couponData && couponData.secondaryDiscount) {
            discount += (couponData.secondaryDiscount * additionalPrice) / 100;
          }
        }
      }
    }
  }

  return {
    discountAmount: discount,
    totalCost: totalPrice,
    basePrice: basePrice,
    additionalPrice: additionalPrice,
    primaryDiscount: primaryDiscountAmount,
    secondaryDiscount: secondaryDiscountAmount,
    totalWithDiscount: EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
      ? totalPrice - primaryDiscountAmount - secondaryDiscountAmount
      : totalPrice - discount,
  };
};

const T7_ReviewPlan = (props: Props) => {
  const {
    formik,
    shipmentPlans,
    content,
    simPrefenceContent,
    simPreference,
    setSimPreference,
    simInHandCheck,
    formikForPlanCard,
    step,
    handleNextStep,
    paymentClicked,
    navigate,
    primaryLineError,
    multiSimInHandCheck,
    checkoutPageOpsMessages
  } = props;
  let plandata =
    data && data.data && data.data.length > 0
      ? sortByKey(data && data.data, "planData")
      : [];
  const [simCharges, setSimCharges] = useState(0);
  const [physicalSimPrefCharges, setPhysicalSimPrefCharges] = useState(0);
  const dispatch = useAppDispatch();
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const {
    planDetails,
    planTaxCharges,
    oneTimeSIMTaxCharges,
    oneTimeShippingTaxCharges,
    errorgetOneTimeSimTaxCharges,
    isLoadedShippingTaxes,
    errorOneTimeShippingTaxCharges,
    coupon,
    errorCoupon,
    errorUpdateCustomerSource,
    activationTax,
    isLoadedPlanTaxCharges,
    isLoadedActivationTax,
    errorPlanTaxCharges
  } = useAppSelector((state: any) => state.checkout);
  const [planTaxAmount, setPlanTaxAmount] = useState(0);
  let location: any = new URLSearchParams(document.location.search);
  const [appliedCouponCharges, setAppliedCouponCharges] = useState<number>(0);
  const [primaryDiscount, setPrimaryDiscount] = useState<number>(0);
  const [secondaryDiscount, setSecondaryDiscount] = useState<number>(0);
  const [showSimChangePrefernceDialog, setShowSimChangePrefernceDialog] =
    useState(false);
  const [eSimKitBasePrice, setESimKitBasePrice] = useState(0);
  const [eSimSecondarySimPrice, setESimSecondarySimPrice] = useState(0);
  const [welcomeKitCost, setWelcomeKitCost] = useState(0);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [activationFee, setActivationFee] = useState<number>(0);
  const [activationTaxes, setActivationTaxes] = useState<number>(0);
  const [planTaxes, setPlanTaxes] = useState<any>([]);
  const [termsErrorMsg, setTermsErrorMsg] = useState<string>("");
  const {
    activation_fee,
    due_today,
    esim,
    monthly_charges,
    monthly_service,
    next,
    note,
    note_desc1,
    note_desc2,
    note_desc3,
    proceed_to_pay,
    shipping_charges,
    sim,
    sim_type,
    taxes,
    your_plan,
    nickname,
    oneTime_charges,
    primary_line_error,
    termsError,
    linename,
    text_color,
    simInHand,
  } = content?.reviewPlanData;

  const { TandC } = content;

  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { checkoutCompatabilityState, multiSimVerificationState } = useAppSelector(
    (state: any) => state.checkout
  );
  useEffect(() => {
    getSIMCharges();
  }, [planDetails.numberOfLines, simPreference]);

  const getShipmentPlanByLabel = (label: string) => {
    return shipmentPlans.find((sPlan: any) => sPlan.value === label);
  };
  const shipmentType =
    getShipmentPlanByLabel(formik.values.shipmentPlan)?.name || "";

  let shipmentCharges = getShipmentPlanByLabel(
    formik.values.shipmentPlan
  )?.shippingAmount;

  let taxableAmount = getPlanTaxableAmount(planDetails);
  let simPreferenceStorage: any = JSON.parse(
    localStorage.getItem("simPreference") || "[]"
  );
  const isEsimSelected = formikForPlanCard?.values?.some(
    (line: any, i: number) => {
      return line.simPreference === SimTypes.eSim;
    }
  );
  const isPhysicalSelected = formikForPlanCard?.values?.some(
    (line: any, i: number) => {
      return line.simPreference === SimTypes.phySim;
    }
  );

  const numOfEsims = simPreferenceStorage?.filter(
    (data: any) => data?.eSimCompatible && !data?.isPrimaryLine
  ).length;

  const numOfPsims = simPreferenceStorage?.filter(
    (data: any) => !data?.eSimCompatible && !data?.isPrimaryLine
  ).length;

  const totalSimLines = simPreferenceStorage?.filter(
    (data: any) => !data?.eSimCompatible
  ).length;

  const totalEsimLines = simPreferenceStorage?.filter(
    (data: any) => data?.eSimCompatible
  ).length;
  /**
   * To fetch plan tax charges need billing address and customer fname and lname is mandatory
   */
  useEffect(() => {
    fetchBillingCalculations();
  }, [planDetails, appliedCouponCharges, taxableAmount, coupon]);

  useEffect(() => {
    setPlanTaxes([]);
  }, [formik.values.billingAddress]);

  useEffect(() => {
    if (
      formik?.values?.billingAddress &&
      formik?.values?.billingAddress["zip"]
    ) {
      fetchBillingCalculations();
    }
  }, [planTaxes]);

  const fetchBillingCalculations = async () => {
    if (
      appliedCouponCharges >= 0 ||
      primaryDiscount >= 0 ||
      secondaryDiscount >= 0
    ) {
      taxableAmount = getPlanTaxableAmount(planDetails);
      if (coupon) {
        const costCalculationData: any = costCalculation(
          coupon,
          planDetails,
          content
        );
        taxableAmount = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
          ? taxableAmount -
            costCalculationData.primaryDiscount -
            costCalculationData.secondaryDiscount
          : taxableAmount - (costCalculationData?.discountAmount || 0);
        setAppliedCouponCharges(costCalculationData?.discountAmount);
        setPrimaryDiscount(costCalculationData?.primaryDiscount);
        setSecondaryDiscount(costCalculationData?.secondaryDiscount);
        getPlanTaxInfo(taxableAmount);
      } else {
        getPlanTaxInfo(taxableAmount);
      }
    }
  };

  const checkIfIMEICheckInProgress = (collection: any, key: string) =>
    collection.every((obj: any) => !obj[key]);
  const resetPlanTaxInfo = () => {
    setTimeout(() => {
      setPlanTaxAmount(0);
      dispatch(resetPlanTaxCharges());
    }, 1000);
    setPlanTaxAmount(0);
    dispatch(resetPlanTaxCharges());
  };

  const checkIfSimInHandCheckInProgress = (collection: any, key: string) =>
    collection.every((obj: any) => !obj[key]);

  const handlePaymentSubmit = () => {
    // if (isChecked) {
    //   setTermsErrorMsg("");
    formik.handleSubmit();
    // } else {
    //   setTermsErrorMsg(termsError);
    // }
  };

  const getPlanTaxInfo = async (taxableAmountValue: number) => {
    plandata?.map(async (plan: any, i: any) => {
      if (
        !isInValidAddressInfo(formik.values.billingAddress) &&
        plan.baseLinePrice > 0
      ) {
        try {
          const response = await dispatch(
            getPlanTaxCharges({
              address: {
                ...formik.values.billingAddress,
                name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
              },
              reachPlanId: plan.name,
              amount: plan.baseLinePrice,
              // discountMap: {
              //   DISCOUNT: unlimitedPlanDiscount.data[0].discountInDollar,
              // },
            })
          );
          if (response?.payload?.status === 200) {
            let tax = response?.payload?.data?.data?.totalTax;

            if (!planTaxes?.find((obj: any) => obj[plan.name])?.[plan.name]) {
              let taxes = planTaxes;
              taxes?.push({ [plan.name]: tax });
              setPlanTaxes(taxes);
            }
          }
          return response;
        } catch (error) {
          console.log(error);
        }
      } else {
        resetPlanTaxInfo();
      }
    });
  };

  useEffect(() => {
    let shippingAddressInfo = formik.values.billingAddress;
    if (formik.values.isShippingSameAsBilling) {
      shippingAddressInfo = {
        ...formik.values.billingAddress,
        // type: addressType.shipping,
      };
    }

    if (!isInValidAddressInfo(shippingAddressInfo)) {
      fetchOneTimeTaxCharges(shippingAddressInfo);
    }
  }, [
    formik.values.shipmentPlan,
    formik.values.isShippingSameAsBilling,
    formik.values.billingAddress,
    formik.values.shippingAddress,
    simCharges,
    shipmentType,
  ]);

  const calculateActivationFee = () => {
    let totalCost = 0;
    for (let i = 1; i < formikForPlanCard.values.length + 1; i++) {
      totalCost += configKeys?.ACTIVATION_FEE_LINE[i];
    }
    return totalCost;
  };

  const fetchOneTimeTaxCharges = (address: any) => {
    let shipmentAmountCost = EnvConfig.INSTANT_DELIVERY_VIA_EMAIL_CHARGES;
    if (EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected) {
      // to do shipment charges when all selected sims are eSim type
    }
    if (shipmentCharges > 0) {
      dispatch(
        getOneTimeShippingTaxCharges({
          address: {
            ...address,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: shipmentCharges || 0,
        })
      );
    } else {
      dispatch(resetOneTimeShippingTaxCharges());
    }
  };
  /**
   * Error handling for one-time tax calculation get api failure
   */
  useEffect(() => {
    if (
      (errorgetOneTimeSimTaxCharges &&
        errorgetOneTimeSimTaxCharges?.status === "FAILURE") ||
      (errorOneTimeShippingTaxCharges &&
        errorOneTimeShippingTaxCharges?.status === "FAILURE")
    ) {
      if (errorgetOneTimeSimTaxCharges?.resCode === 100001) {
        NotificationCust({
          message: content?.ot_tax_add_err,
          type: "danger",
          duration: 0,
          id: "addressFailure",
          showIcon: true,
        });
      }
    }
  }, [errorgetOneTimeSimTaxCharges, errorOneTimeShippingTaxCharges]);

  const getSIMCharges = async () => {
    const simCostDataBaseURLAddress = EnvConfig?.TENANT_ID
      ? `${EnvConfig.TENANT_ID}/perSimCost`
      : "/perSimCost";
    const perCost: any = await firebase
      .database()
      .ref(simCostDataBaseURLAddress);
    let kitBasePrice = 0;
    let secondarySimPrice = 0;
    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();
      // physical sim cost calculations
      kitBasePrice =
        data &&
        data.USA &&
        ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      secondarySimPrice = kitBasePrice;
      if (data && data.USA && data.USA.subsequentCost) {
        secondarySimPrice =
          ((100 - data.USA.discountPctg) * data.USA.subsequentCost) / 100;
      }
      let simPref: any = JSON.parse(
        localStorage.getItem("simPreference") || "null"
      );

      let totalCost =
        kitBasePrice + secondarySimPrice * (planDetails.numberOfLines - 1);
      if (EnvConfig.IS_ESIM_SUPPORTED && simPref) {
        let numberOfPhysicalSIMs = simPref.filter((simItem: any) => {
          return simItem.value === SimTypes.sim;
        }).length;
        let numberOfPreferncePhysicalSIMs = simPreference.filter(
          (simItem: any) => {
            return simItem.value === SimTypes.sim;
          }
        ).length;
        const totalCostOfPreferedPhysicalSIMs =
          kitBasePrice +
          Number(secondarySimPrice * (numberOfPreferncePhysicalSIMs - 1));
        setPhysicalSimPrefCharges(Number(totalCostOfPreferedPhysicalSIMs));
        totalCost =
          kitBasePrice + secondarySimPrice * (numberOfPhysicalSIMs - 1);
      }
      setSimCharges(totalCost);
      // eSim cost calculations
      if (EnvConfig.IS_ESIM_SUPPORTED) {
        let eSimKitBasePrice = 0;
        let eSimSecondarySimPrice = 0;
        eSimKitBasePrice =
          data &&
          data.USA &&
          ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimCost) / 100;
        eSimSecondarySimPrice = eSimKitBasePrice;
        if (data && data.USA && data.USA.eSimSubsequentCost) {
          eSimSecondarySimPrice =
            ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimSubsequentCost) /
            100;
        }
        setESimKitBasePrice(eSimKitBasePrice);
        setESimSecondarySimPrice(eSimSecondarySimPrice);
        setWelcomeKitCost(totalCost);
      }
    });
  };
  useEffect(() => {
    if (coupon) {
      const costCalculationData = costCalculation(coupon, planDetails, content);
      setAppliedCouponCharges(costCalculationData?.discountAmount);
      setPrimaryDiscount(costCalculationData?.primaryDiscount);
      setSecondaryDiscount(costCalculationData?.secondaryDiscount);
    }
  }, [coupon, planDetails]);

  useEffect(() => {
    let couponDetails = localStorage.getItem("couponDetails");
    if (couponDetails && !coupon) {
      couponDetails = JSON.parse(couponDetails);
      dispatch(updateCouponDetails(couponDetails));
    }
  }, []);

  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);

  useNonInitialEffect(() => {
    if (configKeys?.IS_ACTIVATION_FEE_PER_LINE) {
      const fee = calculateActivationFee();
      setActivationFee(fee);

      dispatch(
        getActivationTax({
          address: {
            ...formik.values.billingAddress,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: fee ? fee : 0,
        })
      )
        .then((res: any) => {
          if (res?.payload?.data?.status === "SUCCESS") {
            setActivationTaxes(res?.payload?.data?.data?.totalTax);
          }
        })
        .catch((err: any) => console.log(err));
    }
  }, [
    configKeys,
    formikForPlanCard.values.length,
    formik?.values?.billingAddress?.zip,
  ]);
  /**
   * Error notifications handlers
   */
  useEffect(() => {
    if (errorCoupon) {
      NotificationCust({
        message: errorCoupon,
        type: "danger",
        duration: 2000,
        id: "CouponFailureNotifier",
        showIcon: true,
      });
    }
  }, [errorCoupon]);

  useEffect(() => {
    if (location?.message && location?.code) {
      NotificationCust({
        message: location?.message,
        type: "danger",
        duration: 2000,
        id: "PaymentGatewayFailure",
        showIcon: true,
      });
    }
  }, [location]);

  useEffect(() => {
    if (errorUpdateCustomerSource) {
      NotificationCust({
        message: content.user_upd_err,
        type: "danger",
        duration: 2000,
        id: "UserUpdateFailure",
        showIcon: true,
      });
    }
  }, [errorUpdateCustomerSource]);

  const planChargesTaxAmount = planTaxAmount || 0;

  let totalDueAmount: number = 0;

  const oneTimeTotalTaxChargesAmount: number = isPhysicalSelected && !multiSimInHandCheck
    ? activationTaxes + (oneTimeShippingTaxCharges?.totalTax || 0)
    : activationTaxes;

  if (EnvConfig.IS_ESIM_SUPPORTED) {
    totalDueAmount = isPhysicalSelected
      ? parseFloat(
          (
            oneTimeTotalTaxChargesAmount +
            activationFee +
            shipmentCharges
          ).toFixed(2)
        )
      : activationFee + activationTaxes;
  } else {
    totalDueAmount =
      activationFee + shipmentCharges + oneTimeTotalTaxChargesAmount;
  }
  const handleCouponRemove = () => {
    setAppliedCouponCharges(0);
    setPrimaryDiscount(0);
    setSecondaryDiscount(0);
    localStorage.removeItem("couponDetails");
    dispatch(resetCoupon());
  };
  const personIcon = content?.person_ico?.data?.attributes?.url;
  const personIcon_alt = content?.person_ico?.data?.attributes?.alternativeText;
  const giftBoxIcon = content?.gift_box_ico?.data?.attributes?.url;
  const giftBoxIcon_alt =
    content?.gift_box_ico?.data?.attributes?.alternativeText;

  const getPrimaryUserSimType = () => {
    return simPreferenceStorage[0]?.eSimCompatible
      ? `(${getLabel(simPrefenceContent?.esim_txt)})`
      : `(${getLabel(simPrefenceContent?.sim_txt)})`;
  };
  const getLabel = (text: string, value: number = 0) => {
    return text + (value > 1 ? "'s" : "");
  };
  // To show banner at offer info
  // const textBannerImg = "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/rectangle_d43e16f69d.webp"// content?.text_banner?.data?.attributes?.url;
  const getQuantity = (p: any) => {
    let quantity = 0;
    formikForPlanCard?.values.forEach((line: any) => {
      return line?.plan?.displayName === p?.displayName && quantity++;
    });
    return quantity;
  };

  const calcPlanTaxes = () => {
    let pTax = 0;
    if (planTaxes?.length > 0) {
      formikForPlanCard.values?.map((line: any, index: number) => {
        planTaxes?.map((plan: any, i: number) => {
          // let q: number = getQuantity(line.plan);
          const planName = Object.keys(planTaxes[i]);
          const tax: any = Object.values(plan);
          if (line?.plan?.name == planName) {
            pTax = pTax + 1 * tax;
          }
        });
      });
    }
    return pTax;
  };

  let totalMonthlyCharges: number = 0;
  formikForPlanCard?.values?.map((line: any, i: any) => {
    totalMonthlyCharges =
      totalMonthlyCharges + priceCalculationHandler(line.plan);
  });
  totalMonthlyCharges = totalMonthlyCharges + calcPlanTaxes();

  const line = () => {
    return (
      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: " 1px solid var(--lightgrey_7)",
            margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>
    );
  };

  return (
    <Grid
      container
      className={`${styles.Review_plan} checkout_review_plan_card`}
      sx={{
        p: { sm: "30px 36px", xs: "25px 10px" },
        borderRadius: "10px",
        background: " var(--White, #FFF)",
        padding: "30px",
        mb: "20px",
        opacity: errorOneTimeShippingTaxCharges || errorPlanTaxCharges ? "10%" : ""
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: { md: "14px", sm: "15px", xs: "10px" },
            px: { xs: 0, md: 0 },
            fontSize: { xs: "18px !important", md: "16px !important" },
            color: {
              xs: "var(--black) !important",
              sm: text_color ? `${text_color} !important` : "",
            },
            lineHeight: { xs: "27px", sm: "26px" },
          }}
          className={styles.review_header}
        >
          {your_plan}
        </Typography>
        {line()}
      </Grid>

      <Grid
        item
        container
        display="flex"
        sx={{
          justifyContent: { sm: "space-between", xs: "initial", ms: "initial" },
          px: { xs: 0, md: 0 },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          sx={{ pr: { sm: "0px", md: "0px", xs: "0px" } }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              sx={{
                mb: { md: "12px", xs: "10px" },
                fontSize: "16px !important",
                color: "var(--black)!important",
              }}
              className={styles.review_sub_header}
            >
              {monthly_service}
            </Typography>
          </Grid>
          {/* Plan Details */}
          <Grid item container xs={12} className={styles.your_line}>
            {formikForPlanCard?.values?.map((line: any, i: any) => {
              const planPerLinesCost = priceCalculationHandler(line.plan);

              return (
                // <Box key={i}>
                <>
                  <Grid item xs={9} textAlign="left">
                    <Box>
                      <Typography
                        className={styles.your_line}
                        sx={{
                          fontFamily: "var(--font_family_Regular) !important",
                          fontWeight: "var(--font_weight_0) !important",
                          fontSize: "16px !important",
                          color: text_color ? `${text_color} !important` : "",
                        }}
                      >
                        {
                          line?.firstName
                            ? line?.firstName + " " + line?.lastName
                            : linename + " " + (i + 1)
                          // Removed below code as per Hani's request
                          // .toString().padStart(2, "0")
                        }
                      </Typography>
                      <Typography
                        className={styles.your_line}
                        sx={{
                          fontSize: "16px !important",
                          fontFamily: "var(--font_family_Regular) !important",
                          fontWeight: "var(--font_weight_0) !important",
                          color: text_color ? `${text_color} !important` : "",
                        }}
                      >
                        {sim_type}{" "}
                        {line?.simPreference !== "" ? (
                          <Typography
                            component={"span"}
                            sx={{
                              backgroundColor: "#008FD510",
                              color: "var(--primary_color) !important",
                              fontFamily:
                                "var(--font_family_Semibold) !important",
                              borderRadius: "2px",
                              padding: "5px 10px !important",
                              fontSize: "12px !important",
                            }}
                            className={styles.your_line}
                          >
                            {line?.simPreference === SimTypes.phySim && line?.isPrimary && line?.simInHand ? simInHand : line?.simPreference === SimTypes.eSim ||
                            line?.simPreference === SimTypes.ESIM
                              ? esim
                              : line?.simPreference === SimTypes.phySim
                              ? sim
                              : ""}
                          </Typography>
                        ) : null}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    textAlign="right"
                    sx={{
                      fontWeight: "var(--font_weight_0) !important",
                      fontFamily: "var(--font_family_Regular) !important",
                      color: "var(--button_secondary_color)",
                    }}
                  >
                    {GetCurrencyFixedToTwo(line?.plan?.baseLinePrice)}
                  </Grid>
                  <Grid item xs={12} textAlign="right">
                    <Typography
                      sx={{
                        borderBottom: " 1px solid var(--navy)",
                        opacity: 0.1,
                        margin: "10px 0px",
                      }}
                    ></Typography>
                  </Grid>
                </>
                // {/* </Box> */}
              );
            })}
          </Grid>

          {/* Tax */}
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid
              item
              xs={8}
              textAlign="left"
              sx={{
                fontWeight: "var(--font_weight_0) !important",
                fontFamily: "var(--font_family_Regular) !important",
                fontSize: "16px !important",
                color: "var(--dark-charcoal-fade)",
              }}
            >
              {taxes}
            </Grid>
            {formik.values.billingAddress?.zip?.length > 5 ? (
              <Grid item xs={4} textAlign="right">
                {GetCurrencyFixedToTwo(calcPlanTaxes())}
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={4}
                  sx={{
                    fontFamily: "var(--font_family_Regular) !important",
                    fontWeight: "var(--font_weight_0) !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    color: "var(--button_secondary_color)",
                  }}
                >
                  {!isLoadedPlanTaxCharges ? (
                    GetCurrencyFixedToTwo(calcPlanTaxes())
                  ) : (
                    <Skeleton variant="rectangular" width={60} height={24} />
                  )}
                </Grid>
              </>
            )}
            {line()}
          </Grid>
          <Grid item container xs={12} sx={{ mb: "15px" }}></Grid>
          {/* Monthly Charges */}
          <Grid item container xs={12} sx={{ mb: "15px" }}>
            <Grid
              item
              xs={8}
              textAlign="left"
              className={styles.review_sub_header}
              sx={{
                fontSize: "16px !important",
                color: "var(--black) !important",
              }}
            >
              {monthly_charges}
            </Grid>
            <Grid
              item
              xs={4}
              textAlign="right"
              className={styles.review_sub_header}
              sx={{
                fontSize: "16px !important",
                color: {
                  xs: "var(--black) !important",
                  sm: "var(--dark-charcoal-fade) !important",
                },
              }}
            >
              {GetCurrencyFixedToTwo(totalMonthlyCharges) || "-"}
            </Grid>
            {line()}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          sx={{ pl: { sm: "0px", md: "0px", xs: "0px" } }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{
              mb: "15px",
              fontSize: "16px !important",
              color: "var(--black) !important",
            }}
            className={styles.review_sub_header}
          >
            {oneTime_charges}
          </Typography>

          {/* Activation Charges */}
          {configKeys?.IS_ACTIVATION_FEE_PER_LINE ? (
            <Grid item container xs={12} className={styles.tax_and_fee}>
              <Grid
                sx={{
                  fontFamily: "var(--font_family_Regular)",
                  fontWeight: "var(--font_weight_0)",
                }}
                item
                xs={9.5}
                textAlign="left"
              >
                {activation_fee?.replace(
                  /feeAmount/g,
                  configKeys?.ACTIVATION_FEE_LINE[1] || ""
                )}
              </Grid>
              <Grid
                item
                xs={2.5}
                textAlign="right"
                sx={{
                  fontSize: { xs: "14px", sm: "16px" },
                  lineHeight: "28px",
                  color: {
                    xs: "var(--text_color)",
                    sm: "var(--button_secondary_color)",
                  },
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                }}
              >
                {GetCurrencyFixedToTwo(activationFee)}
              </Grid>
              {line()}
            </Grid>
          ) : null}
          {/* Shipping Charges */}
          {formikForPlanCard?.values?.some((line: any, i: number) => {
            return line.simPreference === SimTypes.phySim  && !multiSimInHandCheck;
          }) ? (
            <Grid item container xs={12} className={styles.shipping_charge}>
              <Grid
                item
                xs={9.5}
                textAlign="left"
                sx={{
                  fontSize: "16px !important",
                  color: "var(--text_color) !important",
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                }}
              >
                {shipping_charges}
              </Grid>
              <Grid
                item
                xs={2.5}
                textAlign="right"
                sx={{
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: {
                    xs: "var(--text_color)",
                    sm: "var(--button_secondary_color)",
                  },
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                }}
              >
                {simInHandCheck || multiSimInHandCheck
                  ? GetCurrencyFixedToTwo(0)
                  : shipmentCharges
                  ? GetCurrencyFixedToTwo(shipmentCharges)
                  : GetCurrencyFixedToTwo(0)}
              </Grid>
              {line()}
            </Grid>
          ) : null}
          {/* Taxes */}
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid
              item
              xs={9.5}
              textAlign="left"
              sx={{
                fontWeight: "var(--font_weight_0)",
                fontFamily: "var(--font_family_Regular)",
              }}
            >
              {taxes}
            </Grid>
            <Grid
              item
              xs={2.5}
              sx={{
                fontSize: "16px",
                lineHeight: "28px",
                color: {
                  xs: "var(--text_color)",
                  sm: "var(--button_secondary_color)",
                },
                fontWeight: "var(--font_weight_0)",
                fontFamily: "var(--font_family_Regular)",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {isLoadedShippingTaxes || isLoadedActivationTax ? (
                <Skeleton variant="rectangular" width={60} height={24} />
              ) : oneTimeTotalTaxChargesAmount > 0 ? (
                GetCurrencyFixedToTwo(oneTimeTotalTaxChargesAmount)
              ) : (
                GetCurrencyFixedToTwo(0)
              )}
            </Grid>
            {line()}
          </Grid>
          {/* Payment due today */}
          <Grid item container xs={12} sx={{ marginBottom: "30px" }}>
            <Grid
              item
              xs={8}
              textAlign="left"
              className={styles.review_sub_header}
              sx={{
                fontSize: "16px !important",
                color: "var(--black) !important",
              }}
            >
              {due_today}
            </Grid>
            <Grid
              item
              xs={4}
              className={styles.review_sub_header}
              sx={{
                fontSize: "16px !important",
                color: {
                  xs: "var(--black) !important",
                  sm: "var(--dark-charcoal-fade) !important",
                },
                display: "flex",
                justifyContent: "end",
              }}
            >
              {isLoadedShippingTaxes || isLoadedActivationTax ? (
                <Skeleton variant="rectangular" width={60} height={24} />
              ) : simInHandCheck || multiSimInHandCheck ? (
                GetCurrencyFixedToTwo(0)
              ) : totalDueAmount >= 0 ? (
                GetCurrencyFixedToTwo(totalDueAmount)
              ) : (
                "-"
              )}
            </Grid>
          </Grid>
          {/* Terms and Conditions */}
          {/* <Box
            display={"flex"}
            sx={{ justifyContent: "start", alignItems: "center" }}
            mb={3}
          >
            <Checkbox
              value={isChecked}
              sx={{ color: "var(--primary_color)", padding: "9px 9px 9px 0px" }}
              onChange={() => {
                setIsChecked((prev) => !prev);
              }}
            />
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                color: "var(--text_color_12)",
                fontFamily: "var(--font_family_Bold) !important",
                width: { xs: "100%", sm: "70%" },
                a: {
                  color: "var(--text_color_12)",
                  fontFamily: "var(--font_family_Bold) !important",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: TandC,
              }}
              onClick={() => {
                gtmTagManager({
                  event: globalVal?.gtmEvents?.checkoutTerms,
                  click_section: "checkout",
                });
              }}
            ></Typography>
          </Box> */}
        </Grid>
        {/* <Box>
          <Typography
            className={styles.your_line}
            sx={{
              fontFamily: "var(--font_family_Medium) !important",
              fontSize: "14px !important",
            }}
          >
            {note}
          </Typography>
          <ol>
            <li style={{ color: "var(--text_color_12)" }}>
              <Typography
                className={styles.your_line}
                sx={{
                  fontSize: "14px !important",
                  fontWeight: "var(--font_weight_0) !important",
                  fontFamily: "var(--font_family_Regular) !important",
                }}
              >
                {note_desc1}
              </Typography>
            </li>
            <li style={{ color: "var(--text_color_12)" }}>
              <Typography
                sx={{
                  "& a": {
                    color: "var(--primary_color)",
                    fontWeight: "var(--font_weight_1) !important",
                    fontFamily: "var(--font_family_Medium) !important",
                    textDecoration: "none",
                  },
                  fontSize: "14px !important",
                  fontWeight: "var(--font_weight_0) !important",
                  fontFamily: "var(--font_family_Regular) !important",
                }}
                className={styles.your_line}
                dangerouslySetInnerHTML={{
                  __html: note_desc2,
                }}
              />
            </li>
          </ol>
        </Box> */}
        {(content?.otc_note_txt || content?.otc_note_desc) && (
            <Grid container xs={12}>
              {content?.otc_note_txt && (
                <Typography
                  component="p"
                  sx={{
                    my: "10px",
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "14px",
                    color: "var(--text_color)",
                  }}
                >
                  {content?.otc_note_txt}
                </Typography>
              )}
              {content?.otc_note_desc && (
                <Typography component={"ol"} style={{ paddingLeft: "20px" }}>
                  <Typography
                    component="li"
                    className={styles.note_description}
                    sx={{
                      mb: "5px",
                      lineHeight: "25px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(content?.otc_note_desc),
                    }}
                  />
                </Typography>
              )}
              {EnvConfig?.IS_MSG_CENTER_ENABLED && (
                <>
                  {checkoutPageOpsMessages.map((opsMessage: any) => {
                    return (
                      <Typography key={opsMessage.id} component={"div"}>
                        <Typography
                          variant="body2"
                          component="li"
                          sx={{
                            mb: "5px",
                            fontSize: "14px",
                            color: "var(--text_color)",
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              mb: "5px",
                              fontFamily: "var(--font_family_Medium)",
                            }}
                          >
                            {opsMessage.messageType}:{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              mb: "5px",
                              fontFamily: "var(--font_family_Medium)",
                            }}
                          >
                            {opsMessage.message}
                          </Typography>
                        </Typography>
                      </Typography>
                    );
                  })}
                </>
              )}
            </Grid>
          )}
          {(content?.otc_descl || content?.otc_dscl_d) && (
            <Grid item container xs={12} className={styles.total_due}>
              {content?.otc_descl && (
                <Grid item xs={12} textAlign="left">
                  <Typography
                    variant="body2"
                    component="li"
                    className={styles.note_header}
                  >
                    {content?.otc_descl}
                  </Typography>
                </Grid>
              )}
              {content?.otc_dscl_d && (
                <Grid item xs={12} textAlign="left">
                  <ol start={2} style={{ paddingLeft: "20px" }}>
                    <Typography
                      variant="body2"
                      component="li"
                      className={styles.note_description}
                      sx={{ mb: "5px", lineHeight: "25px" }}
                      dangerouslySetInnerHTML={{
                        __html: BrandNameUpdate(content?.otc_dscl_d),
                      }}
                    />
                  </ol>
                </Grid>
              )}
              {content?.otc_dscl_f && (
                <Grid item xs={12} textAlign="left">
                  <ol start={3} style={{ paddingLeft: "20px" }}>
                    <Typography
                      variant="body2"
                      component="li"
                      className={styles.note_description}
                      sx={{ mb: "5px", lineHeight: "25px" }}
                      dangerouslySetInnerHTML={{
                        __html: BrandNameUpdate(content?.otc_dscl_f),
                      }}
                    />
                  </ol>
                </Grid>
              )}
            </Grid>
          )}
        {step === 1 ? ( //TODO: Add condition to check NEXT button click
          <>
            {primaryLineError && (
              <Typography
                sx={{
                  m: "15px 0px 0px 10px",
                  fontSize: "18px",
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "var(--font_weight_4)",
                  lineHeight: "20px",
                  color: "#F40E1B",
                  marginBottom: "20px"
                }}
              >
                {primary_line_error || ""}
              </Typography>
            )}
            <ButtonCust
              style={{
                width: "250px",
                marginTop: "30px",
                height: "50px",
                minHeight: "50px",
                margin: "0 auto",
              }}
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              loading={
                !checkIfIMEICheckInProgress(
                  checkoutCompatabilityState,
                  "compatibilityLoading"
                ) || !checkIfSimInHandCheckInProgress(
                  multiSimVerificationState,
                  "pending"
                )
              }
              onClick={() => {
                handleNextStep();
                gtmTagManager({
                  event: globalVal?.gtmEvents?.page1Next,
                  click_section: "checkout",
                });
              }}
            >
              {next}
            </ButtonCust>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
export default T7_ReviewPlan;
