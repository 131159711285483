import React, { useState } from "react";
import { CustToolTip } from "../TooltipCust";
import { Box, ClickAwayListener } from "@mui/material";
import { getResolutionDetails } from "../../../utils";
import { RoutingURLs } from "../../../config/RoutingURLs";

export const EclipsedUsername = (props: any) => {
  let { lineName } = props;
  const [openLineName, setOpenLineName] = useState(false);
  const { mobile } = getResolutionDetails();

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setOpenLineName(false);
        }}
      >
        <Box
          component="a"
          target="_blank"
          sx={{
            color: "var(--white)",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "14px",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };
  const billingPageName = props?.creditsCards ? true : false;

  return (
    <>
      <CustToolTip
        title={PoperOver(<Box component={"p"}>{lineName}</Box>)}
        open={openLineName ? true : false}
        disableFocusListener
        disableHoverListener
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "var(--black) !important",
              color: "var(--white) !important",
              padding: "8px 14px!important",
              borderRadius: "3px !important",
              border: "none !important",
              "& p": {
                lineHeight: "21px !important",
                fontWeight: "var(--font_weight_0) !important",
                fontFamily: "var(--font_family_Regular) !important",
                fontSize: "16px !important",
                margin: "0px !important",
              },
            },
          },
          arrow: {
            sx: {
              "::before": {
                backgroundColor: "var(--black) !important",
                border: "none !important",
              },
            },
          },
        }}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
      >
        <Box
          component={"span"}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: { xs: "120px", sm: billingPageName ? "150px" : "180px" },
            lineHeight: billingPageName ? "28px" : "21px !important",
            fontWeight: billingPageName
              ? "var(--font_weight_2)"
              : "var(--font_weight_0) !important",
            fontFamily: billingPageName
              ? "var(--font_family_Semibold)"
              : "var(--font_family_Regular) !important",
            fontSize: "16px !important",
            margin: "0px !important",
          }}
          onMouseEnter={() => {
            if (lineName?.length > 20 || (mobile && lineName?.length > 11)) {
              setOpenLineName(true);
            }
          }}
          onClick={() => {
            if (lineName?.length > 20 || (mobile && lineName?.length > 11)) {
              setOpenLineName(true);
            }
          }}
          onMouseLeave={() => {
            setOpenLineName(false);
          }}
        >
          {lineName}{" "}
          {billingPageName && lineName?.length < 15 ? <>&nbsp;</> : null}
        </Box>
      </CustToolTip>
    </>
  );
};
