import { useEffect, useState } from "react";
import {
  Box,
  ClickAwayListener,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ButtonCust,
  Divider,
  SimpleModal,
  RadioCust,
  CustToolTip,
} from "../widgets";
import { ChangeLinePreProps } from "./ChangeLinePreferenceDialog";
import classes from "./T3_ChangeLinePreferenceDialog.module.scss";
import EnvConfig from "../../config/env.config.json";
import { SimTypes } from "../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { CarrierTypes } from "../../enums";
import { BrandNameUpdate } from "../../utils";
import { PopperToolTip } from "./PopperToolTip";
import { useAppSelector } from "../../app/hooks";
import ApnNote from "../apnSettings/Apn_Note";
import MultiSimInHand from "../checkout/MultiSimInHandCard/MultiSimInHand";

const T3_ChangeLinePreferenceDialog = (props: ChangeLinePreProps) => {
  const {
    open,
    onClose,
    content,
    onConfirm,
    simType,
    setSimType,
    pageName,
    simCost,
    formik,
    numChangeHandler,
    onSubmitSimHandler,
    error,
    success,
    pending,
    handleClose,
    simInHandContentState,
  } = props;
  const [openEsimToolTipIndex, setOpenEsimToolTipIndex] = useState(false);
  const [openPsimToolTipIndex, setOpenPsimToolTipIndex] = useState(false);
  const [openSimInHandToolTipIndex, setOpenSimInHandToolTipIndex] =
    useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSimTypeChange = (e: any) => {
    setSimType(e.target.value);
  };
  const { imeiData } = useAppSelector((state: any) => state?.activation);

  useEffect(() => {
    setSimType(imeiData?.esimAvailable ? SimTypes?.eSim : SimTypes?.sim);
  }, [imeiData]);

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setOpenEsimToolTipIndex(false);
          setOpenPsimToolTipIndex(false);
          setOpenSimInHandToolTipIndex(false);
        }}
      >
        <Box
          component="a"
          //   href={tooltip_link}
          target="_blank"
          sx={{
            color: "var(--white)",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "14px",
            backgroundColor: "var(--navy)",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };
  const onCloseEsimToolTip = () => {
    setOpenEsimToolTipIndex(false);
  };
  let AdditionalIphoneSettings =
    pageName === "activation" && content?.add_phn_stn;
  let title = content?.add_phn_stn
    ? props?.currentLine?.isPrimary
      ? content?.sim_prf_suc_dlg?.title
      : content?.sim_prf_suc_dlg?.title_sec
    : content?.sim_prf_suc_dlg?.title;
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        width: {
          xs: "90%",
          sm: "60%",
          md: "82%",
          lg: "45%",
          xl: AdditionalIphoneSettings ? "47%" : "35%",
        },
        maxWidth: "648px",
        padding: { xs: "15px" },
        overflow: "auto",
      }}
    >
      <Box
        style={{ width: "100%", alignItems: "center" }}
        sx={{ mt: { md: "12px" } }}
      >
        {/* here check success title */}
        {AdditionalIphoneSettings ? (
          <Box textAlign={"center"}>
            <Box
              component="img"
              alt={content?.suc_tick_icon?.data?.attributes?.alternativeText}
              src={
                imeiData?.os &&
                !imeiData?.esimAvailable &&
                props?.currentLine?.esim
                  ? content?.danger_info?.data?.attributes?.url
                  : content?.suc_tick_icon?.data?.attributes?.url
              }
              sx={{
                m: "0px 0px 25px",
                width:
                  imeiData?.os && !imeiData?.esimAvailable ? "41px" : "55px",
              }}
            />
          </Box>
        ) : null}
        {AdditionalIphoneSettings &&
          !(
            !imeiData?.esimAvailable &&
            props?.currentLine?.esim &&
            EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED &&
            props?.currentLine?.isPrimary
          ) && (
            <Typography
              component="h3"
              sx={{
                textAlign: "center",
                fontSize: {
                  xs: "24px !important",
                  sm: AdditionalIphoneSettings
                    ? "24px !important"
                    : "28px !important",
                },
                fontFamily: AdditionalIphoneSettings
                  ? "var(--font_family_Bold) !important"
                  : "var(--font_family_Bold)",
                fontWeight: AdditionalIphoneSettings
                  ? "700 !important"
                  : "var(--font_weight_custom2)",
                lineHeight: "33px",
                letterSpacing: "0px",
                pb: "15px",
                marginX: {
                  xs: "18px",
                  sm: props?.currentLine?.isPrimary ? "10%" : "2%",
                },
                color: "var(--dark-charcoal-fade)",
                width: {
                  xs: "90%",
                  sm: "62%",
                },
                margin: "0px auto !important",
                //styleName: H3;
              }}
            >
              {AdditionalIphoneSettings
                ? imeiData?.esimAvailable
                  ? BrandNameUpdate(content?.res1?.esimPsimEnabled)
                  : BrandNameUpdate(content?.res1?.psimEnabled)
                : BrandNameUpdate(title)}
            </Typography>
          )}
        {!imeiData?.esimAvailable &&
          props?.currentLine?.esim &&
          EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED &&
          props?.currentLine?.isPrimary && (
            <Typography
              component="h3"
              sx={{
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "42px",
                textAlign: "center",
                color: "var(--lite_black_3)",
                margin: "0px 60px 20px",
              }}
            >
              {!imeiData?.esimAvailable && content?.simInHand_error_title}
            </Typography>
          )}
        <Box
          className="h4"
          sx={{
            fontFamily: "var(--font_family_Semibold)",
            fontSize: AdditionalIphoneSettings ? "16px" : "18px",
            mb: "15px",
          }}
          textAlign="center"
        >
          {!imeiData?.esimAvailable && EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED
            ? content?.Preferred_simInHand
            : content?.Preferred_sim1}
        </Box>
        {/* <Box style={{ margin: "15px auto 30px auto" }}>
          <Divider />
        </Box> */}

        {pageName === "activation" && content?.act_desc_txt ? (
          <Box
            style={{
              fontFamily: "var(--font_family_Medium)",
              fontSize: "18px",
              color: "var(--text-color)",
            }}
          >
            {BrandNameUpdate(content?.act_desc_txt)} {pageName}
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: {
              xs: "250px",
              sm:
                EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED &&
                props?.currentLine.esim &&
                !imeiData?.esimAvailable
                  ? "305px"
                  : AdditionalIphoneSettings
                  ? "250px"
                  : "350px",
            },
            margin: "auto",
          }}
        >
          {AdditionalIphoneSettings ? null : (
            <Box className={classes.label_txt}>
              {pageName === "activation"
                ? content?.single_line
                : content?.add_line_lbl}
            </Box>
          )}
          <Box
            sx={{
              width: AdditionalIphoneSettings ? "auto" : "60%",
              ml: AdditionalIphoneSettings ? "0px" : { sm: "auto", xs: "15px" },
            }}
          >
            <RadioGroup
              value={simType}
              onChange={(e: any) => handleSimTypeChange(e)}
              style={{
                display: "flex",
                justifyContent: AdditionalIphoneSettings
                  ? "space-around"
                  : "none",
              }}
              row
            >
              <RadioCust value={SimTypes.sim} label={content?.sim_lbl} />
              {content?.res1?.psim_tooltip_msg &&
              content?.sim_info_icon1?.data?.attributes?.url ? (
                <CustToolTip
                  placement={isMobile ? "top" : "top-start"}
                  className={classes.tooltip_style}
                  title={PoperOver(
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: content?.res1?.psim_tooltip_msg,
                      }}
                    ></Typography>
                  )}
                  open={openPsimToolTipIndex ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                >
                  <Box
                    component="img"
                    className={classes.info_icon}
                    sx={{ ml: "-13px" }}
                    src={content?.sim_info_icon1?.data?.attributes?.url}
                    onMouseEnter={() => {
                      setOpenPsimToolTipIndex(true);
                    }}
                    onClick={() => {
                      setOpenPsimToolTipIndex(true);
                    }}
                    onMouseLeave={() => {
                      setOpenPsimToolTipIndex(false);
                    }}
                  ></Box>
                </CustToolTip>
              ) : null}
              <Box sx={{ display: "flex" }}>
                {!imeiData?.esimAvailable &&
                props?.currentLine?.esim &&
                EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED &&
                props?.currentLine?.isPrimary ? (
                  <>
                    <RadioCust
                      value={SimTypes.simInHAND}
                      label={content?.simInHand_txt}
                    />
                    {content?.res1?.sim_in_hand_lbl_tooltip_msg &&
                    content?.sim_info_icon1?.data?.attributes?.url ? (
                      <CustToolTip
                        placement={isMobile ? "top" : "top-start"}
                        className={classes.tooltip_style}
                        title={PoperOver(
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: BrandNameUpdate(
                                content?.res1?.sim_in_hand_lbl_tooltip_msg
                              ),
                            }}
                          ></Typography>
                        )}
                        open={openSimInHandToolTipIndex ? true : false}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ],
                        }}
                      >
                        <Box
                          component="img"
                          className={classes.info_icon}
                          sx={{ ml: "-13px" }}
                          src={content?.sim_info_icon1?.data?.attributes?.url}
                          onMouseEnter={() => {
                            setOpenSimInHandToolTipIndex(true);
                          }}
                          onClick={() => {
                            setOpenSimInHandToolTipIndex(true);
                          }}
                          onMouseLeave={() => {
                            setOpenSimInHandToolTipIndex(false);
                          }}
                        ></Box>
                      </CustToolTip>
                    ) : null}
                  </>
                ) : (
                  <>
                    <RadioCust
                      value={SimTypes.eSim}
                      label={content?.sih_esim_lbl}
                      disabled={imeiData?.os && !imeiData?.esimAvailable}
                    />
                    {content?.res1?.esim_tooltip_msg &&
                    content?.sim_info_icon1?.data?.attributes?.url ? (
                      <CustToolTip
                        placement={isMobile ? "top" : "top-start"}
                        className={classes.tooltip_style}
                        title={PoperOver(
                          <Typography
                            sx={{
                              "& a": {
                                color: "var(--white) !important",
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: content?.res1?.esim_tooltip_msg,
                            }}
                          ></Typography>
                        )}
                        open={openEsimToolTipIndex ? true : false}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ],
                        }}
                      >
                        <Box
                          component="img"
                          className={classes.info_icon}
                          sx={{ ml: "-13px" }}
                          src={content?.sim_info_icon1?.data?.attributes?.url}
                          // onMouseEnter={() => {
                          //   setOpenEsimToolTipIndex(true);
                          // }}
                          onClick={() => {
                            setOpenEsimToolTipIndex(true);
                          }}
                          // onMouseLeave={() => {
                          //   setOpenEsimToolTipIndex(false);
                          // }}
                        ></Box>
                      </CustToolTip>
                    ) : null}
                  </>
                )}
              </Box>
            </RadioGroup>
          </Box>
        </Box>
        {simType === SimTypes.simInHAND &&
          !imeiData?.esimAvailable &&
          props?.currentLine?.esim &&
          EnvConfig.IS_MULTI_SIM_IN_HAND_ENABLED && (
            <Box
              sx={{
                margin: {
                  sm: "0px 25px",
                  md: "0px 50px",
                },
              }}
            >
              <MultiSimInHand
                simId={formik.values.iccId}
                onChangeSimId={(e: any) => {
                  numChangeHandler(e, formik);
                }}
                onSubmitSimHandler={onSubmitSimHandler}
                simInHandContentState={simInHandContentState}
                error={error}
                success={success}
                pending={pending}
                handleClose={handleClose}
              />
            </Box>
          )}
        {/* {pageName === "activation" && simType === SimTypes.sim && (
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              margin: "auto",
              marginTop: "15px",
            }}
          >
            <Box className={classes.note_style}>{content?.note_txt}</Box>
            <Box pl={1}>
              {content?.note_desc1 &&
                content?.note_desc1?.map((val: any) => {
                  return (
                    <Box className={classes.note_style}>
                      {" "}
                      {val?.item.replace(
                        "${cost}",
                        GetCurrencyFixedToTwo(simCost)
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )} */}
        <Box
          className={classes.btn_container}
          sx={{ mt: AdditionalIphoneSettings ? "15px" : "30px" }}
        >
          <ButtonCust
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            sx={{
              my: "10px",
              width: {
                xs: "201px",
                md: "250px",
              },
              textAlign: "center",
              height: "45px",
            }}
            onClick={onConfirm}
            disabled={SimTypes.simInHAND === simType && !success}
          >
            {EnvConfig.OPERATOR.toLowerCase() === CarrierTypes.ATT
              ? content?.cl_btn_ctx
              : simType === SimTypes.ESIM && pageName !== "activation"
              ? content?.ctx_c_c_btn
              : content?.cl_btn_ctx}
          </ButtonCust>
        </Box>
        {AdditionalIphoneSettings ? (
          <>
            <Typography
              component="h3"
              sx={{
                fontSize: "16px !important",
                lineHeight: { xs: "18px", sm: "26px" },
                fontFamily: "var(--font_family_Semibold) !important",
                fontWeight: "var(--font_weight_2) !important",
                my: "15px",
                color: "var(--primary_color)",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={onClose}
            >
              {BrandNameUpdate(content?.sim_prf_suc_dlg?.chk_an_ph)}
            </Typography>
            {EnvConfig?.IS_APN_SETTINGS_ENABLED && imeiData?.os ? (
              <Box pt={"20px"}>
                <ApnNote
                  imeiData={{
                    os: imeiData?.os,
                    osVer: imeiData?.osVer,
                  }}
                  isShowAsPill={false}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  marginX: { xs: "0px", md: "0px" },
                  padding: "20px",
                  backgroundColor: "var(--light_primary_color)",
                  borderRadius: "4px",
                  border: "1px solid var(--primary_color)",
                  boxShadow: " 0px 0px 27px 0px var(--translucent_gray)",
                }}
              >
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "16px !important",
                    lineHeight: { xs: "18px", sm: "29px" },
                    fontFamily: "var(--font_family_Medium)  !important",
                    fontWeight: "var(--font_weight_1)  !important",
                    pb: "20px",
                    color: "var(--black)",
                  }}
                >
                  {BrandNameUpdate(
                    imeiData?.os !== "ios"
                      ? content?.sim_prf_suc_dlg?.im_cr_an
                      : content?.sim_prf_suc_dlg?.im_cr_ip
                  )}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html:
                      imeiData?.os !== "ios"
                        ? content?.sim_prf_suc_dlg?.an_acc_nxt_lnk
                        : content?.sim_prf_suc_dlg?.acc_nxt_lnk,
                  }}
                  sx={{
                    display: {
                      xs: imeiData?.os !== "ios" ? "block" : "none",
                      sm: "block",
                    },
                    fontSize: "14px !important",
                    lineHeight: { xs: "18px", sm: "25px" },
                    fontFamily: "var(--font_family_Regular)  !important",
                    fontWeight: "var(--font_weight_0)  !important",
                    color: "var(--text_color)",
                    "& a": {
                      fontFamily: "var(--font_family_Medium)  !important",
                      fontWeight: "var(--font_weight_1)  !important",
                      color: "var(--primary_color)",
                    },
                  }}
                />
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: content?.sim_prf_suc_dlg?.acc_nxt_lnk_xs,
                  }}
                  sx={{
                    display: {
                      xs: imeiData?.os !== "ios" ? "none" : "block",
                      sm: "none",
                    },
                    fontSize: "14px !important",
                    lineHeight: { xs: "18px", sm: "25px" },
                    fontFamily: "var(--font_family_Regular)  !important",
                    fontWeight: "var(--font_weight_0)  !important",
                    color: "var(--text_color)",
                    "& a": {
                      fontFamily: "var(--font_family_Medium)  !important",
                      fontWeight: "var(--font_weight_1)  !important",
                      color: "var(--primary_color)",
                    },
                  }}
                />
              </Box>
            )}
          </>
        ) : null}
      </Box>
    </SimpleModal>
  );
};

export default T3_ChangeLinePreferenceDialog;
