import {
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { ButtonCust } from "../../../widgets";
import { calculateCost, calculateUnlimitedCost, calculateUnlimitedCostMnM, PlanProps } from "./PlanCard";
import styles from "./S13_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useEffect, useState } from "react";
import { NutritionLabel } from "../NutritionLabel/NutritionLabel";
import { getConfigKeys } from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAppData } from "../../../../features/checkout/checkoutSlice";
import { caclulatePlanCostBasedOnEligibility, gtmTagManager, useNonInitialEffect } from "../../../../utils";
import { gaScreenNames } from "../../../../Types/GoogleAnalytics";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import unlimitedPlanDiscount from "../../../../config/discountCouponMNM.json";
import { nutritionLabels } from "../../../../features/planpage/planpageSlice";

export const priceCalculationHandler: any = (
  data: any,
  selectedLine: any = 1
) => {
  let PlanDiscountCost, planPerLinesCost: any;
  if (data?.isUnlimited) {
    const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);
    PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
      planCost,
      unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
    );
  } else {
    const { planCost } = calculateCost(data, selectedLine);
    PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
      planCost,
      unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
    );
  }
  planPerLinesCost = PlanDiscountCost / selectedLine;
  return planPerLinesCost;
};

const T13_PlanCard = (props: PlanProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAnswer, setShowAnswer] = useState<any>(true);
  const [filteredPlans, setFilteredPlans] = useState<any>({});
  const [selectedPlanDetail, setSelectedPlanDetail] = useState<any>([]);
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(nutritionLabels());
    dispatch(getConfigKeys());
  }, []);
  const {
    plandata,
    planContent,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    planSelectionHandler,
  } = props;
  useNonInitialEffect(() => {
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify(selectedPlanDetail)
    );

    let countByName = count(selectedPlanDetail, (item: any) => {
      return item?.plan?.displayName;
    });
    setFilteredPlans(countByName);
  }, [selectedPlanDetail]);

  useEffect(() => {
    let selectedPlanDetails = localStorage.getItem("reachSelectedPlanDetail");
    if (selectedPlanDetails && selectedPlanDetails?.length > 0) {
      selectedPlanDetails = JSON.parse(selectedPlanDetails) || [];
      setSelectedPlanDetail(selectedPlanDetails);
    }
  }, []);

  const count = (ary: any, classifier: any) => {
    classifier = classifier || String;
    return ary?.reduce(function (counter: any, item: any) {
      var p = classifier(item);
      counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
      return counter;
    }, {});
  };

  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    fade_out,
    home_page_plan_card,
    tag,
    wrapper_div,
    mb_img,
  } = styles;
  const {
    per_month_text,
    mbl_img,
    video_img,
    tax_text,
    btn_txt,
    data,
    hd_vid,
    sd_vid,
    mb_data,
    hotspot_img,
    no_hpt_fee,
    res,
    res1,
    res2,
    checkout_cta,
    of_txt,
    lines_selected
  } = planContent || {};
  const getQuantity = (p: any) => {
    let quantity = 0;
  
    // Check if selectedPlanDetail is an array
    if (!Array.isArray(selectedPlanDetail)) {
      return quantity; // return 0 if selectedPlanDetail is not an array
    }
  
    selectedPlanDetail.forEach((el: any) => {
      if (el?.plan?.displayName === p?.displayName) {
        quantity++;
      }
    });
  
    return quantity;
  };

  const selectPlanHandler = (
    _: any,
    plan: any,
    type?: string,
    firstLine?: boolean
  ) => {
    let planDetails;
    if (type === "add") {
      if (selectedPlanDetail.length + 1 <= configKeys?.MAX_LINES_GROUP) {
        let currentPlan = {
          plan: plan,
          firstName: "",
          lastName: "",
          imei: "",
          simPreference: "",
          isPrimary: "",
          simInHand: false,
          iccId: "",
        };
        planDetails = [...(selectedPlanDetail || []), currentPlan];
        console.log("chck2")
        setSelectedPlanDetail(planDetails);
      }
      if (firstLine) {
        gtmTagManager({
          event: `mobile_${plan?.planData}gb_select_plan`,
          click_section: "home",
        });
      } else {
        gtmTagManager({
          event: `mobile_${plan?.planData}gb_add_line`,
          click_section: "home",
        });
      }
    } else if (type === "remove") {
      gtmTagManager({
        event: `mobile_${plan?.planData}gb_remove_line`,
        click_section: "home",
      });
      let selectedPlans = [...selectedPlanDetail];
      for (let i = 0; i < selectedPlans?.length; i++) {
        if (selectedPlans[i].plan?.displayName === plan?.displayName) {
          selectedPlans?.splice(i, 1);
          break;
        }
      }
      let updatedPlans = [...selectedPlans]; // changing the reference so that component is rerendered
      console.log("chck3")
      setSelectedPlanDetail(updatedPlans);
    }
  };
  
  return (
    <Stack className={wrapper_div}>
      <Grid container className={clsx(cards_div, plans_div)}>
        {plandata.length > 0 &&
          plandata.map((el: any, index: number) => {
            const hotspotData = ()=>{
              if(el.planData >= 50){
                return 10
              }else{
                return 5
              }
            }
            const { planCost, planPerLinesCost } = el?.unlimited
              ? calculateUnlimitedCost(el, selectedLine)
              : calculateCost(el, selectedLine);
            const isCurrentPlan =
              details?.reachPlanDisplayName === el?.displayName;
            return (
              <Card
                id={`home${index}`}
                key={index}
                className={clsx(home_page_plan_card, plan_page_plan_card)}
                raised
                elevation={isMobile ? 0 : 8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                }}
              >
                {/* {isCurrentPlan && (
                  <>
                    {!tagLabel && (
                      <Box
                        className={tag}
                        sx={{
                          backgroundColor: tagBgColor
                            ? tagBgColor
                            : "var(--primary_background)",
                          color: tagTxtColor ? tagTxtColor : "var(--white)",
                        }}
                      >
                        {planContent?.current_plan}
                      </Box>
                    )}
                  </>
                )} */}
                <Box sx={{ minHeight: "335px" }}>
                  <Typography
                    component={"h5"}
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      fontSize: { xs: "20px", md: "18px" },
                      color: "var(--text_color_12)",
                      fontWeight: "600",
                      my: "6px",
                      textAlign: "center",
                    }}
                  >
                    {el.displayName}
                  </Typography>
                  <Typography
                    component={"div"}
                    sx={{
                      fontFamily: "var(--font_family_Bold)",
                      fontSize: { xs: "18px", md: "20px" },
                      color: "var(--text_color)",
                      my: "6px",
                      textAlign: "center",
                    }}
                  >
                    {el.planData + el.dataUnit}
                    {el.planType === "LSD" ? "+" : ""}
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "32px",
                        fontFamily: "var(--font_family_Bold)",
                        color: "var(--primary_color)",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "32px",
                          fontFamily: "var(--font_family_Bold)",
                          color: "inherit",
                        }}
                      >
                        $
                        {GetCurrencyFixedToTwo(
                          planCost ? planCost : 0,
                          el.currency ? el.currency : "usd",
                          true
                        )}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "10px",
                          color: "var(--text_color_12)",
                        }}
                      >
                        {per_month_text}
                      </Typography>
                      <Typography
                        component={"div"}
                        sx={{
                          fontFamily: "var(--font_family_Regular)",
                          fontSize: "12px",
                          color: "var(--black)",
                          my: "10px",
                        }}
                      >
                        {tax_text}
                      </Typography>
                    </Typography>
                  </Box>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={mbl_img?.data?.attributes?.alternativeText}
                      src={mbl_img?.data?.attributes?.url || ""}
                      width="15px"
                      // className={el?.planType !== "HSD" ? mb_img : }
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "14px",
                        color: "var( --text_color)",
                        ml: "15px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          el?.planType === "HSD"
                            ? res1?.buy_data
                            : res1?.streaming.replace(
                                /plandata/g,
                                el?.planData || ""
                              ),
                      }}
                    ></Typography>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "start",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={hotspot_img?.data?.attributes?.alternativeText}
                      src={hotspot_img?.data?.attributes?.url || ""}
                      width="15px"
                      // className={el?.planType !== "HSD" ? mb_img : ""}
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var( --text_color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          el?.planType === "HSD"
                            ? res2?.buy_data
                            : res2?.streaming.replace(
                              /hot/g,
                              hotspotData || ""
                            ),
                      }}
                    ></Typography>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "25px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={mb_data?.data?.attributes?.alternativeText}
                      src={mb_data?.data?.attributes?.url}
                      width="15px"
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var( --text_color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          el?.planType === "HSD"
                            ? res?.buy_data
                            : res?.streaming.replace(
                              /plandata/g,
                              el?.planData || ""
                            ),
                      }}
                    ></Typography>
                  </Typography>
                </Box>
                <Box
                  className={el.maxLines >= selectedLine ? " " : fade_out}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                    mt: "auto",
                  }}
                >
                  {/* <ButtonCust
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    className={el.maxLines >= selectedLine ? " " : fade_out}
                    onClick={(e: any) => {
                      el.maxLines >= selectedLine
                        ? planSelectionHandler(el, btn_txt)
                        : "";
                    }}
                  >
                    {btn_txt}
                  </ButtonCust> */}
                  {getQuantity(el) === 0 ? (
                    <Box
                      className={el.maxLines >= selectedLine ? " " : fade_out}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        my: "15px",
                      }}
                    >
                      <ButtonCust
                        variantType={Config.PRIMARY_BUTTON_TYPE}
                        className={el.maxLines >= selectedLine ? " " : fade_out}
                        onClick={(e: any) => {
                          // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                          //   ? shareablePlans?.maxLines >= selectedLine
                          //     ? selectPlanHandler(e, "byTheGig")
                          //     : " "
                          //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                          selectPlanHandler(e, el, "add", true);
                        }}
                        disabled={
                          selectedPlanDetail.length >=
                          configKeys?.MAX_LINES_GROUP
                        }
                      >
                        {btn_txt}
                      </ButtonCust>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        my: "15px",
                      }}
                      className={clsx(
                        configKeys?.MAX_LINES_GROUP >= selectedLine
                          ? " "
                          : fade_out
                      )}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          backgroundColor: "var(--primary_color)",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          height: "50px",
                          borderRadius: "60px",
                          minWidth: "128px",
                          width: "128px",
                          margin: "auto",
                        }}
                        className={clsx(
                          configKeys?.MAX_LINES_GROUP >= selectedLine
                            ? " "
                            : fade_out
                        )}
                      >
                        <Typography
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "var(--text_color_12)",
                            textAlign: "center",
                            cursor: "pointer",
                            backgroundColor: "var(--white)",
                            border: " 1px solid var(--white)",
                            borderRadius: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            lineHeight: "20px",
                            padding: 0,
                            margin: 0,
                          }}
                          onClick={(e: any) => {
                            // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                            //   ? shareablePlans?.maxLines >= selectedLine
                            //     ? selectPlanHandler(e, "byTheGig")
                            //     : " "
                            //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                            selectPlanHandler(e, el, "remove");
                          }}
                        >
                          <span>-</span>
                        </Typography>
                        <Typography
                          sx={{
                            width: "20%",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {getQuantity(el)}
                        </Typography>
                        <Typography
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "var(--text_color_12)",
                            textAlign: "center",
                            cursor: "pointer",
                            backgroundColor: "var(--white)",
                            border: " 1px solid var(--white)",
                            borderRadius: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            lineHeight: "20px",
                            padding: 0,
                            margin: 0,
                          }}
                          onClick={(e: any) => {
                            // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                            //   ? shareablePlans?.maxLines >= selectedLine
                            //     ? selectPlanHandler(e, "byTheGig")
                            //     : " "
                            //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                            selectPlanHandler(e, el, "add");
                          }}
                        >
                          <span>+</span>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box sx={{ paddingLeft: '10px', paddingRight: '10px'}}>
                <NutritionLabel
                  NLContent={planContent}
                  plandata={el}
                  planCost={planCost}
                  index={index}
                  showAnswer={showAnswer}
                  handleAnswer={handleAnswer}
                  isheading={true}
                />
                </Box>
              </Card>
            );
          })}
        {isMobile && (
          <Typography
            component={"div"}
            style={{
              opacity: 0,
            }}
          >
            div
          </Typography>
        )}
        {selectedPlanDetail.length > 0 ? (
          <Box
            sx={{
              backgroundColor: "var(--white)",
              width: "100%",
              padding: "10px 0px",
              position: "fixed",
              left: 0,
              bottom: "0px",
              height: { xs: "auto" },
              zIndex: "999",
              boxShadow: "0px 0px 4px 2px #00000026",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "space-around" },
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--primary_color)",
                      fontWeight: "var(--font_weight_5)",
                      fontSize: { xs: "24px", md: "45px" },
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    $
                    {selectedPlanDetail
                      .reduce((tot: any, arr: any) => {
                        return (
                          tot +
                          caclulatePlanCostBasedOnEligibility(
                            arr?.plan?.baseLinePrice,
                            0
                          )
                        );
                      }, 0)
                      .toFixed(2)}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      color: "var(--title-color_1)",
                      fontSize: "18px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                      py: { xs: "10px", sm: "0px" },
                    }}
                  >
                    {selectedPlanDetail.length} {of_txt}{" "}
                    {configKeys?.MAX_LINES_GROUP} {lines_selected}
                  </Typography>
                </Box>
                <Divider
                  orientation={isMobile ? "horizontal" : "vertical"}
                  sx={{
                    display: "flex",
                    m: { xs: "0px", sm: "21px" },
                    height: { xs: "0px", sm: "60px" },
                    width: { xs: "90%", sm: "unset" },
                    borderWidth: "1px",
                    borderColor: "var(--divider_color) !important",
                    opacity: "30%",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "center", sm: "flex-start" },
                    justifyContent: "center",
                    flexDirection: "column",
                    width: { xs: "100%", sm: "fit-content" },
                    // borderLeft: { xs: "none", md: "1px solid #004282" },
                  }}
                >
                  {filteredPlans &&
                    Object.keys(filteredPlans).map((displayName: any) => {
                      return (
                        <Typography
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: "var(--font_weight_4)",
                            fontFamily: "var(--font_family_Bold)",
                            padding: "2px",
                            color: "var(--title-color_1)",
                            pt: { xs: "20px", sm: "0px" },
                          }}
                          key={displayName}
                        >
                          {filteredPlans[displayName]} - {displayName}
                        </Typography>
                      );
                    })}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center" },
                  marginTop: { xs: "20px", md: "0" },
                }}
              >
                <ButtonCust
                  sx={{
                    width: { xs: "250px", sm: "230px" },
                    marginBottom: { xs: "20px", sm: "0px" },
                  }}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  data-testid="selectPlanHandlerissimpaid"
                  onClick={(e: any) => {
                    navigate(RoutingURLs.checkout);
                    gtmTagManager({
                      event: gaScreenNames.checkout,
                    });
                  }}
                >
                  {checkout_cta}
                </ButtonCust>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Grid>
    </Stack>
  );
};

export default T13_PlanCard;
