import { Box, Paper } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import ENVConfig from "../../../config/env.config.json";
import styles from "./LoginAndSignUp.module.scss";
import { strapiSignupLoginContent } from "../../../features/strapi/signinSlice";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
type Props = { children: ReactNode };

export const LoginAndSignUp = (props: Props) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const [cacheContent, setCacheContent] = useState<any>(null);
  const { signupAndLoginContent } = useAppSelector(
    (state: any) => state.strapi.signinPage
  );
  const { bg_web, bg_ipad, bg_ipad_pro, bg_mobile } =
    signupAndLoginContent || cacheContent || {};
  useEffect(() => {
    getCache(
      "SignupLogInComponent",
      strapiSignupLoginContent,
      setCacheContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("SignupLogInComponent", signupAndLoginContent);
  }, [signupAndLoginContent]);
  return (
    (cacheContent || signupAndLoginContent) && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          background: {
            sm: bg_web?.data?.attributes?.url
              ? `url(${bg_web?.data?.attributes?.url}) no-repeat bottom`
              : "linear-gradient( var(--white) 50%, var(--login-signup-color) 50.5%)",
            xs: bg_mobile?.data?.attributes?.url
              ? `url(${bg_mobile?.data?.attributes?.url}) no-repeat bottom`
              : "unset",
          },
          width: "100%",
          py: "40px",
        }}
      >
        <Paper
          sx={{
            // padding: { xs: "10px 0", sm: "40px 50px" },
            paddingLeft: "50px",
            paddingRight: "50px",
            margin: { xs: "0", sm: "10% 0" },
            boxShadow: {
              xs: "unset",
              sm: "0 0 3px 0 rgba(174, 174, 174, 0.81)",
            },
          }}
          className={`${
            ENVConfig.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA
              ? styles.login_singup_main_1
              : styles.login_singup_main_2
          }`}
        >
          {children}
        </Paper>
      </Box>
    )
  );
};
