export const CurrentPlanCharges = {
  PLAN_CHARGES: "plan charges",
  CURRENT_CHARGES: "current charges",
  TAXES_AND_FEE : "taxes and fees",
  ADD_CARD_BILLING: "addCardBilling",
  AUTO_PAY: "autopay",
};

export const InvoicePlanTypes = {
  INFORMATION: "Information",
  ADJUSTMENT: "Adjustment",
  CHARGE: "Charge",
  DISCOUNT: "Discount",
  REFERRAL: "Referral",
  CREDIT: "Credit",
  PLAN: "Plan",
};

export const AccordionTypes = {
  PLAN_DETAILS: "PLAN_DETAILS",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PAYMENT_HISTORY: "PAYMENT_HISTORY",
};