import { Box, Checkbox, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonCust, SelectCust, SimpleModal } from "../../../widgets";
import styles from "./OneTimeCharge.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { type4TaxCalculation } from "../../../../features/account/accountSlice";
import { shipmentOrder } from "../../../../features/trackorder/trackorderSlice";
import { useNavigate } from "react-router-dom";
import PaymentCartComp from "./PaymentCartComp";
import { UpdateCustomerSource } from "../../../../features/checkout/checkoutSlice";
import { CardTypes } from "../../../../enums";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import EnvConfig from "../../../../config/env.config.json";
type Props = {
  onClose?: any;
  changeCard: any;
  changeAddress: any;
  latestAddress: any;
  loaderHandler: any;
  btnLoader: boolean;
  currentLine: any;
  content: any;
  kitBasePrice: any;
  sendSimOptions: any;
  handleNavigateNext: any;
  showShipmentCharges?: any;
  //   styles?: any;
};
// const { header_txt, card_section, main_class } = styles;
function OneTimeChargeComp(props: Props) {
  const {
    onClose,
    changeCard,
    changeAddress,
    latestAddress,
    loaderHandler,
    btnLoader,
    currentLine,
    content,
    kitBasePrice,
    sendSimOptions,
    handleNavigateNext,
    // styles,
  } = props;
  const { c_num } = content;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let customerDetails: any = localStorage.getItem("customerDetail");
  let storedDeliveryType = localStorage.getItem("oneTimeChargeDeliveryType");
  customerDetails = customerDetails ? JSON.parse(customerDetails) : null;
  const [sendSimOption, setSimOption] = useState(
    sendSimOptions?.[0] ? sendSimOptions : []
  );
  const { getCCDetails, type4Tax, tax4Loader } = useAppSelector(
    (state: any) => state?.account || {}
  );
  const [pageSelection, setPageSelection] = useState<any>(
    props?.showShipmentCharges ? "paymentPage" : "oneTimeChrg"
  );
  const {
    title,
    card_txt,
    change_add,
    change_txt,
    confirm_pay,
    tax_txt,
    total_txt,
    wel_kit,
    chkbox_txt,
    err_msg,
    visa,
    american_exp,
    num_mask,
  } = content?.tm_chgs || {};

  const { creditCardData } = useAppSelector(
    (state: any) => state?.billing || {}
  );
  const { ccuuid } = creditCardData;

  const { totalTax } = type4Tax || {};

  const currentCard = getCCDetails?.filter((val: any) =>
    ccuuid ? val?.uuid === ccuuid : val?.isAutoPay === true
  );
  const [deiveryType, setDeliveryType] = useState<any>(null);
  const [currentDelivery, setCurrentDelivery] = useState<any>(null);
  const [deliveryCost, setDeliveryCost] = useState<any>(null);
  const [tick, setTick] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [addressData, setAddressData] = useState<any>(
    latestAddress ? latestAddress : {}
  );

  const [chargeData, setChargeData] = useState([
    { label: wel_kit, value: 0, id: 1 },
    { id: 2 },
    { label: tax_txt, value: totalTax, id: 3 },
    { label: total_txt, value: 0, id: 4 },
  ]);

  useEffect(() => {
    latestAddress === null &&
      setAddressData({ ...customerDetails?.addresses[1] });
    fetchData();
  }, [kitBasePrice]);
  useEffect(() => {
    if (props?.showShipmentCharges) {
      setPageSelection("paymentPage");
    } else {
      setPageSelection("oneTimeChrg");
    }
  }, [props?.showShipmentCharges]);
  useEffect(() => {
    const newAddress: any = {
      address1: latestAddress?.address1,
      city: latestAddress?.city,
      country: latestAddress?.country,
      zip: latestAddress?.zip,
      state: latestAddress?.state,
    };
    latestAddress && setAddressData({ ...addressData, ...newAddress });
  }, [latestAddress]);
  useEffect(() => {
    const totalDueAmt = chargeData?.[0]?.value + totalTax + deliveryCost;
    const resetData: any = [...chargeData];
    resetData[2].value = totalTax;
    resetData[3].value = totalDueAmt;
    setChargeData(resetData);
  }, [totalTax, deliveryCost]);

  useEffect(() => {
    const firstOb: any = sendSimOption?.[0];
    firstOb &&
      callTaxCalculation(
        storedDeliveryType ? storedDeliveryType : firstOb?.value
      );
  }, [sendSimOption]);
  const fetchData = async () => {
    let firstObj: any;

    firstObj = sendSimOptions?.[0];
    setSimOption(sendSimOptions);
    setDeliveryType(firstObj?.value);
    setCurrentDelivery(firstObj);

    const resetData: any = [...chargeData];
    resetData[0].value = kitBasePrice;
    setChargeData(resetData);
    setDeliveryCost(firstObj?.cost);
  };
  const onChangeHandler = (e: any) => {
    localStorage.setItem("oneTimeChargeDeliveryType", e);
    setDeliveryType(e);
    callTaxCalculation(e);
  };
  const handleNavigateNextPage = () => {
    handleNavigateNext("oneTimeChrg");
    setPageSelection("paymentPage");
  };
  const callTaxCalculation = (selectedServiceType: any) => {
    let x = sendSimOption?.filter((vals: any) => {
      return selectedServiceType === vals?.serviceType;
    });
    setCurrentDelivery(x);
    const cost: any = x?.[0] || null;
    const payload = {
      address: { ...customerDetails?.addresses[1], type: "type1" },
      amount: cost?.shippingAmount,
      type: 1,
    };
    delete payload.address.phone;
    setDeliveryCost(cost?.shippingAmount);
    dispatch(type4TaxCalculation(payload));
  };
  const confirmToPay = () => {
    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 4);
    const payload: any = {
      cId: customerDetails.id,
      reachCarrierId: currentDelivery?.[0]?.reachCarrierId,
      deliveryDays: currentDelivery?.[0]?.deliveryDays,
      serviceType: currentDelivery?.[0]?.serviceType,
      serviceCode: currentDelivery?.[0]?.serviceCode,
      carrierCode: currentDelivery?.[0]?.carrierCode,
      shipmentType: "PHYSICAL",
      category: "SIM",
      shippingAmount: currentDelivery?.[0]?.cost,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: totalTax,
      taxId: type4Tax?.taxId,
      taxInfo: type4Tax,
      totalAmount: totalTax + currentDelivery?.[0]?.cost || 0,
      ccUUID: currentCard?.[0]?.uuid,
      shipTo: {
        city: addressData?.city,
        country: customerDetails?.addresses?.[0]?.country,
        name: customerDetails?.addresses?.[0]?.name,
        phone: customerDetails?.addresses?.[0]?.phone,
        postalCode: addressData?.zip,
        residential: "false",
        state: addressData?.state,
        street1: addressData?.address1,
      },
      items: [
        {
          quantity: 1,
          unitPrice: 0,
        },
      ],
    };
    dispatch(shipmentOrder(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        loaderHandler(false);
        const py = {
          activationJourneyStatus: "IN_PROGRESS",
          esim: false,
          physicalSimsOrdered: 1,
          simType: "PHYSICAL",
        };
        dispatch(
          UpdateCustomerSource({
            customerId: currentLine.id,
            data: py,
          })
        );

        setTimeout(() => {
          navigate(RoutingURLs.trackOrder);
        }, 1000);
      } else {
        setErrorMsg(true);
      }
      loaderHandler(false);
    });
    loaderHandler(true);
  };
  return (
    <>
      {pageSelection === "oneTimeChrg" ? (
        <Box
          sx={{
            width: { xs: "90%", sm: "50%" },
            margin: "0 auto",
            background: "var(--white)",
            my: "60px",
            maxWidth: content?.step_txt_next ? "570px" : "unset",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderBottom: "2px solid var(--lightgrey_6)",
              p: "33px 0",
            }}
          >
            {/* <Box
              component="img"
              alt={content?.swap_psim_icon}
              src={content?.swap_psim_icon?.data?.attributes?.url || ""}
              mr="10px"
              className={styles?.profile_img}
              width="51px"
              height="35px"
            /> */}
            <Box
              sx={{
                fontFamily: "var(--font_family_Bold)",
                fontWeight: "var(--font_weight_4)",
                fontSize: "24px",
                lineHeight: "40px",
                color: "var(--black)",
              }}
            >
              {content?.swap_psim_txt}
            </Box>
          </Box>
          {/* shipping box */}
          <Box className={styles.boxContainer}>
            <Box
              display="flex"
              justifyContent="space-between"
              className={styles.selectShipDetails}
              sx={{
                mb: "30px",
              }}
            >
              <SelectCust
                formStyles={{
                  width: "100%",
                }}
                options={sendSimOption}
                value={storedDeliveryType ? storedDeliveryType : deiveryType}
                onChange={(e: any) => {
                  onChangeHandler(e?.target?.value);
                }}
              />
            </Box>
            {/* checkBox complete  */}
            <Box
              className={styles?.change_add}
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #D9D9D9",
                p: "20px 0",
                borderRadius: "5px",
              }}
            >
              <Box className={styles?.shipping_text}>
                <Checkbox
                  sx={{
                    color: "var(--primary_color)",
                    "&.Mui-checked": {
                      color: "var(--primary_color)",
                    },
                  }}
                  onChange={() => {
                    setTick(!tick);
                  }}
                  checked={tick}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "0px 20px 10px 20px",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Regular)",
                    fontWeight: "var(--font_weight_0)",
                    fontSize: "16px",
                    lineHeight: "28px",
                  }}
                >
                  {content?.swap_checkbox_txt}
                </Box>
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1)",
                    fontSize: "16px",
                    lineHeight: "28px",
                    textDecoration: "underline",
                  }}
                  mb={0.5}
                  className={styles?.address_text}
                >
                  {addressData?.address1}
                  {", "}
                  {addressData?.city}
                  {", "}
                  {addressData?.state}
                  {", "}
                  {addressData?.zip}
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                className={styles.card_text}
                sx={{
                  color: "var(--primary_color) !important",
                  cursor: tick ? "not-allowed" : "pointer",
                  fontSize: "16px !important",
                  my: "15px",
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_2)",
                  textAlign: "right",
                  lineHeight: "29px",
                  opacity: tick ? 0.6 : 1,
                }}
                ml="10px"
                onClick={() => (tick ? null : changeAddress(addressData))}
              >
                {content?.swap_change_addr}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <ButtonCust
                variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
                sx={{
                  width: content?.chg_is_lbl ? "250px" : "inherit",
                  mt: "25px",
                }}
                // onClick={confirmToPay}
                onClick={handleNavigateNextPage}
                disabled={!tick || tax4Loader}
                loading={btnLoader}
              >
                {content?.swap_btn_txt}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      ) : (
        <PaymentCartComp
          // onClose={closeOneTimeCharge}
          changeCard={changeCard}
          changeAddress={changeAddress}
          latestAddress={addressData}
          loaderHandler={loaderHandler}
          btnLoader={btnLoader}
          currentLine={currentLine}
          content={content}
          sendSimOptions={sendSimOption}
          kitBasePrice={kitBasePrice}
        />
      )}
    </>
  );
}
export default OneTimeChargeComp;
