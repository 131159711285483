import { Box, CircularProgress, Typography } from "@mui/material";
import styles from "./PESimSwap.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ButtonCust, SimpleModal } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import { useEffect, useState } from "react";
import { ActivationStatus, SimTypeEnums, orderType } from "../../../../enums";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  shipmentOrder,
  shipmentOrderNew,
} from "../../../../features/trackorder/trackorderSlice";
import {
  UpdateCustomerSource,
  getAppData,
} from "../../../../features/checkout/checkoutSlice";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";

const PESimSwap = (props: any) => {
  const [sendSimOptions, setSendSimOptions] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { content, setPSimToESimJourney, currentLine } = props;
  const {
    simswap_title,
    simswap_subtitle,
    helper,
    note_txt,
    note_msg,
    confrm_email_txt,
    update_email,
  } = content;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { oneTimeSIMTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const { creditCardData } = useAppSelector(
    (state: any) => state?.billing || {}
  );
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { ccuuid } = creditCardData;
  const currentCard = getCCDetails?.filter((val: any) =>
    ccuuid ? val?.uuid === ccuuid : val?.isAutoPay === true
  );
  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = customerDetails ? JSON.parse(customerDetails) : null;
  useEffect(() => {
    fetchShipmentPlans();
  }, []);
  const fetchShipmentPlans = () => {
    dispatch(getAppData()).then((res: any) => {
      if (
        res?.payload?.status === 200 &&
        res?.payload?.data?.status === "SUCCESS"
      ) {
        const shippingRates = res?.payload?.data?.data?.shippingRates;
        let shipmentPlansList: any = [];
        if (shippingRates && shippingRates.length > 0) {
          shippingRates.forEach((el: any) => {
            shipmentPlansList.push({
              label: `${el && el.name}`,
              value: el && el.serviceCode,
              cost: el && el.shippingAmount,
              ...el,
            });
          });
        }
        setSendSimOptions(shipmentPlansList);
      }
    });
  };
  const confirmToPay = () => {
    setLoader(true);
    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 4);
    const esimTax = { totalTax: 0 };
    const esimPayload: any = {
      cId: customerDetails.id,
      reachCarrierId: sendSimOptions[0]?.reachCarrierId,
      deliveryDays: 0,
      serviceType: "email",
      serviceCode: "email",
      carrierCode: sendSimOptions[0]?.carrierCode,
      shipmentType: "ESIM",
      category: "SIM",
      shippingAmount: 0,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: 0,
      totalAmount: 0,
      taxId: oneTimeSIMTaxCharges?.taxId
        ? oneTimeSIMTaxCharges?.taxId
        : esimTax?.totalTax,
      taxInfo: oneTimeSIMTaxCharges ? oneTimeSIMTaxCharges : esimTax,
      ccUUID: currentCard?.[0]?.uuid,
      secondaryCustomers: currentLine.secondaryCustomers,
      shipTo: {
        city: customerDetails?.addresses[0]?.city,
        country: "USA",
        name: customerDetails?.addresses[0]?.name,
        phone: customerDetails?.addresses[0]?.phone,
        postalCode: customerDetails?.addresses[0]?.zip,
        residential: "false",
        state: customerDetails?.addresses[0]?.state,
        street1: customerDetails?.addresses[0]?.address1,
      },
      items: [
        {
          quantity: 1,
          unitPrice: 0,
        },
      ],
      orderType: orderType.RE_ORDER,
      shipmentReason: orderType.CustomerRequest,
    };
    dispatch(shipmentOrderNew(esimPayload)).then((res: any) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        const py = {
          activationJourneyStatus: ActivationStatus.IN_PROGRESS,
          esim: true,
          physicalSimsOrdered: 1,
          simType: SimTypeEnums.eSim,
          isSimInHand: false,
          simId: "",
        };
        dispatch(
          UpdateCustomerSource({
            customerId: currentLine.id,
            data: py,
          })
        ).then((res: any) => {
          if (res?.payload?.status === 200) {
            navigate(RoutingURLs.activateEsim, { state: "isTrack" });
          }
        });
      } else {
        setLoader(false);
      }
    });
    gtmTagManager({
      event: globalVal?.gtmEvents?.confirmEmailChangeToESIM,
      click_section: "activation",
    });
  };

  return (
    <Box className={styles.heading_container}>
      <Box
        className={styles.contentContainer}
        sx={{
          // padding: { xs: "10px", sm: "20px" },
          width: { sm: "50%", xs: "90%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: "24px 30px 14px",
            alignItems: "center",
          }}
        >
          {/* <Box
            component="img"
            alt={content?.cnf_img?.data?.attributes?.alt}
            src={content?.cnf_img?.data?.attributes?.url}
          /> */}
          <Typography className={styles.title_main} sx={{ pl: "15px" }}>
            {simswap_title}
          </Typography>
        </Box>
        <hr style={{ border: "1px solid var(--lightgrey_6)" }}></hr>
        <Typography className={styles.subtitle_main}>
          {simswap_subtitle}
        </Typography>
        <Box
          className={styles.emailContainer}
          sx={{ padding: { xs: "35px 0px", sm: "23px 30px 24px" } }}
        >
          <Box
            className={styles.emailBox}
            sx={{ width: { sm: "439px", xs: "80%" } }}
          >
            <Box className={styles.email}>{customerDetails?.emailId}</Box>
            <Box
              onClick={() => {
                window.open(content?.dn_ac_em_lnk, "_blank");
                gtmTagManager({
                  event: globalVal?.gtmEvents?.dontHaveAccessToEmail,
                  click_section: "activation",
                });
              }}
              className={styles.helper}
            >
              {helper}
            </Box>
          </Box>
        </Box>
        <Box className={styles.noteContainer}>
          <Box
            component={"span"}
            sx={{
              color: "var(--button_secondary_color)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "var(--font_weight_1)",
              fontFamily: "var(--font_family_Medium)",
            }}
            textAlign={"center"}
          >
            {note_txt}
            <Box component={"span"} className={styles.note}>
              {" "}{note_msg}
            </Box>
          </Box>
        </Box>
        <ButtonCust
          variantType={Config.PRIMARY_BUTTON_TYPE}
          sx={{
            width: "200px",
            mb: "30px",
          }}
          onClick={!loader ? confirmToPay : () => {}}
        >
          {loader ? <CircularProgress /> : confrm_email_txt}
        </ButtonCust>
      </Box>
    </Box>
  );
};

export default PESimSwap;
