import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/app.config.json";
import EnvConfig from "../../config/env.config.json";
import { Store } from "react-notifications-component";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../../adapters/firebase";
import { NotificationCust } from "../widgets";
import { validateEmail } from "../../utils/commonFunctions/ValidateEmail";
import {
  GetCustomer,
  loginWithNumber,
  resetPassword,
  storeLoginData,
} from "../../features/checkout/checkoutSlice";
import { strapiLoginContent } from "../../features/strapi/signinSlice";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { emailPattern } from "../../utils/regexPatterns";
import { updateToHubspot } from "../../utils/commonFunctions/hubspot";
import { getInTouchApi } from "../../features/homepage/homepageSlice";
import { RoutingURLs } from "../../config/RoutingURLs";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import Cookies from "js-cookie";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { CustomerStatus } from "../../enums";
import Plans from "../../config/data.json";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import { gtmTagManager } from "../../utils/commonFunctions/GtmTagManager";
import FirebaseDBURLs from "../../config/FirebaseDBURLs";
import {
  shopwareAuthentication,
  checkUserExist,
} from "../../utils/commonFunctions/shopWareFunctions";
import {
  getCartItems,
  shopwareAuth,
} from "../../features/shopWare/shopWareSlice";

const LoginTemplate = isTemplateExisits(Config.Login.Login)
  ? require(`./${Config.Login.Login.template}_Login`).default
  : null;
const ForgotPassword = isTemplateExisits(Config.ForgotPassword.ForgotPassword)
  ? require(`./${Config.ForgotPassword.ForgotPassword.template}_ForgotPassword`)
      .default
  : null;

type CommonProps = {
  email: string;
  emailError: string;
  onChangeHandler: any;
  handleToggle: any;
  content: any;
};

export interface LoginProps extends CommonProps {
  password: string;
  passwordError: string;
  isLoginClicked: boolean;
  handleLogIn: any;
  onGoogleLogin: any;
  onAppleLogin: any;
  onFacebookLogin: any;
  handleEnter: any;
  showSignUp?: any;
}

export interface ForgotPasswordProps extends CommonProps {
  handleForgotPassword: any;
  isForgotClicked: boolean;
}
type Props = {
  isCheckout?: boolean;
  toggleForm?: any;
  commonSocialRedirect?: any;
};

export const LoginComponent = (props: Props) => {
  let navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const { isCheckout, toggleForm, commonSocialRedirect } = props;

  const [cacheContent, setCacheContent] = useState<any>(null);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [credentialErrors, setCredentialErrors] = useState({
    emailError: "",
    passwordError: "",
  });
  const [isLoginClicked, setIsLoginClicked] = useState(true);
  const [isForgotClicked, SetIsForgotClicked] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const {
    loginNumberSuccess,
    errorLoginNumber,
    resetPasswordData,
    errorResetPasswordData,
  } = useAppSelector((state: any) => state.checkout);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );

  const { loginContent } = useAppSelector(
    (state: any) => state.strapi.signinPage
  );
  const { shopwareAuthSuccess, shopwareAuthError } = useAppSelector(
    (state: any) => state.shopWare
  );
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.login,
      category: category,
      buttonValue: value,
    });
  };

  useEffect(() => {
    getCache("LogInComponent", strapiLoginContent, setCacheContent, dispatch);
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let userExist: any = checkUserExist();
      let searchParams = new URLSearchParams(document.location.search);
      let logoutCheck: string | null = searchParams.get("logout");
      if (userExist) {
        navigate(RoutingURLs.home);
      }
      if (!logoutCheck) {
        dispatch(getCartItems());
      }
    }
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("LogInComponent", loginContent);
  }, [loginContent]);

  useEffect(() => {
    window.scroll(0, 0);
    localStorage.removeItem("currentuser");
    localStorage.removeItem("accessToken");
    if (localStorage.getItem("customerDetail")) {
      let user: any = localStorage.getItem("customerDetail");
      let parsedUser = JSON.parse(user);
      if (parsedUser && parsedUser.status === 2) {
        !isCheckout && navigate(RoutingURLs.postActivationHome);
      } else if (parsedUser && parsedUser.status === 0) {
        !isCheckout && navigate(RoutingURLs.home);
      }
    } else {
      localStorage.removeItem("currentuser");
      localStorage.removeItem("accessToken");
    }
    if (location && location.state && location.state.isForgotPassword) {
      setIsResetPassword(true);
    }

    return () => {
      Store.removeNotification("LoginErrorNotification");
      Store.removeNotification("LoginForgotNotification");
      Store.removeNotification("LoginForgotErrorNotification");
    };
  }, []);

  useNonInitialEffect(() => {
    if (loginNumberSuccess?.status === "SUCCESS" && loginNumberSuccess.data) {
      let emailId = loginNumberSuccess.data.emailId;
      initUserSignUp(emailId);
    }
  }, [loginNumberSuccess]);

  useNonInitialEffect(() => {
    if (errorLoginNumber?.status === "FAILURE") {
      setIsLoginClicked(true);
      showErrorMessage("incorrect-number");
    }
  }, [errorLoginNumber]);

  const {
    notification,
    loginErrors,
    validation_errors,
    btn_forgot,
    btn_login,
  } = cacheContent ? cacheContent : loginContent || {};

  useNonInitialEffect(() => {
    if (resetPasswordData && resetPasswordData.status === "SUCCESS") {
      NotificationCust({
        message: notification && notification.passwordReset,
        duration: 2000,
        id: "LoginForgotNotification",
      });
    }
    setIsResetPassword(false);
    setCredentials((values) => ({
      ...values,
      email: "",
    }));
    SetIsForgotClicked(false);
  }, [resetPasswordData]);

  useNonInitialEffect(() => {
    if (errorResetPasswordData && errorResetPasswordData.status === "FAILURE") {
      NotificationCust({
        message: notification.emailError,
        duration: 2000,
        type: "danger",
        id: "LoginForgotErrorNotification",
      });
    }
    SetIsForgotClicked(false);
  }, [errorResetPasswordData]);

  useNonInitialEffect(() => {
    if (shopwareAuthSuccess) {
      let idTokenValue: any = localStorage.getItem("accessToken");
      let currentUser: any = localStorage.getItem("currentuser");
      currentUser = JSON.parse(currentUser);
      let swContextToken: any = shopwareAuthSuccess["sw-context-token"];
      let lineItemsInCart: any = localStorage.getItem("lineItems");
      lineItemsInCart = JSON.parse(lineItemsInCart);
      let user1: any = localStorage.getItem("currentuser");
      dispatch(storeLoginData({ userData: userInfo }));
      user1 = JSON.parse(user1);
      if (lineItemsInCart?.length > 0) {
        let value: string = "show";
        localStorage.setItem("typeNotify", value);
      }
      if (swContextToken) {
        let currentUser: any = localStorage.getItem("currentuser");
        currentUser = JSON.parse(currentUser);
        Cookies.set("swContextToken", `${swContextToken}`);
        let searchParams = new URLSearchParams(document.location.search);
        let Redirect_Url: string | null = searchParams.get("redirect_url");
        if (Redirect_Url) {
          window.location.assign(
            `${Redirect_Url}?token=${swContextToken}&firebase_token=${idTokenValue}&logged_in=${
              currentUser && currentUser.email ? true : false
            }`
          );
        } else {
          isCheckout &&
            commonSocialRedirect(user1, "Email", notification?.loginSuccess);
          !isCheckout && handleRedirection(email);
        }
      }
    }
  }, [shopwareAuthSuccess]);

  useNonInitialEffect(() => {
    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    dispatch(storeLoginData({ userData: userInfo }));
    isCheckout &&
      commonSocialRedirect(user1, "Email", notification?.loginSuccess);
    !isCheckout && handleRedirection(email);
  }, [shopwareAuthError]);

  function onChangeHandler(e: any) {
    setCredentials((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "email") {
      setCredentialErrors((values) => ({
        ...values,
        emailError: "",
      }));
    } else if (e.target.name === "password") {
      setCredentialErrors((values) => ({
        ...values,
        passwordError: "",
      }));
    }
  }
  const showErrorMessage = (code: any) => {
    let errorMsg = "";
    switch (code) {
      case "auth/email-already-in-use":
        errorMsg = loginErrors["signup"][code];
        break;
      case "auth/invalid-email":
        errorMsg = loginErrors["signup"][code];
        break;
      case "auth/operation-not-allowed":
        errorMsg = loginErrors["signup"][code];
        break;
      case "auth/weak-password":
        errorMsg = loginErrors["signup"][code];
        break;
      // eslint-disable-next-line no-duplicate-case
      case "auth/invalid-email":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/user-disabled":
        errorMsg = BrandNameUpdate(
          loginErrors["login"]["auth/user-disabled"] || ""
        );
        break;
      case "auth/user-not-found":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/wrong-password":
        errorMsg = loginErrors["login"][code];
        break;
      case "auth/account-exists-with-different-credential":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/auth-domain-config-required":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/cancelled-popup-request":
        errorMsg = "";
        break;
      case "auth/operation-not-supported-in-this-environment":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/popup-blocked":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/popup-closed-by-user":
        errorMsg = "";
        break;
      case "auth/unauthorized-domain":
        errorMsg = loginErrors["social_login"][code];
        break;
      case "auth/user-cancelled":
        errorMsg = "";
        break;
      case "incorrect-number":
        errorMsg = BrandNameUpdate(loginErrors["login"][code] || "");
        break;
      default:
        errorMsg = loginErrors["default"];
        break;
    }
    if (errorMsg)
      NotificationCust({
        message: errorMsg,
        type: "danger",
        duration: 2000,
        id: "LoginErrorNotification",
        showIcon: true,
      });
  };
  const onGoogleLogin = () => {
    GAAndFBEventsHandler(gaCategory.login, "Google Login");
    gtmTagManager({
      event: gaCategory.login,
      login_method: "Google Login",
    });
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        localStorage.setItem("currentuser", JSON.stringify(result.user));
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        checkUserSource(result?.user?.email);
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "google");
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const onAppleLogin = () => {
    GAAndFBEventsHandler(gaCategory.login, "Apple Login");
    gtmTagManager({
      event: gaCategory.login,
      login_method: "Apple Login",
    });
    let provider = new firebase.auth.OAuthProvider("apple.com");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        checkUserSource(result?.user?.email);
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "apple");
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const onFacebookLogin = () => {
    GAAndFBEventsHandler(gaCategory.login, "Facebook Login");
    gtmTagManager({
      event: gaCategory.login,
      login_method: "Facebook Login",
    });
    let provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        checkUserSource(result?.user?.email);
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "facebook");
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };
  const handleRedirection = (email: string) => {
    dispatch(GetCustomer({ username: encodeURIComponent(email) }))
      .then((response) => {
        if (response.payload.status === 200) {
          dispatch(getCartItems());
          const customerData = response.payload?.data?.data;
          const { simPaid, status } = customerData || {};
          if (
            ![
              CustomerStatus.ACTIVE,
              CustomerStatus.SUSPENDED,
              CustomerStatus.DISCONNECTED,
            ].includes(customerData.status) &&
            !customerData?.isPrimary
          ) {
            navigate(RoutingURLs.secondarylogin);
          } else if (status === CustomerStatus.INACTIVE && simPaid) {
            if (customerData?.esim) {
              navigate(RoutingURLs.myAccount);
            } else if (customerData?.isSimInHand) {
              if (EnvConfig?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
                if(EnvConfig?.IS_MULTI_SIM_IN_HAND_ENABLED){
                  navigate(RoutingURLs.myAccount);
                }else{
                  navigate(RoutingURLs.deviceConfirmCheck)
                }
              } else {
                navigate(RoutingURLs.activation);
              }
            } else navigate(RoutingURLs.myAccount, { state: customerData });
          } else if (status === CustomerStatus.INACTIVE && !simPaid) {
            if (!EnvConfig?.IS_SHOPWARE_ENABLED) {
              navigate(RoutingURLs.checkout, { state: customerData });
            } else {
              navigate(RoutingURLs.home);
            }
            if (EnvConfig.IS_MIX_AND_MATCH) {
              if (customerData?.linePreferences) {
                let newArr: any = [];
                Object.values(customerData?.linePreferences).map((el: any) => {
                  newArr.push(el);
                });
                localStorage.setItem(
                  "reachSelectedPlanDetail",
                  JSON.stringify(newArr)
                );
                navigate(RoutingURLs.checkout, { state: customerData });
              }
            } else {
              let planData;
              const customerPlan = Plans.data.find(
                (plan) =>
                  plan.name.toLowerCase() ===
                  customerData.reachPlanId.toLowerCase()
              );
              planData = JSON.stringify({
                line: Number(customerData.extraLines) + 1,
                plan: customerPlan,
              });
              localStorage.setItem("reachSelectedPlanDetail", planData);
            }
          } else if (
            status == CustomerStatus.ACTIVATION_IN_PROGRESS ||
            status == CustomerStatus.ACTIVE ||
            status == CustomerStatus.SUSPENDED ||
            status == CustomerStatus.DISCONNECTED ||
            status == CustomerStatus.HOTLINED ||
            status == CustomerStatus.ACTIVATION_FAILED ||
            status == CustomerStatus.PORTING_IN_PROGRESS ||
            status == CustomerStatus.PORTING_FAILED
          ) {
            navigate(RoutingURLs.postActivationHome);
          } else if (status == CustomerStatus.PORT_CANCELLED) {
            navigate(RoutingURLs.myAccount);
          }
        } else {
          navigate(RoutingURLs.home);
        }
      })
      .catch((err) => {
        localStorage.removeItem("customerDetail");
        navigate(RoutingURLs.home);
      });
  };
  const checkUserSource = async (email?: any) => {
    await firebase
      .database()
      .ref(FirebaseDBURLs.source + window.btoa(email))
      .once("value")
      .then((snapshot: any) => {
        let source = snapshot.val();
        if (source && source === EnvConfig.OPERATOR) {
          if (EnvConfig?.IS_SHOPWARE_ENABLED) {
            shopwareAuthHandler();
          } else {
            !isCheckout && handleRedirection(email);
          }
        } else {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.removeItem("currentuser");
              localStorage.removeItem("accessToken");
              localStorage.clear();
              localStorage.setItem("globalValues", JSON.stringify(globalVal));
            });
          window.open(`${EnvConfig.REDIRECT_URL}`, "_self");
        }
      })
      .catch((_error: any) => {
        if (EnvConfig?.IS_SHOPWARE_ENABLED) {
          shopwareAuthHandler();
        } else {
          !isCheckout && navigate(RoutingURLs.home);
        }
      });
  };

  const shopwareAuthHandler = () => {
    let payloadData: any = shopwareAuthentication();
    if (payloadData) {
      dispatch(shopwareAuth(payloadData));
    }
  };

  const initUserSignUp = (email: string) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, credentials.password)
      .then(async (user) => {
        const userinfo = {
          displayName: user.user?.displayName,
          email: user.user?.email,
          emailVerified: user.user?.emailVerified,
          isAnonymous: user.user?.isAnonymous,
        };
        const loginResponse: any = {
          ...user.user,
          ...userinfo,
        };
        localStorage.setItem("currentuser", JSON.stringify(loginResponse));
        let user1: any = JSON.stringify(loginResponse);
        user1 = JSON.parse(user1);
        let token = loginResponse?._delegate?.accessToken;
        localStorage.setItem("accessToken", token);
        if (!EnvConfig.IS_SHOPWARE_ENABLED) {
          dispatch(storeLoginData({ userData: userinfo }));
        } else {
          setUserInfo(userinfo);
        }
        dispatch(
          getInTouchApi({
            data: {
              emailId: credentials.email,
              hubspotMap: {
                email: credentials.email,
                is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
                is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
                // brand_name: globalVal?.short_name?.toLowerCase(), //brand name is not being used in hubspot this property is overriding hubsport config.
              },
            },
          })
        ).then((res) => {
          if (res?.payload?.status === 200) {
            localStorage.setItem("currentuserEmailId", credentials.email);
          }
        });
        if (EnvConfig?.IS_SHOPWARE_ENABLED) {
          isCheckout && shopwareAuthHandler();
        } else {
          isCheckout &&
            commonSocialRedirect(user1, "Email", notification?.loginSuccess);
        }
        if (EnvConfig.CHECK_USER_SOURCE) {
          checkUserSource(user?.user?.email);
        } else {
          if (EnvConfig?.IS_SHOPWARE_ENABLED) {
            shopwareAuthHandler();
          } else {
            if (!isCheckout) {
              dispatch(
                GetCustomer({
                  username: encodeURIComponent(email),
                })
              )
                .then((response: any) => {
                  if (response?.payload?.status === 200) {
                    {
                      const {
                        simPaid,
                        status,
                        isPrimary,
                        esim,
                        reachPlanId,
                        extraLines,
                        isSimInHand
                      } = response?.payload?.data?.data || {};
                      if (
                        ![
                          CustomerStatus.ACTIVE,
                          CustomerStatus.SUSPENDED,
                          CustomerStatus.DISCONNECTED,
                        ].includes(status) &&
                        !isPrimary
                      ) {
                        navigate(RoutingURLs.secondarylogin);
                      } else if (status === 0 && simPaid) {
                        if (esim) {
                          navigate(RoutingURLs.myAccount);
                        } else if (isSimInHand) {
                          if (EnvConfig?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
                            if(EnvConfig?.IS_MULTI_SIM_IN_HAND_ENABLED){
                              navigate(RoutingURLs.myAccount);
                            }else{
                              navigate(RoutingURLs.deviceConfirmCheck)
                            }
                          } else {
                            navigate(RoutingURLs.activation);
                          }
                        } else {
                          navigate(RoutingURLs.myAccount, {
                            state: response?.payload?.data?.data,
                          });
                        }
                      } else if (status === 0 && !simPaid) {
                        let planData;
                        const customerPlan = Plans.data.find(
                          (plan) =>
                            plan.name.toLowerCase() ===
                            reachPlanId.toLowerCase()
                        );
                        planData = JSON.stringify({
                          line: Number(extraLines) + 1,
                          plan: customerPlan,
                        });
                        localStorage.setItem(
                          "reachSelectedPlanDetail",
                          planData
                        );
                        if (!EnvConfig?.IS_SHOPWARE_ENABLED) {
                          navigate(RoutingURLs.checkout, {
                            state: response?.payload?.data?.data,
                          });
                        } else {
                          navigate(RoutingURLs.home);
                        }
                      } else if (
                        status == 1 ||
                        status == 2 ||
                        status == 3 ||
                        status == 4 ||
                        status == 5 ||
                        status == 8 ||
                        status == 9 ||
                        status == 10
                      ) {
                        navigate(RoutingURLs.postActivationHome);
                      } else if (status == 11) {
                        navigate(RoutingURLs.myAccount);
                      }
                    }
                  } else {
                    navigate(RoutingURLs.home);
                  }
                })
                .catch((err: any) => {
                  navigate(RoutingURLs.home);
                });
            }
          }
        }
      })
      .catch((error) => {
        setIsLoginClicked(true);
        showErrorMessage(error.code);
      });
  };

  const handleLogIn = (e: any) => {
    GAAndFBEventsHandler(gaCategory.login, btn_login);
    gtmTagManager({
      event: gaCategory.login,
      login_method: "email",
    });
    if (emailPattern.test(credentials.email)) {
      updateToHubspot(e, credentials.email);
    }
    setIsLoginClicked(false);

    if (credentials.email !== "" && credentials.password !== "") {
      if (/^[0-9() -]+$/.test(credentials.email)) {
        let phone = credentials.email.replace(/\D/g, "");

        dispatch(loginWithNumber({ loginNumber: phone }));
      } else {
        if (validateEmail(credentials.email)) {
          initUserSignUp(credentials.email);
        } else {
          setIsLoginClicked(true);
          setCredentialErrors((values) => ({
            ...values,
            emailError: validation_errors.invalidEmail,
          }));
        }
      }
    } else {
      setIsLoginClicked(true);
      if (credentials.email === "") {
        setCredentialErrors((values) => ({
          ...values,
          emailError: validation_errors.emptyEmail,
        }));
      } else if (
        credentials.email !== "" &&
        !validateEmail(credentials.email)
      ) {
        setCredentialErrors((values) => ({
          ...values,
          emailError: validation_errors.invalidEmail,
        }));
      }
      if (credentials.password === "") {
        setCredentialErrors((values) => ({
          ...values,
          passwordError: validation_errors.emptyPassword,
        }));
      }
    }
  };

  const handleForgotPassword = () => {
    GAAndFBEventsHandler(gaCategory.forgotPassword, btn_forgot);
    gtmTagManager({
      event: gaCategory.forgotPassword,
    });
    if (
      credentials.email !== "" &&
      validateEmail(credentials.email) &&
      EnvConfig?.IS_ENABLE_BACKEND_FORGOT_PASSWORD_REQUEST
    ) {
      SetIsForgotClicked(true);
      dispatch(
        resetPassword({
          email: credentials.email,
          tenantId: EnvConfig.TENANT_ID,
          actionCodeSettings: {
            url: EnvConfig.RP_URL,
            handleCodeInApp: EnvConfig.RP_HANDLE_CODE_IN_APP,
            iOS: {
              bundleId: EnvConfig.RP_IOS_BUNDLE_ID,
            },
            android: {
              packageName: EnvConfig.RP_ANDROID_PACKAGE_NAME,
              installApp: EnvConfig.RP_ANDROID_INSTALL_APP,
              minimumVersion: EnvConfig.RP_ANDROID_MIN_VERSION,
            },
            dynamicLinkDomain: EnvConfig.RP_DYNAMIC_LINK_DOMAIN,
          },
        })
      );
    } else if (credentials.email !== "" && validateEmail(credentials.email)) {
      SetIsForgotClicked(true);

      firebase
        .auth()
        .sendPasswordResetEmail(credentials.email)
        .then(async () => {
          SetIsForgotClicked(false);
          setIsResetPassword(false);
          NotificationCust({
            message: notification?.passwordReset,
            duration: 2000,
            id: "LoginForgotNotification",
          });
        })
        .catch((error: any) => {
          SetIsForgotClicked(false);
          showErrorMessage(error.code);
        });
    } else {
      if (credentials.email === "") {
        setCredentialErrors((values) => ({
          ...values,
          emailError: "Email address cannot be empty",
        }));
      } else if (
        credentials.email !== "" &&
        !validateEmail(credentials.email)
      ) {
        setCredentialErrors((values) => ({
          ...values,
          emailError: "Enter a valid email address",
        }));
      }
    }
  };
  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogIn(e);
    }
  };

  const showSignUp = () => {
    GAAndFBEventsHandler(
      gaCategory.login,
      cacheContent ? cacheContent?.no_account2 : loginContent?.no_account2
    );
    if (isCheckout) return toggleForm();
    else if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let searchParams = new URLSearchParams(document.location.search);
      let Redirect_Url: string | null = searchParams.get("redirect_url");
      if (Redirect_Url) {
        navigate(`${RoutingURLs.signUp}?redirect_url=${Redirect_Url}`);
      } else {
        navigate(RoutingURLs.signUp);
      }
    } else {
      navigate(RoutingURLs.signUp);
    }
  };

  const forgotPassHandler = () => {
    if (isCheckout)
      return navigate(RoutingURLs.login, { state: { isForgotPassword: true } });
    if (
      credentialErrors.emailError !== "" ||
      credentialErrors.passwordError !== ""
    )
      setCredentialErrors((values) => ({
        ...values,
        passwordError: "",
        emailError: "",
      }));
    setIsResetPassword(true);
  };
  const { email, password } = credentials;
  const { emailError, passwordError } = credentialErrors;
  return (
    (cacheContent || loginContent) && (
      <>
        {isResetPassword ? (
          <ForgotPassword
            email={email}
            emailError={emailError}
            onChangeHandler={(e: any) => onChangeHandler(e)}
            handleToggle={() => {
              if (credentialErrors.emailError !== "")
                setCredentialErrors((values) => ({
                  ...values,
                  emailError: "",
                }));
              setIsResetPassword(false);
              GAAndFBEventsHandler(gaCategory.login, "forgot password");
            }}
            isForgotClicked={isForgotClicked}
            handleForgotPassword={handleForgotPassword}
            content={cacheContent ? cacheContent : loginContent}
          />
        ) : (
          <LoginTemplate
            email={email}
            password={password}
            emailError={emailError}
            passwordError={passwordError}
            onChangeHandler={(e: any) => onChangeHandler(e)}
            handleToggle={forgotPassHandler}
            isLoginClicked={isLoginClicked}
            handleLogIn={handleLogIn}
            onGoogleLogin={onGoogleLogin}
            onAppleLogin={onAppleLogin}
            onFacebookLogin={onFacebookLogin}
            handleEnter={handleEnter}
            content={cacheContent ? cacheContent : loginContent}
            showSignUp={showSignUp}
          />
        )}
      </>
    )
  );
};
