import { CheckCoverageProps } from "./CheckCoverage";
import { SecondaryInfoCard } from "../../common";
import styles from "./S5_CheckCoverage.module.scss";
import { AutoComplete, SimpleModal, ButtonCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { Box, Container, Typography } from "@mui/material";
import { addressDisplayPattern } from "../../../utils/regexPatterns";

const CheckCoverageComponent = (props: CheckCoverageProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    checkCoverageHandler,
    coverageContent,
    checkAddress,
    coverageLoading,
    coverageAddress,
    signal4G,
    signal5G,
    coverageError,
    navigateCheckCoverage,
    coverageModal,
    checkDisableHandler,
    signalModal,
    openCoverageModal,
    closeModalHandler,
    searchAgainHandler,
    exploreHandler,
  } = props;

  const {
    heading,
    sub_heading,
    modal_heading,
    modal_subheading,
    place_holder,
    signal_note2,
    button_1,
    button_2,
    button_3,
    button_4,
    button_5,
    error,
    coverage_1,
    coverage_2,
    signal_strength1,
    signal_strength2,
    signal_strength3,
    signal_strength4,
    alt_image_text,
    fair_signal,
    good_signal,
    great_signal,
    no_coverage,
    coverage_map,
    signal_note1,
  } = coverageContent || {};
  const isCompleteAddressDisplayFormat = addressDisplayPattern.test(checkAddress)
  const displayAddressValue = isCompleteAddressDisplayFormat?checkAddress.replace(/,(\s*\d{5})$/, '$1'):checkAddress;
  return (
    coverageContent && (
      <>
        <Box
          className={styles.check_coverage_container}
          minHeight={{ xs: "456px", sm: "614px", md: "340px" }}
        >
          <SecondaryInfoCard
            is_right={false}
            image={coverage_map?.data?.attributes?.url}
            btnWidth="200px"
            info={sub_heading}
            title={heading}
            btnTxt={button_1}
            onclick={openCoverageModal}
            alt={alt_image_text ?? coverage_map?.data?.attributes?.alternativeText}
          />
        </Box>
        <SimpleModal
          isShowModal={coverageModal || signalModal}
          onCloseModal={closeModalHandler}
          showClose={true}
          className={styles.coverage_modal}
          sx={{
            width: { xs: "95%", sm: "500px", lg: "550px" },
            maxWidth: "500px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {coverageModal ? (
            <>
              <Typography
                variant="h5"
                sx={{ m: "10px 0 20px" }}
                className={styles.coverage_check_heading}
              >
                {modal_heading}
              </Typography>
              <Typography
                variant="subtitle2"
                className={styles.coverage_check_subheading}
              >
                {modal_subheading}
              </Typography>
              <Typography
                sx={{ width: "25%", my: 2.75 }}
                className={styles.greenline}
              ></Typography>
              <AutoComplete
                value={displayAddressValue}
                onChange={checkAddressHandler}
                onSelect={(e: any) => {
                  handleSelect(e);
                }}
                placeholder={place_holder}
                autoComplete="off"
                name="address"
              />
              {coverageError && (
                <Container
                  style={{ padding: 0 }}
                  className={styles.coverage_error}
                >
                  {error}
                </Container>
              )}
              <ButtonCust
                sx={{ my: 1, width: "100%" }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => checkCoverageHandler(coverageAddress)}
                disabled={checkDisableHandler()}
                loading={coverageLoading}
              >
                {button_1}
              </ButtonCust>
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                sx={{ mb: 0.75 }}
                className={styles.coverage_check_heading}
              >
                {modal_heading}
              </Typography>
              <Typography
                variant="subtitle2"
                className={styles.coverage_check_subheading}
              >
                {modal_subheading}
              </Typography>
              <Typography
                sx={{ width: "25%", my: 2.75 }}
                className={styles.greenline}
              ></Typography>
              {signal4G !== "no service" || signal5G !== "no service" ? (
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "60%",
                  }}
                >
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt="success"
                      className={styles.coverage_image}
                      src={
                        signal4G === "fair"
                          ? fair_signal?.data?.attributes?.url
                          : signal4G === "good"
                          ? good_signal?.data?.attributes?.url
                          : signal4G === "great"
                          ? great_signal?.data?.attributes?.url
                          : no_coverage?.data?.[0]?.attributes?.url
                      }
                    />
                    <Typography variant="h6" className={styles.coverage_text1}>
                      {coverage_1.slice(0, 2)}
                    </Typography>
                  </Container>
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt="success"
                      className={styles.coverage_image}
                      src={
                        signal5G === "fair"
                          ? fair_signal?.data?.attributes?.url
                          : signal5G === "good"
                          ? good_signal?.data?.attributes?.url
                          : signal5G === "great"
                          ? great_signal?.data?.attributes?.url
                          : no_coverage?.data?.[0]?.attributes?.url
                      }
                    />
                    <Typography variant="h6" className={styles.coverage_text1}>
                      {coverage_2.slice(0, 3)}
                    </Typography>
                  </Container>
                </Container>
              ) : (
                <Box
                  component="img"
                  alt="success"
                  className={styles.modal_image}
                  src={no_coverage?.data?.[0]?.attributes?.url}
                />
              )}

              {signal4G !== "no service" || signal5G !== "no service" ? (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    my: 2.5,
                  }}
                  className={styles.coverage_final_text}
                >
                  <Typography variant="subtitle1" component={"p"}>
                    {coverage_1}
                    {signal4G === "fair"
                      ? signal_strength1
                      : signal4G === "good"
                      ? signal_strength2
                      : signal4G === "great"
                      ? signal_strength3
                      : signal_strength4}
                  </Typography>
                  <Typography variant="subtitle1" component={"p"}>
                    {coverage_2}
                    {signal5G === "fair"
                      ? signal_strength1
                      : signal5G === "good"
                      ? signal_strength2
                      : signal5G === "great"
                      ? signal_strength3
                      : signal_strength4}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component={"p"}
                    sx={{ textAlign: "center", my: 1.25 }}
                  >
                    {signal_note1}
                  </Typography>
                </Container>
              ) : (
                <Container
                  sx={{ textAlign: "center", my: 2.5 }}
                  className={styles.coverage_final_text}
                >
                  {signal_note2}
                </Container>
              )}
              {signal4G !== "no service" || signal5G !== "no service" ? (
                <ButtonCust
                  sx={{ my: 0.75, width: "100%" }}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={searchAgainHandler}
                >
                  {button_2}
                </ButtonCust>
              ) : (
                ""
              )}
              <ButtonCust
                sx={{ my: 0.75, width: "100%" }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={exploreHandler}
              >
                {signal4G !== "no service" || signal5G !== "no service"
                  ? button_3
                  : button_4}
              </ButtonCust>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2 }}
                className={styles.detailed_coverage}
                onClick={navigateCheckCoverage}
              >
                {button_5}
              </Typography>
            </>
          )}
        </SimpleModal>
      </>
    )
  );
};

export default CheckCoverageComponent;
