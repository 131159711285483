import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ButtonCust } from "../../../widgets";
import envConfig from "../../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNonInitialEffect } from "../../../../utils/commonFunctions";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import EnvConfig from "../../../../config/env.config.json";
import { activateLine } from "../../../../features/activation/activationSlice";
import { customerGroup } from "../../../../features/account/accountSlice";

const T6_ActivationStep4 = (props: any) => {
  const { currentLine, content } = props;
  const {
    tick_icon,
    pay_success_title,
    pay_success_title1,
    pay_success_desc1,
    pay_success_desc,
    btn_txt,
  } = content;
  const [paySuccess, setPaySuccess] = useState(true);
  const [actBtnLoader, setActBtnLoader] = useState(false);
  const { activateLineStatus, activateLineError } = useAppSelector(
    (state: any) => state?.activation || {}
  );

  const numOfLines: any = localStorage.getItem("numOfLines") || null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const simID = localStorage.getItem("simId");
  const { id, reachPlanId, imei, make, model, isMNP, mnpInfo, simId, esim } =
    currentLine || {};
  const { operator, oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  const activateLineHandler = () => {
    const skipHoldVal =
      EnvConfig.IS_ACT_SKIP_HOLD_VALUE && isMNP ? true : false;
    const newNumPy: any = {
      esim: esim,
      customerId: id,
      reachPlanId: reachPlanId,
      iccId: simID || simId,
      imei: imei,
      make: make,
      model: model,
      skipHold: true,
    };
    const mnpPy = {
      ...newNumPy,
      skipHold: skipHoldVal,
      isPort: true,
      numberToPort: oldNumber,
      oldCarrier: operator,
      oldCarrierAccountNumber: acctNum,
      password: oldPassword,
      oldZip: oldZip,
      portUpdate: false,
    };
    dispatch(activateLine({ payload: isMNP ? mnpPy : newNumPy }));
  };

  const continueHandler = () => {
    localStorage.removeItem("simId");
    setActBtnLoader(true);
    activateLineHandler();
  };

  useNonInitialEffect(() => {
    setPaySuccess(false);
    setActBtnLoader(false);
    if (customerDetails && customerDetails?.groupId) {
      dispatch(
        customerGroup({
          groupId: customerDetails.groupId,
        })
      );
    }
    navigate(RoutingURLs.postActivationHome);
  }, [activateLineStatus, activateLineError]);

  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          m: "90px 0px 130px",
        }}
      >
        {paySuccess && (
          <>
            <Box
              component="img"
              src={tick_icon?.data?.attributes?.url}
              alt={tick_icon?.data?.attributes?.alternativeText}
              height={"30px"}
              width={"30px"}
            />
            <Typography
              sx={{
                textAlign: "center",
                color: "var(--checkout_secondary_light_green)",
                fontFamily: "var(--font_family_Bold)",
                fontSize: "32px",
              }}
              my="10px"
            >
              {/* {numOfLines > 1 ? pay_success_title1 : pay_success_title} */}
              {pay_success_title1}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font_family_Regular)",
                color: "var(--subtext-color)",
                fontSize: "16px",
                textAlign: "center",
                maxWidth: { xs: "80%", md: "37%" },
              }}
            >
              {/* {numOfLines > 1 ? pay_success_desc1 : pay_success_desc} */}
              {pay_success_desc}
            </Typography>
            <ButtonCust
              variantType={envConfig.PRIMARY_BUTTON_TYPE}
              sx={{
                m: "20px 0 30px 0",
                width: { xs: "250px", md: "400px" },
              }}
              onClick={() => continueHandler()}
              // onClick={props?.activateHandler}
              loading={actBtnLoader}
              // loading={props?.activateBtnLd}
            >
              {btn_txt}
            </ButtonCust>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default T6_ActivationStep4;
