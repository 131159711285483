import { useEffect, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  RadioGroup,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S7_ActivationStep1.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IMEIFailureModal } from "./IMEIFailureModal";
import {
  TextFieldCust,
  ButtonCust,
  ManageCard,
  CustToolTip,
  RadioCust,
} from "../../../widgets";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import OneTimeChargeComp from "./OneTimeChargeComp";
import { ChangeLinePreferenceDialog } from "../../../ChangeLinePreferenceDialog/ChangeLinePreferenceDialog";
import { SimTypes, planModalType, simVerificationState } from "../../../../features/checkout/Types";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { AddShippingAddressDialogComp } from "../../Account/AddLinesDialog/AddShippingAddressDialog/AddShippingAddressDialogComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddLine_ChangePlan from "../../../common/AddLine_ChangePlan/AddLine_ChangePlan";
import PESimSwap from "./PESimSwap";
import { renderTextStyles } from "../../../../utils";
import _ from "lodash";
import CreateIcon from "@mui/icons-material/Create";
import MultiSimInHand from "../../../checkout/MultiSimInHandCard/MultiSimInHand";
import { setMultiSIMVerificationState, updateMultiSIMVerificationState, validateSIMNumber } from "../../../../features/checkout/checkoutSlice";

const T7ActivationStep1 = (props: any) => {
  const {
    handleStep,
    currentLine,
    numChangeHandler,
    content,
    simPrefenceContent,
    GAAndFBEventsHandler,
    kitBasePrice,
    sendSimOption,
    deviceComp,
    setDeviceComp,
    currentModal,
    setCurrentModal,
    formik,
    btnLoader,
    setBtnLoader,
    modalStatus,
    setModalStatus,
    updatingCustomer,
    setDeviceSimInHand,
    deviceSimInHand,
    setIccidComp,
    iccidComp,
    simInHandContentState,
    esimAvl,
    imeiDataSet,
  } = props;
  const dispatch = useAppDispatch();
  const provideEdit: boolean = currentLine?.esim ? true : false;
  const onTimeActivation = localStorage.getItem("oneTimeactivation");
  const getLatestAdd: any = JSON?.parse(
    localStorage.getItem("latestAddress") || "null"
  );
  const [addressData, setAddressData] = useState<any>(() => getLatestAdd);
  const [simType, setSimType] = useState<SimTypes>(
    provideEdit ? SimTypes?.eSim : SimTypes?.sim
  );
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [btnDisabale, setBtnDisable] = useState(true);
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(planModalType.changePlan);
  const [pSimToESimJourney, setPSimToESimJourney] = useState<boolean>(false);
  const { imeiData } = useAppSelector((state: any) => state?.activation);
  const [isShipmentCharges, setIsShipmentPaymentDetails] = useState(false);
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const [IMEIOpenInfo, setIMEIOpenInfo] = useState(false);
  const {
    imei_view,
    Iemi_con,
    imei_form,
    sp1_title,
    step_txt,
    msgs,
    verfiy_details_txt,
    sih_title,
    sih_title2,
    sih_btn,
    sih_icc_icon,
  } = content || {};

  const { multiSimVerificationState } = useAppSelector(
    (state: any) => state.checkout
  );

  const changeModal = (val?: string) => {
    setCurrentModal(val ?? "");
  };
  const handleSimTypeChange = (e: any) => {
    setSimType(e.target.value);
  };
  const closeOneTimeCharge = () => {
    if (currentLine?.esim && !esimAvl) {
      changeModal("");
      setDeviceComp(false);
    } else {
      changeModal();
      setDeviceComp(false);
      setAddressData(null);
      localStorage.removeItem("latestAddress");
      onTimeActivation && localStorage.removeItem("oneTimeactivation");
      localStorage.removeItem("oneTimeChargeDeliveryType");
    }
  };
  const showCardChangeModalHandler = (val?: any) => {
    setShowCardChangeModal(false);
    if (val?.currentModal) {
      changeModal(val?.currentModal);
    } else if (val === false) {
      setIsShipmentPaymentDetails(true);
      changeModal("oneTimeChrg");
    }
  };
  const ProceedPayment = () => { };
  const changeCard = (obj?: any) => {
    if (obj) {
      localStorage.setItem("latestAddress", JSON.stringify(obj));
      setAddressData(obj);
    }
    setShowCardChangeModal(true);
    changeModal("cardsModal");
  };
  const changeAddress = (obj?: any) => {
    obj && setAddressData(obj);
    changeModal("addressDetails");
  };
  const handleNavigateNext = (type: any) => {
    changeModal(type);
    setIsShipmentPaymentDetails(true);
  };
  const SaveAddress = (e: any) => {
    changeModal("oneTimeChrg");
    e?.billingAddress && setAddressData(e.billingAddress);
  };
  const chooseModalResult = () => {
    if (currentLine.esim && !imeiDataSet?.esimAvailable) {
      updatingCustomer({
        imei: imeiDataSet?.imei,
        make: imeiDataSet?.make,
        model: imeiDataSet?.model,
        os: imeiDataSet?.os,
        osVer: imeiDataSet?.osVer,
      });
    }
    const currentLineSimType = currentLine.esim ? SimTypes.eSim : SimTypes.sim;
    if (currentLineSimType === simType && !currentLine?.isSimInHand) {
      setDeviceComp(true);
      // handleStep(true);
      changeModal();
      return;
    }
    if (simType === SimTypes.simInHAND) {
      updatingCustomer({
        simId: formik.values.iccId,
        isSimInHand: true,
        esim: false,
      });
      setIccidComp(true);
      setDeviceComp(true);
      changeModal("");
      return;
    }
    if (simType === SimTypes.sim) {
      changeModal("oneTimeChrg");
      return;
    }
    if (simType === SimTypes.eSim) {
      setPSimToESimJourney(true);
      changeModal();
    }
  };

  const handleChangePlan = (index: number, newPlan: any) => {
    updatingCustomer({
      reachPlanId: newPlan?.name,
      reachPlanDisplayName: newPlan?.displayName,
    });
  };
  const handleChangeEditPlan = () => {
    setOpenModal(!openModal);
  };
  useEffect(() => {
    const { isValid, make, model } = imeiData || {};
    if (isValid && make && model && currentModal?.length > 0) {
      setDeviceComp(true);
    }
  }, [imeiData, currentModal]);

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          openIMEIPopper(false);
        }}
      >
        <Box
          component="a"
          //   href={tooltip_link}
          target="_blank"
          sx={{
            color: "var(--white)",
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: "14px",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };
  const openIMEIPopper = (data: any) => {
    setIMEIOpenInfo(data);
  };
  const onBtnIccidBtnClick = () => {
    if (multiSimVerificationState?.[0]?.error) {
      updatingCustomer({
        simId: "",
        isSimInHand: false,
      });
      chooseModalResult();
      setIccidComp(true);
    } else {
      updatingCustomer({
        simId: formik.values.iccId,
        isSimInHand: true,
      });
      setIccidComp(true);
      changeModal("");
    }
  }
  const handleClose = (e: any) => {
    const index = 0;
    setBtnDisable(true);
    dispatch(
      updateMultiSIMVerificationState({
        type: "error",
        value: false,
        index,
      })
    );
    dispatch(
      updateMultiSIMVerificationState({
        type: "isSuccess",
        value: false,
        index,
      })
    );
  }

  const onSubmitSimHandler = (e: any) => {
    const index = 0;
    dispatch(
      updateMultiSIMVerificationState({
        type: "pending",
        value: true,
        index,
      })
    );
    dispatch(
      validateSIMNumber({
        iccId: formik.values.iccId,
        mvne: Config.OPERATOR,
      })
    ).then((response: any) => {
      setBtnDisable(false);
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(
          updateMultiSIMVerificationState({
            type: "pending",
            value: false,
            index,
          })
        );
        dispatch(
          updateMultiSIMVerificationState({
            type: "isSuccess",
            value: response.payload.data.data.isValid ? true : false,
            index,
          })
        );
        dispatch(
          updateMultiSIMVerificationState({
            type: "error",
            value: response.payload.data.data.isValid ? false : true,
            index,
          })
        );
      } else {
        dispatch(
          updateMultiSIMVerificationState({
            type: "isSuccess",
            value: response.payload.data.data.isValid ? false : true,
            index,
          })
        );
        dispatch(
          updateMultiSIMVerificationState({
            type: "error",
            value: response.payload.data.data.isValid ? true : false,
            index,
          })
        );
        dispatch(
          updateMultiSIMVerificationState({
            type: "pending",
            value: false,
            index,
          })
        );
      }
    });
  };
  const editEmeiNumber = () => {
    return (
      <>
        <Box
          className={styles.phone_details_container}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            pt: "70px",
          }}
        >
          <Paper
            sx={{
              // border: "1px solid rgba(0,0,0,.2)",
              width: { xs: "94%", sm: "724px" },
              // height: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // mt: { xs: "60px" },
              boxShadow: { xs: "unset !important" },
              marginBottom: "59px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: { xs: "27px 0px 18px" },
              }}
            >
              {/* <Box
                component="img"
                alt={"circular_loader"}
                src={content?.imei_comp_headIcon?.data?.attributes?.url}
                sx={{ width: { xs: "33px", paddingRight: "16px" } }}
              /> */}
              <Typography
                className={styles.title}
                sx={{
                  fontSize: { xs: "18px", sm: "24px" },
                  lineHeight: { xs: "30px", sm: "26px" },
                }}
              >
                {sp1_title}
              </Typography>
            </Box>
            <hr style={{ border: "1px solid #F8F8F8", width: "100%" }}></hr>
            <Box className={styles.steps_container}>
              <Typography
                sx={{
                  mb: { xs: "26px" },
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_2)",
                  color: "var(--text_color)",
                }}
                className={styles.steps_title}
              >
                {currentLine?.esim ? content?.step_txt1 : content?.step_txt_next1}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "16px" },
                  lineHeight: { xs: "25px", sm: "26px" },
                  fontWeight: {
                    xs: "var(--font_weight_0)",
                    sm: "var(--font_weight_2)",
                  },
                  fontFamily: {
                    xs: "var(--font_family_Regular)",
                    sm: "var(--font_family_Semibold)",
                  },
                  color: "var(--text_color)",
                  m: { xs: " 0px 11% 30px", sm: "0px 34px 26px" },
                }}
              >
                {BrandNameUpdate(
                  currentLine?.isPrimary ? imei_form?.desc1 : imei_form?.desc_sec2
                )}
              </Typography>
            </Box>
            <Box
              width={{
                xs: "80%",
                sm: "80%",
                md: "70%",
              }}
              sx={{ px: { md: "12px" } }}
            >
              <TextFieldCust
                value={formik.values.imei}
                name="imei"
                onChange={(e: any) => numChangeHandler(e, formik)}
                onBlur={formik.handleBlur}
                type={"text"}
                label={imei_form?.txt_ph}
                maxlength={15}
                error={formik.errors?.imei ? true : false}
                helperText={formik.errors?.imei}
                onKeyUp={(e: any) => {
                  if (e.key === "Enter") {
                    formik.handleSubmit();
                  }
                }}
              />
              <Box display="flex" justifyContent="end">
                <Typography className={styles.activation_limit_div}>
                  {formik.values.imei.length}/15
                </Typography>
              </Box>
            </Box>
            {/* <Box
              component="p"
              sx={{ display: imei_form?.note ? "block" : "none" }}
            >
              {imei_form?.note}
            </Box> */}
            <Box
              sx={{
                marginBottom: msgs?.what_is_imei ? "20px" : "44px",
                mt: { xs: "0px", sm: "20px" },
              }}
            >
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                sx={
                  {
                    // width: { xs: "250px", sm: "250px", md: "250px" },
                    // height: { md: "50px !important" },
                    padding: '0px 35px'
                  }
                }
                disabled={
                  formik.values?.imei.length === 0 ? true : !formik?.isValid
                }
                onClick={() => {
                  formik.handleSubmit();
                }}
                loading={btnLoader}
              >
                {content?.check_btn1}
              </ButtonCust>
            </Box>
            {msgs?.what_is_imei ? (
              <>
                <CustToolTip
                  title={PoperOver(
                    <Box sx={{ padding: "7px 19px 0px 20px" }}>
                      {content?.imei_modal_mod?.length > 0 &&
                        content?.imei_modal_mod?.map((data: any, index: string) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                pb: "15px",
                                "& span": { letterSpacing: "0px" },
                              }}
                            >
                              <Typography
                                component={"span"}
                                sx={renderTextStyles(
                                  1,
                                  "14px",
                                  "white",
                                  "28px",
                                  "var(--font_weight_4)",
                                  0
                                )}
                              >
                                {data?.title}
                              </Typography>
                              &nbsp;
                              <Typography
                                component={"span"}
                                sx={renderTextStyles(
                                  4,
                                  "14px",
                                  "white",
                                  "22px",
                                  "var(--font_weight_0)",
                                  0
                                )}
                              >
                                {data?.desc}
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                  open={IMEIOpenInfo ? true : false}
                  disableFocusListener
                  disableHoverListener
                  arrow
                  placement={"top"}
                  className={styles?.tooltip_style}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                >
                  <Typography
                    sx={_.merge(
                      renderTextStyles(
                        3,
                        "16px",
                        "text_color",
                        "28px",
                        "var(--font_weight_1)",
                        0
                      ),
                      {
                        cursor: "pointer",
                        mb: "27px",
                      }
                    )}
                    onMouseEnter={() => {
                      openIMEIPopper(true);
                    }}
                    onClick={() => {
                      openIMEIPopper(true);
                    }}
                    onMouseLeave={() => {
                      openIMEIPopper(false);
                    }}
                  >
                    {msgs?.what_is_imei}
                  </Typography>
                </CustToolTip>
              </>
            ) : null}
          </Paper>
        </Box>
        {/* <Box
          className={styles.activation_bottom}
          sx={{ background: "var(--lightgrey_3)" }}
        >
          <Box>
            <Typography
              className={styles.activation_bottom_text1}
              sx={{
                mb: "5px",
                fontFamily: "var(--font_family_Medium)",
                textAlign: "center",
              }}
            >
              {Iemi_con?.title}
            </Typography>
            <Typography
              className={styles.activation_bottom_text1}
              sx={{
                fontFamily: "var(--font_family_Medium)",
                textAlign: "center",
              }}
            >
              {Iemi_con?.desc}
            </Typography>
          </Box>

          <Box
            sx={{
              margin: {
                xs: "0px 0px 50px",
                sm: "0px 0px 155px",
              },
            }}
            width={{ xs: "90%", sm: "fit-content" }}
          >
            {Iemi_con?.iemiContent.map((data: any) => {
              return (
                <Box
                  className={styles.activation_version_container}
                  mt={"14px"}
                  key={data.desc1}
                >
                  <img
                    width={23}
                    height={23}
                    className={styles.os_img}
                    src={data.img?.data?.attributes?.url}
                    alt={data.img?.data?.attributes?.alternativeText}
                  />
                  <Box>
                    <Typography className={styles.os_text_1}>
                      {data.desc1}
                    </Typography>
                    <Typography
                      className={styles.os_text_2}
                      sx={{ color: "var(--lite_black_3) !important" }}
                    >
                      {data.desc2}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box> */}
      </>
    );
  };

  const editIccidNumber = () => {
    return (
      <>
        <Box
          className={styles.phone_details_container}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            pt: "70px",
          }}
        >
          <Paper
            sx={{
              width: { xs: "94%", sm: "724px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              boxShadow: { xs: "unset !important" },
              marginBottom: "59px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: "27px 5px 18px" },
              }}
            >
              {/* <Box
                component="img"
                alt={"circular_loader"}
                src={sih_icc_icon?.data?.attributes?.url ?? 'https://d3726y2k8xqjif.cloudfront.net/reachmobile-att-dev/numberpref_a9670e1905.svg'}
                sx={{ width: { xs: "33px", paddingRight: "16px" } }}
              /> */}
              <Typography
                className={styles.title}
                sx={{
                  fontSize: { xs: "18px", sm: "24px" },
                  lineHeight: { xs: "30px", sm: "26px" },
                }}
              >
                {sih_title}
              </Typography>
            </Box>
            <hr style={{ border: "1px solid #F8F8F8", width: "100%" }}></hr>
            <Box className={styles.steps_container}>
              <Typography
                sx={{
                  mb: { xs: "26px" },
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_2)",
                  color: "var(--text_color)",
                }}
                className={styles.steps_title}
              >
                {currentLine?.esim ? content?.step_txt : content?.step_txt_next}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "16px" },
                  lineHeight: { xs: "25px", sm: "26px" },
                  fontWeight: {
                    xs: "var(--font_weight_0)",
                    sm: "var(--font_weight_2)",
                  },
                  fontFamily: {
                    xs: "var(--font_family_Regular)",
                    sm: "var(--font_family_Semibold)",
                  },
                  color: "var(--text_color)",
                  m: { xs: " 0px 11% 30px", sm: "0px 34px 26px" },
                }}
              >
                {BrandNameUpdate(sih_title2)}
              </Typography>
            </Box>
            <Box
              width={{
                xs: "95%",
                sm: "95%",
                md: "70%",
              }}
              sx={{ px: { md: "12px" } }}
            >
              <MultiSimInHand
                simId={formik.values.iccId}
                onChangeSimId={(e: any) => {
                  setBtnDisable(true);
                  numChangeHandler(e, formik)
                }}
                onSubmitSimHandler={onSubmitSimHandler}
                simInHandContentState={simInHandContentState}
                handleClose={handleClose}
                removeCloseIcon={true}
                error={multiSimVerificationState?.[0]?.error}
                success={multiSimVerificationState?.[0]?.isSuccess}
                pending={multiSimVerificationState?.[0]?.pending} />
            </Box>
            {multiSimVerificationState?.[0]?.error && formik.values.iccId?.length === Number(20) &&
              <>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  mt: '15px',
                }}>
                  <Box sx={{
                    fontSize: '19px',
                    fontWeight: 700,
                    lineHeight: '26px',
                    textAlign: 'center',
                    color: "var(--text_color)",
                  }}>
                    {content?.sih_or_txt}
                  </Box>
                  <Box sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '30px',
                    textAlign: 'center',
                    mt: '10px',
                    color: "var(--text_color)",
                  }}>{content?.sih_error_title}</Box>
                </Box>
                <Box
                  sx={{
                    width: "60%",
                  }}
                >
                  <RadioGroup
                    value={simType === SimTypes.simInHAND ? SimTypes.sim : simType}
                    onChange={(e: any) => handleSimTypeChange(e)}
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: '20px',
                    }}
                    row
                  >
                    <RadioCust value={SimTypes.sim} label={content?.sih_sim_lbl} />
                    <Box sx={{ display: "flex" }}>
                      <RadioCust
                        value={SimTypes.eSim}
                        label={content?.sih_esim_lbl}
                        disabled={!imeiData?.esimAvailable}
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              </>
            }
            <Box
              sx={{
                marginBottom: msgs?.what_is_imei ? "20px" : "44px",
                mt: { xs: "0px", sm: "20px" },
              }}
            >
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                sx={{
                  width: { xs: "250px", sm: "250px", md: "250px" },
                  height: { md: "50px !important" },
                }}
                disabled={
                  btnDisabale
                }
                onClick={() => {
                  onBtnIccidBtnClick()
                }}
                loading={btnLoader}
              >
                {sih_btn}
              </ButtonCust>
            </Box>
          </Paper>
        </Box>
      </>
    );
  };

  const handleBackButton = () => {
    if (pSimToESimJourney) {
      setPSimToESimJourney(false);
    } else {
      if (currentModal === "oneTimeChrg") {
        if (isShipmentCharges) {
          setIsShipmentPaymentDetails(!isShipmentCharges);
          changeModal("oneTimeChrg");
        } else {
          changeModal(" ");
        }
      } else if (currentModal === "addressDetails") {
        changeModal("oneTimeChrg");
      } else {
        if (deviceComp && iccidComp) {
          changeModal("");
          handleStep(false);
        } else {
          if (currentLine?.imei && iccidComp) {
            setDeviceComp(true);
          } else if (!iccidComp) {
            setIccidComp(true);
            changeModal("");
          } else {
            handleStep(false);
          }
        }
      }
    }
  };
  return (
    <>
      <KeyboardBackspaceIcon
        onClick={handleBackButton}
        fontSize="large"
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          top: "91.5px",
          left: "20px",
          color: "var(--internal_back_arrow_color, var(--white))",
        }}
      />
      <Box
        sx={{
          cursor: "pointer",
          width: "18px",
          height: "18px",
          zIndex: "100",
          left: "0",
          position: "absolute",
          ml: { md: "30px", sm: "25px", xs: "25px" },
          mt: { md: "60px" },
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "18px",
            height: "18px",
            position: "absolute",
            left: "3rem",
            alignItems: "center",
          }}
          onClick={() => handleBackButton()}
        >
          <ArrowBackIosIcon sx={{ width: "18px", fontSize: "1.125rem" }} />
          <Box
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_1)",
              fontSize: "18px",
              lineHeight: "30px",
            }}
          >
            {content?.back_txt}
            Back
          </Box>
        </Box>
      </Box>
      {currentModal !== "oneTimeChrg" && currentModal !== "addressDetails" && (
        <>
          {pSimToESimJourney ? (
            <PESimSwap
              content={content}
              setPSimToESimJourney={setPSimToESimJourney}
              currentLine={currentLine}
            />
          ) : (
            <Box className={styles.step1_main}>
              {!deviceComp ? (
                <>{editEmeiNumber()}</>
              ) : !iccidComp && !currentLine?.esim && currentLine?.isSimInHand && currentLine?.simId && Config.IS_MULTI_SIM_IN_HAND_ENABLED ? (
                <>{editIccidNumber()}</>
              ) : (
                <>
                  <Box className={styles.confirm_your_details_container}>
                    <Paper
                      sx={{
                        width: { xs: "90%", sm: "753px" },
                        height: { xs: "100%", md: "100%" },
                        mt: { xs: "40px", sm: "60px" },
                        boxShadow: { xs: "unset !important" },
                      }}
                    >
                      <Box
                        sx={{
                          p: { xs: "15px 0px", sm: "31px 0px 26px" },
                        }}
                        className={styles.header_container}
                      >
                        {/* <Box
                          component="img"
                          alt={"circular_loader"}
                          src={content?.mobile_icon_main?.data?.attributes?.url}
                          sx={{ width: { xs: "20px", paddingRight: "10px" } }}
                        /> */}
                        <Typography
                          sx={{
                            fontSize: { xs: "18px", sm: "24px" },
                            lineHeight: { xs: "30px", sm: "36px" },
                          }}
                          className={styles.title}
                        >
                          {verfiy_details_txt}
                        </Typography>
                      </Box>
                      <hr
                        style={{
                          border: "1px solid var(--lightgrey_6)",
                          margin: "0px",
                        }}
                      ></hr>
                      <Box
                        className={styles.steps_container}
                        sx={{ textAlign: "center" }}
                      >
                        <Typography
                          sx={{
                            mt: { xs: "30px" },
                            mb: { xs: "5px" },
                            fontFamily: "var(--font_family_Semibold)",
                            fontWeight: "var(--font_weight_2)",
                            color: "var(--text_color)",
                          }}
                          className={styles.steps_title}
                        >
                          {currentLine?.esim
                            ? content?.step_txt1
                            : content?.step_txt_next1}
                        </Typography>
                      </Box>
                      <Box
                        className={`${Config.IS_MULTI_SIM_IN_HAND_ENABLED ? styles.multi_sim_confim_details_container : styles.confim_details_container}`}
                        sx={{
                          flexDirection: { xs: "column", md: "row" },
                          mx: { xs: "40px", sm: "68px" },
                          ml: { sm: "86px" },
                          justifyContent: "space-between",
                          "& > *": {
                            width: { xs: "100%", sm: "42%" },
                            margin: { xs: "10px", sm: "15px 20px 15px" },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: "flex" },
                            marginTop: "20px",
                            alignItems: "center",
                          }}
                        >
                          {/* <Box
                            component="img"
                            alt={"circular_loader"}
                            src={
                              content?.mobile_icon_imei?.data?.attributes?.url
                            }
                            sx={{ width: { xs: "20px" }, mr: "20px" }}
                          /> */}
                          {/* <SvgIcon
                            sx={{
                              color: "var(--primary_color)",
                              fontSize: "30px",
                              margin: "10px 5px",
                            }}
                          > */}

                          {/* </SvgIcon> */}
                          <Box sx={{ m: "5px 15px 0px 0px" }}>
                            <svg
                              width="22"
                              height="40"
                              viewBox="0 0 22 40"
                              fill="var(--primary_color)"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2229_11244)">
                                <path
                                  d="M19.3821 -0.0703125H2.67641C1.19852 -0.0703125 -0.00390625 1.13211 -0.00390625 2.61V37.2479C-0.00390625 38.7258 1.19852 39.9282 2.67641 39.9282H19.3821C20.86 39.9282 22.0624 38.7258 22.0624 37.2479V2.61C22.0624 1.13219 20.86 -0.0703125 19.3821 -0.0703125ZM2.67641 1.1668H19.3821C20.1778 1.1668 20.8254 1.8143 20.8254 2.61008V32.877H1.23313V2.61C1.23313 1.8143 1.88071 1.1668 2.67641 1.1668ZM9.88901 36.0934C9.88865 35.9433 9.91795 35.7946 9.97522 35.6558C10.0325 35.5171 10.1166 35.391 10.2228 35.2849C10.3289 35.1787 10.455 35.0946 10.5937 35.0373C10.7325 34.98 10.8812 34.9507 11.0313 34.9511C11.6622 34.9511 12.1694 35.4624 12.1694 36.0934C12.1694 36.7242 11.6622 37.2355 11.0313 37.2355C10.8812 37.2359 10.7325 37.2066 10.5937 37.1493C10.455 37.0921 10.3289 37.0079 10.2228 36.9018C10.1167 36.7957 10.0325 36.6696 9.97525 36.5309C9.91797 36.3921 9.88866 36.2435 9.88901 36.0934Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  d="M16.8665 12.8567L13.6433 9.63349C13.4427 9.43204 13.2041 9.27234 12.9414 9.16361C12.6787 9.05488 12.3971 8.99927 12.1128 9.00001H7.66351C7.08983 9.00071 6.53987 9.22895 6.13427 9.63465C5.72867 10.0403 5.50056 10.5904 5.5 11.164V22.6895C5.50056 23.2631 5.72867 23.8132 6.13427 24.2189C6.53987 24.6246 7.08983 24.8528 7.66351 24.8535H15.336C15.9097 24.8528 16.4597 24.6246 16.8654 24.2189C17.2711 23.8132 17.4993 23.2632 17.5 22.6895V14.3867C17.4984 13.8132 17.2708 13.2635 16.8665 12.8567ZM16.5429 22.6889C16.5426 23.0089 16.4153 23.3158 16.1891 23.542C15.9628 23.7683 15.656 23.8956 15.336 23.8958H7.66351C7.34354 23.8954 7.03681 23.7681 6.81056 23.5419C6.58431 23.3156 6.45702 23.0089 6.4566 22.6889V11.164C6.45702 10.844 6.58429 10.5372 6.81053 10.3109C7.03676 10.0846 7.3435 9.95717 7.66351 9.95661H12.1122C12.2708 9.95597 12.4279 9.98689 12.5745 10.0476C12.721 10.1082 12.854 10.1975 12.9657 10.31L16.1889 13.5332C16.4137 13.758 16.5423 14.0689 16.5423 14.3862L16.5429 22.6889Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  d="M13.6237 15.5938H9.37538C9.05223 15.594 8.74238 15.7225 8.51382 15.9509C8.28527 16.1794 8.15667 16.4892 8.15625 16.8124V20.9783C8.15667 21.3015 8.28527 21.6113 8.51382 21.8397C8.74238 22.0682 9.05223 22.1967 9.37538 22.1969H13.6237C13.9468 22.1965 14.2565 22.068 14.485 21.8396C14.7134 21.6111 14.8419 21.3014 14.8423 20.9783V16.8124C14.8419 16.4893 14.7134 16.1796 14.485 15.9511C14.2565 15.7227 13.9468 15.5942 13.6237 15.5938ZM13.8852 16.8124V18.4168H11.9779V16.5509H13.6237C13.7683 16.5509 13.8852 16.6678 13.8852 16.8124ZM9.11338 20.9783V16.8124C9.11338 16.6683 9.23083 16.5509 9.37538 16.5509H11.0213V21.2398H9.37538C9.30599 21.2398 9.23943 21.2123 9.19031 21.1633C9.14119 21.1142 9.11352 21.0477 9.11338 20.9783ZM13.6237 21.2398H11.9779V19.3734H13.8852V20.9783C13.8852 21.1224 13.7683 21.2398 13.6237 21.2398Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  d="M8.18883 4.46555H13.871C14.2128 4.46555 14.4895 4.18867 14.4895 3.84703C14.4895 3.50539 14.2128 3.22852 13.871 3.22852H8.18883C7.84719 3.22852 7.57031 3.50539 7.57031 3.84703C7.57031 4.18867 7.84711 4.46555 8.18883 4.46555Z"
                                  fill="var(--primary_color)"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2229_11244">
                                  <rect
                                    width="22.0001"
                                    height="40"
                                    fill="white"
                                    transform="translate(0 0.0078125)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="baseline"
                          >
                            <Typography className={styles.title}>
                              {imei_view?.deviceID}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography className={styles.desc}>
                                {currentLine?.imei}
                              </Typography>
                              {/* <Box
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeviceComp(false);
                                  GAAndFBEventsHandler(
                                    gaCategory.activation,
                                    imei_view?.edd_txt
                                  );
                                }}
                                component="img"
                                alt={
                                  content?.edit_pn_icon?.data?.attributes?.alt
                                }
                                src={
                                  content?.edit_pn_icon?.data?.attributes?.url
                                }
                                className={styles.edit_icon}
                              />*/}

                              <CreateIcon
                                sx={{
                                  color: "var(--primary_color)",
                                  cursor: "pointer",
                                }}
                                className={styles.edit_icon}
                                onClick={() => {
                                  setDeviceComp(false);
                                  if (currentLine?.esim || (!currentLine?.esim && !currentLine?.isSimInHand)) {
                                    setDeviceSimInHand(false);
                                  } else {
                                    setDeviceSimInHand(true);
                                  }
                                  GAAndFBEventsHandler(
                                    gaCategory.activation,
                                    imei_view?.edd_txt
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: { xs: "flex" }, alignItems: "center" }}
                        >
                          {/* <Box
                            component="img"
                            alt={"circular_loader"}
                            src={
                              content?.mobile_icon_device?.data?.attributes?.url
                            }
                            sx={{ width: { xs: "20px" }, mr: "20px" }}
                          /> */}
                          <Box sx={{ m: "16px 15px 0px 0px" }}>
                            <svg
                              width="22"
                              height="46"
                              viewBox="0 0 22 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2229_11255)">
                                <path
                                  d="M19.3469 0.105469H2.64126C1.16336 0.105469 -0.0390625 1.30789 -0.0390625 2.78578V37.4237C-0.0390625 38.9016 1.16336 40.104 2.64126 40.104H19.3469C20.8248 40.104 22.0272 38.9016 22.0272 37.4237V2.78578C22.0272 1.30797 20.8248 0.105469 19.3469 0.105469ZM2.64126 1.34258H19.3469C20.1426 1.34258 20.7902 1.99008 20.7902 2.78586V33.0527H1.19797V2.78578C1.19797 1.99008 1.84555 1.34258 2.64126 1.34258ZM9.85385 36.2691C9.85349 36.119 9.88279 35.9703 9.94007 35.8316C9.99734 35.6928 10.0815 35.5668 10.1876 35.4606C10.2938 35.3545 10.4198 35.2704 10.5586 35.2131C10.6973 35.1558 10.846 35.1265 10.9961 35.1269C11.6271 35.1269 12.1342 35.6382 12.1342 36.2691C12.1342 36.9 11.6271 37.4113 10.9961 37.4113C10.846 37.4117 10.6973 37.3824 10.5586 37.3251C10.4198 37.2678 10.2938 37.1837 10.1876 37.0776C10.0815 36.9715 9.99737 36.8454 9.94009 36.7067C9.88281 36.5679 9.8535 36.4192 9.85385 36.2691Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  d="M6.90498 17.3068C6.79447 17.3068 6.68848 17.2629 6.61033 17.1848C6.53218 17.1066 6.48828 17.0006 6.48828 16.8901V13.5573C6.48828 13.4468 6.53218 13.3408 6.61033 13.2627C6.68848 13.1845 6.79447 13.1406 6.90498 13.1406H10.2386C10.3491 13.1406 10.4551 13.1845 10.5332 13.2627C10.6114 13.3408 10.6553 13.4468 10.6553 13.5573V16.8901C10.6553 17.0006 10.6114 17.1066 10.5332 17.1848C10.4551 17.2629 10.3491 17.3068 10.2386 17.3068H6.90498ZM12.7388 17.3068C12.6283 17.3068 12.5223 17.2629 12.4441 17.1848C12.366 17.1066 12.3221 17.0006 12.3221 16.8901V13.5573C12.3221 13.4468 12.366 13.3408 12.4441 13.2627C12.5223 13.1845 12.6283 13.1406 12.7388 13.1406H16.0716C16.1821 13.1406 16.2881 13.1845 16.3662 13.2627C16.4444 13.3408 16.4883 13.4468 16.4883 13.5573V16.8901C16.4883 17.0006 16.4444 17.1066 16.3662 17.1848C16.2881 17.2629 16.1821 17.3068 16.0716 17.3068H12.7388ZM6.90498 23.1406C6.79447 23.1406 6.68848 23.0967 6.61033 23.0186C6.53218 22.9404 6.48828 22.8344 6.48828 22.7239V19.3903C6.48828 19.2798 6.53218 19.1738 6.61033 19.0957C6.68848 19.0175 6.79447 18.9736 6.90498 18.9736H10.2386C10.3491 18.9736 10.4551 19.0175 10.5332 19.0957C10.6114 19.1738 10.6553 19.2798 10.6553 19.3903V22.7239C10.6553 22.8344 10.6114 22.9404 10.5332 23.0186C10.4551 23.0967 10.3491 23.1406 10.2386 23.1406H6.90498ZM12.7388 23.1406C12.6283 23.1406 12.5223 23.0967 12.4441 23.0186C12.366 22.9404 12.3221 22.8344 12.3221 22.7239V19.3903C12.3221 19.2798 12.366 19.1738 12.4441 19.0957C12.5223 19.0175 12.6283 18.9736 12.7388 18.9736H16.0716C16.1821 18.9736 16.2881 19.0175 16.3662 19.0957C16.4444 19.1738 16.4883 19.2798 16.4883 19.3903V22.7239C16.4883 22.8344 16.4444 22.9404 16.3662 23.0186C16.2881 23.0967 16.1821 23.1406 16.0716 23.1406H12.7388Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  d="M8.13805 4.66672H13.8203C14.162 4.66672 14.4388 4.38984 14.4388 4.0482C14.4388 3.70656 14.162 3.42969 13.8203 3.42969H8.13805C7.79641 3.42969 7.51953 3.70656 7.51953 4.0482C7.51953 4.38984 7.79633 4.66672 8.13805 4.66672Z"
                                  fill="var(--primary_color)"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2229_11255">
                                  <rect
                                    width="22.0001"
                                    height="46"
                                    fill="white"
                                    transform="translate(0 0.0117188)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="baseline"
                          >
                            <Typography className={styles.title}>
                              {imei_view?.device}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography className={styles.desc}>
                                {`${currentLine?.make} ${currentLine?.model}`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ display: { xs: "flex" } }}>
                          {/* <Box
                            component="img"
                            alt={"circular_loader"}
                            src={
                              content?.mobile_icon_simtype?.data?.attributes
                                ?.url
                            }
                            sx={{ width: { xs: "20px" }, mr: "20px" }}
                          /> */}
                            <Box sx={{ m: "5px 15px 0px 0px" }}>
                              <svg
                                width="22"
                                height="40"
                                viewBox="0 0 22 40"
                                fill="var(--primary_color)"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_2229_11244)">
                                  <path
                                    d="M19.3821 -0.0703125H2.67641C1.19852 -0.0703125 -0.00390625 1.13211 -0.00390625 2.61V37.2479C-0.00390625 38.7258 1.19852 39.9282 2.67641 39.9282H19.3821C20.86 39.9282 22.0624 38.7258 22.0624 37.2479V2.61C22.0624 1.13219 20.86 -0.0703125 19.3821 -0.0703125ZM2.67641 1.1668H19.3821C20.1778 1.1668 20.8254 1.8143 20.8254 2.61008V32.877H1.23313V2.61C1.23313 1.8143 1.88071 1.1668 2.67641 1.1668ZM9.88901 36.0934C9.88865 35.9433 9.91795 35.7946 9.97522 35.6558C10.0325 35.5171 10.1166 35.391 10.2228 35.2849C10.3289 35.1787 10.455 35.0946 10.5937 35.0373C10.7325 34.98 10.8812 34.9507 11.0313 34.9511C11.6622 34.9511 12.1694 35.4624 12.1694 36.0934C12.1694 36.7242 11.6622 37.2355 11.0313 37.2355C10.8812 37.2359 10.7325 37.2066 10.5937 37.1493C10.455 37.0921 10.3289 37.0079 10.2228 36.9018C10.1167 36.7957 10.0325 36.6696 9.97525 36.5309C9.91797 36.3921 9.88866 36.2435 9.88901 36.0934Z"
                                    fill="var(--primary_color)"
                                  />
                                  <path
                                    d="M16.8665 12.8567L13.6433 9.63349C13.4427 9.43204 13.2041 9.27234 12.9414 9.16361C12.6787 9.05488 12.3971 8.99927 12.1128 9.00001H7.66351C7.08983 9.00071 6.53987 9.22895 6.13427 9.63465C5.72867 10.0403 5.50056 10.5904 5.5 11.164V22.6895C5.50056 23.2631 5.72867 23.8132 6.13427 24.2189C6.53987 24.6246 7.08983 24.8528 7.66351 24.8535H15.336C15.9097 24.8528 16.4597 24.6246 16.8654 24.2189C17.2711 23.8132 17.4993 23.2632 17.5 22.6895V14.3867C17.4984 13.8132 17.2708 13.2635 16.8665 12.8567ZM16.5429 22.6889C16.5426 23.0089 16.4153 23.3158 16.1891 23.542C15.9628 23.7683 15.656 23.8956 15.336 23.8958H7.66351C7.34354 23.8954 7.03681 23.7681 6.81056 23.5419C6.58431 23.3156 6.45702 23.0089 6.4566 22.6889V11.164C6.45702 10.844 6.58429 10.5372 6.81053 10.3109C7.03676 10.0846 7.3435 9.95717 7.66351 9.95661H12.1122C12.2708 9.95597 12.4279 9.98689 12.5745 10.0476C12.721 10.1082 12.854 10.1975 12.9657 10.31L16.1889 13.5332C16.4137 13.758 16.5423 14.0689 16.5423 14.3862L16.5429 22.6889Z"
                                    fill="var(--primary_color)"
                                  />
                                  <path
                                    d="M13.6237 15.5938H9.37538C9.05223 15.594 8.74238 15.7225 8.51382 15.9509C8.28527 16.1794 8.15667 16.4892 8.15625 16.8124V20.9783C8.15667 21.3015 8.28527 21.6113 8.51382 21.8397C8.74238 22.0682 9.05223 22.1967 9.37538 22.1969H13.6237C13.9468 22.1965 14.2565 22.068 14.485 21.8396C14.7134 21.6111 14.8419 21.3014 14.8423 20.9783V16.8124C14.8419 16.4893 14.7134 16.1796 14.485 15.9511C14.2565 15.7227 13.9468 15.5942 13.6237 15.5938ZM13.8852 16.8124V18.4168H11.9779V16.5509H13.6237C13.7683 16.5509 13.8852 16.6678 13.8852 16.8124ZM9.11338 20.9783V16.8124C9.11338 16.6683 9.23083 16.5509 9.37538 16.5509H11.0213V21.2398H9.37538C9.30599 21.2398 9.23943 21.2123 9.19031 21.1633C9.14119 21.1142 9.11352 21.0477 9.11338 20.9783ZM13.6237 21.2398H11.9779V19.3734H13.8852V20.9783C13.8852 21.1224 13.7683 21.2398 13.6237 21.2398Z"
                                    fill="var(--primary_color)"
                                  />
                                  <path
                                    d="M8.18883 4.46555H13.871C14.2128 4.46555 14.4895 4.18867 14.4895 3.84703C14.4895 3.50539 14.2128 3.22852 13.871 3.22852H8.18883C7.84719 3.22852 7.57031 3.50539 7.57031 3.84703C7.57031 4.18867 7.84711 4.46555 8.18883 4.46555Z"
                                    fill="var(--primary_color)"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2229_11244">
                                    <rect
                                      width="22.0001"
                                      height="40"
                                      fill="white"
                                      transform="translate(0 0.0078125)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="baseline"
                            sx={{ mr: "70px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "var(--font_family_Medium)",
                                color: "var(--text_color)",
                              }}
                              className={styles.title}
                            >
                              {imei_view?.imei_id}
                            </Typography>
                            <Typography className={styles.desc}>
                              {currentLine?.isSimInHand && !currentLine?.esim ? content?.simInHand_txt : currentLine?.esim
                                ? content?.esim_txt
                                : content?.psim_txt}{" "}
                                 {currentLine?.isSimInHand && currentLine?.isPrimary &&
                              <CreateIcon
                                sx={{
                                  color: "var(--primary_color)",
                                  cursor: "pointer",
                                }}
                                className={styles.edit_icon}
                                onClick={() => {
                                  setDeviceComp(false);
                                  setDeviceSimInHand(false);
                                  changeModal("");
                                  GAAndFBEventsHandler(
                                    gaCategory.activation,
                                    imei_view?.edd_txt
                                  );
                                }}
                              />
}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: { xs: "flex" },
                            marginBottom: "10px",
                          }}
                        >
                          {/* <Box
                            component="img"
                            alt={"circular_loader"}
                            src={content?.change_plan?.data?.attributes?.url}
                            sx={{ width: { xs: "20px" }, mr: "20px" }}
                          /> */}
                          {/* <AodIcon
                            sx={{
                              color: "var(--primary_color)",
                              fontSize: "30px",
                              margin: "10px 5px",
                            }}
                          /> */}

                          <Box sx={{ m: "5px 15px 0px 0px" }}>
                            <svg
                              width="22"
                              height="40"
                              viewBox="0 0 22 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2229_11266)">
                                <path
                                  d="M19.3821 -0.0703125H2.67641C1.19852 -0.0703125 -0.00390625 1.13211 -0.00390625 2.61V37.2479C-0.00390625 38.7258 1.19852 39.9282 2.67641 39.9282H19.3821C20.86 39.9282 22.0624 38.7258 22.0624 37.2479V2.61C22.0624 1.13219 20.86 -0.0703125 19.3821 -0.0703125ZM2.67641 1.1668H19.3821C20.1778 1.1668 20.8254 1.8143 20.8254 2.61008V32.877H1.23313V2.61C1.23313 1.8143 1.88071 1.1668 2.67641 1.1668ZM9.88901 36.0934C9.88865 35.9433 9.91795 35.7946 9.97522 35.6558C10.0325 35.5171 10.1166 35.391 10.2228 35.2849C10.3289 35.1787 10.455 35.0946 10.5937 35.0373C10.7325 34.98 10.8812 34.9507 11.0313 34.9511C11.6622 34.9511 12.1694 35.4624 12.1694 36.0934C12.1694 36.7242 11.6622 37.2355 11.0313 37.2355C10.8812 37.2359 10.7325 37.2066 10.5937 37.1493C10.455 37.0921 10.3289 37.0079 10.2228 36.9018C10.1167 36.7957 10.0325 36.6696 9.97525 36.5309C9.91797 36.3921 9.88866 36.2435 9.88901 36.0934Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  d="M8.18883 4.46555H13.871C14.2128 4.46555 14.4895 4.18867 14.4895 3.84703C14.4895 3.50539 14.2128 3.22852 13.871 3.22852H8.18883C7.84719 3.22852 7.57031 3.50539 7.57031 3.84703C7.57031 4.18867 7.84711 4.46555 8.18883 4.46555Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.62796 18.2737C6.82103 18.5025 7.13405 18.5025 7.32712 18.2737L9.44947 15.7584L9.44947 24.4219C9.44947 24.7455 9.67082 25.0079 9.94386 25.0079C10.2169 25.0079 10.4382 24.7455 10.4382 24.4219L10.4382 14.3438C10.4382 14.1068 10.3178 13.8931 10.1331 13.8024C9.94831 13.7117 9.73567 13.7619 9.59427 13.9294L6.62796 17.4451C6.43489 17.6739 6.43489 18.0449 6.62796 18.2737Z"
                                  fill="var(--primary_color)"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M16.2261 20.492C16.033 20.2631 15.72 20.2631 15.5269 20.492L13.4046 23.0073L13.4046 14.3438C13.4046 14.0202 13.1832 13.7578 12.9102 13.7578C12.6371 13.7578 12.4158 14.0202 12.4158 14.3438L12.4158 24.4219C12.4158 24.6589 12.5362 24.8726 12.721 24.9633C12.9057 25.054 13.1184 25.0038 13.2598 24.8362L16.2261 21.3206C16.4191 21.0918 16.4191 20.7208 16.2261 20.492Z"
                                  fill="var(--primary_color)"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2229_11266">
                                  <rect
                                    width="22.0001"
                                    height="40"
                                    fill="white"
                                    transform="translate(0 0.0078125)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="baseline"
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "var(--font_family_Regular)",
                                color: "var(--text_color)",
                              }}
                            >
                              {content?.plan_name}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                className={styles.desc}
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "var(--font_family_Semibold)",
                                  color: "var(--text_color)",
                                }}
                              >
                                {currentLine?.reachPlanDisplayName || "-"}
                              </Typography>
                              {/* <Box
                                onClick={() => {
                                  handleChangeEditPlan();
                                }}
                                sx={{ cursor: "pointer" }}
                                component="img"
                                alt={
                                  content?.edit_pn_icon?.data?.attributes?.alt
                                }
                                src={
                                  content?.edit_pn_icon?.data?.attributes?.url
                                }
                                className={styles.edit_icon}
                              /> */}
                              <CreateIcon
                                sx={{
                                  color: "var(--primary_color)",
                                  cursor: "pointer",
                                }}
                                className={styles.edit_icon}
                                onClick={() => {
                                  handleChangeEditPlan();
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                        {currentLine?.isSimInHand && currentLine?.simId && !currentLine?.esim &&
                          <Box sx={{ display: { xs: "flex" } }}>
                            <Box sx={{ m: "5px 15px 0px 0px" }}>
                              <svg
                                width="22"
                                height="40"
                                viewBox="0 0 22 40"
                                fill="var(--primary_color)"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_2229_11244)">
                                  <path
                                    d="M19.3821 -0.0703125H2.67641C1.19852 -0.0703125 -0.00390625 1.13211 -0.00390625 2.61V37.2479C-0.00390625 38.7258 1.19852 39.9282 2.67641 39.9282H19.3821C20.86 39.9282 22.0624 38.7258 22.0624 37.2479V2.61C22.0624 1.13219 20.86 -0.0703125 19.3821 -0.0703125ZM2.67641 1.1668H19.3821C20.1778 1.1668 20.8254 1.8143 20.8254 2.61008V32.877H1.23313V2.61C1.23313 1.8143 1.88071 1.1668 2.67641 1.1668ZM9.88901 36.0934C9.88865 35.9433 9.91795 35.7946 9.97522 35.6558C10.0325 35.5171 10.1166 35.391 10.2228 35.2849C10.3289 35.1787 10.455 35.0946 10.5937 35.0373C10.7325 34.98 10.8812 34.9507 11.0313 34.9511C11.6622 34.9511 12.1694 35.4624 12.1694 36.0934C12.1694 36.7242 11.6622 37.2355 11.0313 37.2355C10.8812 37.2359 10.7325 37.2066 10.5937 37.1493C10.455 37.0921 10.3289 37.0079 10.2228 36.9018C10.1167 36.7957 10.0325 36.6696 9.97525 36.5309C9.91797 36.3921 9.88866 36.2435 9.88901 36.0934Z"
                                    fill="var(--primary_color)"
                                  />
                                  <path
                                    d="M16.8665 12.8567L13.6433 9.63349C13.4427 9.43204 13.2041 9.27234 12.9414 9.16361C12.6787 9.05488 12.3971 8.99927 12.1128 9.00001H7.66351C7.08983 9.00071 6.53987 9.22895 6.13427 9.63465C5.72867 10.0403 5.50056 10.5904 5.5 11.164V22.6895C5.50056 23.2631 5.72867 23.8132 6.13427 24.2189C6.53987 24.6246 7.08983 24.8528 7.66351 24.8535H15.336C15.9097 24.8528 16.4597 24.6246 16.8654 24.2189C17.2711 23.8132 17.4993 23.2632 17.5 22.6895V14.3867C17.4984 13.8132 17.2708 13.2635 16.8665 12.8567ZM16.5429 22.6889C16.5426 23.0089 16.4153 23.3158 16.1891 23.542C15.9628 23.7683 15.656 23.8956 15.336 23.8958H7.66351C7.34354 23.8954 7.03681 23.7681 6.81056 23.5419C6.58431 23.3156 6.45702 23.0089 6.4566 22.6889V11.164C6.45702 10.844 6.58429 10.5372 6.81053 10.3109C7.03676 10.0846 7.3435 9.95717 7.66351 9.95661H12.1122C12.2708 9.95597 12.4279 9.98689 12.5745 10.0476C12.721 10.1082 12.854 10.1975 12.9657 10.31L16.1889 13.5332C16.4137 13.758 16.5423 14.0689 16.5423 14.3862L16.5429 22.6889Z"
                                    fill="var(--primary_color)"
                                  />
                                  <path
                                    d="M13.6237 15.5938H9.37538C9.05223 15.594 8.74238 15.7225 8.51382 15.9509C8.28527 16.1794 8.15667 16.4892 8.15625 16.8124V20.9783C8.15667 21.3015 8.28527 21.6113 8.51382 21.8397C8.74238 22.0682 9.05223 22.1967 9.37538 22.1969H13.6237C13.9468 22.1965 14.2565 22.068 14.485 21.8396C14.7134 21.6111 14.8419 21.3014 14.8423 20.9783V16.8124C14.8419 16.4893 14.7134 16.1796 14.485 15.9511C14.2565 15.7227 13.9468 15.5942 13.6237 15.5938ZM13.8852 16.8124V18.4168H11.9779V16.5509H13.6237C13.7683 16.5509 13.8852 16.6678 13.8852 16.8124ZM9.11338 20.9783V16.8124C9.11338 16.6683 9.23083 16.5509 9.37538 16.5509H11.0213V21.2398H9.37538C9.30599 21.2398 9.23943 21.2123 9.19031 21.1633C9.14119 21.1142 9.11352 21.0477 9.11338 20.9783ZM13.6237 21.2398H11.9779V19.3734H13.8852V20.9783C13.8852 21.1224 13.7683 21.2398 13.6237 21.2398Z"
                                    fill="var(--primary_color)"
                                  />
                                  <path
                                    d="M8.18883 4.46555H13.871C14.2128 4.46555 14.4895 4.18867 14.4895 3.84703C14.4895 3.50539 14.2128 3.22852 13.871 3.22852H8.18883C7.84719 3.22852 7.57031 3.50539 7.57031 3.84703C7.57031 4.18867 7.84711 4.46555 8.18883 4.46555Z"
                                    fill="var(--primary_color)"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2229_11244">
                                    <rect
                                      width="22.0001"
                                      height="40"
                                      fill="white"
                                      transform="translate(0 0.0078125)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="baseline"
                              sx={{ mr: "5px" }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "var(--font_family_Medium)",
                                  color: "var(--text_color)",
                                }}
                                className={styles.title}
                              >
                                {content?.sih_text}
                              </Typography>
                              <Typography className={styles.desc}>
                                {currentLine?.simId}{" "}
                                <CreateIcon
                                  sx={{
                                    color: "var(--primary_color)",
                                    cursor: "pointer",
                                  }}
                                  className={styles.edit_icon}
                                  onClick={() => {
                                    let list = [];
                                    let simVerficationInitialState: simVerificationState = {
                                      isSuccess: false,
                                      error: false,
                                      pending: false,
                                    }
                                    list.push(simVerficationInitialState);
                                    dispatch(setMultiSIMVerificationState(list));
                                    setIccidComp(false);
                                  }}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        }
                      </Box>
                      <Box sx={{ textAlign: "center", mt: "25px", mb: "36px" }}>
                        <ButtonCust
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          onClick={() => {
                            handleStep(true),
                              GAAndFBEventsHandler(
                                gaCategory.activation,
                                imei_view?.nxt_btn
                              );
                            // gtmTagManager({
                            //   event: gaScreenNames?.simDetailsConfirmation,
                            // });
                          }}
                          sx={
                            {
                              // width: { xs: "250px", sm: "250px", md: "250px" },
                              // height: { md: "50px !important" },
                            }
                          }
                        >
                          {imei_view?.nxt_btn}
                        </ButtonCust>
                      </Box>
                    </Paper>
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{
                        width: { xs: "90%", sm: "100%" },
                        m: { xs: "30px auto", sm: "30px auto 30px auto" },
                        pl: { xs: "0px", sm: "4%" },
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                      alignItems={"baseline"}
                    >
                      <Box
                        component="span"
                        sx={{
                          fontFamily: "var(--font_family_Bold)",
                          fontWeight: "var(--font_weight_4) !important",
                        }}
                      >
                        {content?.msgs?.order_deliver_note}&nbsp;&nbsp;
                      </Box>
                      <Box component="p" textAlign={"start"}>
                        <Box
                          component="span"
                          sx={{
                            fontFamily: "var(--font_family_Regular)",
                            fontWeight: "var(--font_weight_0)",
                            fontSize: "16px",
                            lineHeight: "28px",
                            textAlign: { xs: "start", sm: "center" },
                            color: "var(--text_color)",
                          }}
                        >
                          {BrandNameUpdate(content?.msgs?.note_desc)}
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            fontFamily: "var(--font_family_Regular)",
                            fontWeight: "var(--font_weight_0)",
                            fontSize: "16px",
                            lineHeight: "28px",
                            textAlign: { xs: "start", sm: "center" },
                            color: "var(--text_color)",
                            width: { xs: "100%", sm: "80%" },
                            display: { xs: "inline", sm: "flow" },
                            paddingLeft: { xs: "5px", sm: "0px" },
                          }}
                        >
                          {BrandNameUpdate(content?.msgs?.note_desc1)}&nbsp;
                          <Box
                            component="a"
                            sx={{
                              color: "var(--primary_color)",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            href={content?.msgs?.clickhere_link}
                            target="_blank"
                          >
                            {content?.msgs?.clickhere_txt}
                          </Box>
                          .
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {modalStatus && (
                <IMEIFailureModal
                  modalFg={modalStatus}
                  closeHandler={() => setModalStatus(false)}
                  content={content}
                />
              )}
              {currentModal === "cardsModal" && (
                <ManageCard
                  cardData={getCCDetails}
                  showCardChangeModal={showCardChangeModal}
                  setShowCardChangeModal={showCardChangeModalHandler}
                  isModalRequired={true}
                  pageName={"activation"}
                  isDescription={false}
                  payType={true}
                  ProceedPayment={ProceedPayment}
                  esimPsimSwap={true}
                  setSelectedCard={setSelectedCard}
                  activationOptional={true}
                />
              )}

              {currentModal === "chooseType" && !deviceSimInHand && (
                <ChangeLinePreferenceDialog
                  open={true}
                  onClose={closeOneTimeCharge}
                  content={{ ...simPrefenceContent, ...content }}
                  onConfirm={chooseModalResult}
                  simType={simType}
                  setSimType={setSimType}
                  pageName={"activation"}
                  currentLine={currentLine}
                  formik={formik}
                  numChangeHandler={numChangeHandler}
                  onSubmitSimHandler={onSubmitSimHandler}
                  simInHandContentState={simInHandContentState}
                  handleClose={handleClose}
                  error={multiSimVerificationState?.[0]?.error}
                  success={multiSimVerificationState?.[0]?.isSuccess}
                  pending={multiSimVerificationState?.[0]?.pending}
                  simCost={
                    kitBasePrice +
                    (currentLine?.selectedShippingMethod?.shippingAmount || 0)
                  }
                />
              )}
            </Box>
          )}
        </>
      )}
      {currentModal === "oneTimeChrg" && (
        <OneTimeChargeComp
          //   onClose={closeOneTimeCharge}
          showShipmentCharges={isShipmentCharges}
          changeCard={changeCard}
          changeAddress={changeAddress}
          latestAddress={addressData}
          loaderHandler={setBtnLoader}
          btnLoader={btnLoader}
          currentLine={currentLine}
          content={content}
          sendSimOptions={sendSimOption}
          kitBasePrice={kitBasePrice}
          handleNavigateNext={handleNavigateNext}
        />
      )}
      {currentModal === "addressDetails" && (
        <AddShippingAddressDialogComp
          open={true}
          onClose={SaveAddress}
          content={content}
          updateAddress={(e: any) => {
            SaveAddress(e);
          }}
          pageName="activation"
        />
      )}
      <AddLine_ChangePlan
        isShowModal={openModal}
        modalType={modalType}
        setOpenModal={setOpenModal}
        handleChangePlan={handleChangePlan}
        currentPlan={{
          displayName: currentLine.reachPlanDisplayName,
        }}
        isEsim={provideEdit}
      />
    </>
  );
};
export default T7ActivationStep1;
