import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { Divider, ButtonCust } from "../../../widgets";
import styles from "./PayNow.module.scss";
import { BillText, CardTypes } from "../../../../enums";
import { getIsCardExpired } from "../../../../utils/commonFunctions/ReusableFunctions";
import envConfig from "../../../../config/env.config.json";
import AutoPaySaveCard from "./AutoPaySaveCard";
import {
  showPayFormFn,
  showSaveCardAlertDialog,
} from "../../../../features/activation/activationSlice";

const ActivationPayNow = (props: any) => {
  const {
    currentLine,
    payNowConfirm,
    payBtnLoader,
    manageCardFg,
    content,
    autoPayCard,
    setPayBtnLoader,
  } = props;
  const dispatch = useAppDispatch();
  const { isSimInHand = false } = currentLine || {};

  const { monthInAdvanceData, enablePayForm } = useAppSelector(
    (state: any) => state?.activation || {}
  );

  const {
    title,
    info_txt,
    chng_btn,
    due_txt,
    fee_txt,
    discount_txt,
    pay_btn,
    visa,
    discover,
    master_card,
    jcb,
    american_ex,
    promo_img,
    num_mask,
    sub_title,
  } = content?.pay_now;
  let isCardExpired = false;
  let actData: any[] = [
    {
      text: currentLine?.reachPlanDisplayName,
      value: monthInAdvanceData?.amount ?? "-",
      id: 1,
    },
    {
      text: discount_txt,
      value:
        monthInAdvanceData?.promoDiscount > 0
          ? monthInAdvanceData?.promoDiscount
          : null,
      id: 3,
    },
    {
      text: fee_txt,
      value: monthInAdvanceData?.taxAmount ?? "-",
      id: 2,
    },
    {
      text: due_txt,
      value: monthInAdvanceData?.amountToPay ?? "-",
      id: 4,
    },
  ];

  if (autoPayCard?.dateOfExpiry) {
    isCardExpired = getIsCardExpired(autoPayCard);
  }
  return (
    <Stack>
      {currentLine?.reachPlanId && (
        <>
          <Box
            className={styles.activation_paynow_card}
            m="60px auto 50px"
            sx={{ width: { xs: "95%", sm: "50%", md: "45%", lg: "45%" } }}
          >
            <Box
              className={styles.main_container}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  py: { xs: "15px" },
                }}
              >
                {/* <Box
                  component="img"
                  alt={"circular_loader"}
                  src={content?.payment_modal_icon?.data?.[0]?.attributes?.url}
                  sx={{
                    paddingRight: "16px",
                    paddingTop: { xs: "10px", sm: "20px" },
                    width: "30px",
                  }}
                /> */}
                <Typography
                  className={styles.heading}
                  sx={{ mt: { xs: "10px", sm: "20px" } }}
                >
                  {title}
                </Typography>
              </Box>
              <hr style={{ border: "1px solid #F8F8F8", width: "100%" }}></hr>
              <Box sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontFamily: "var(--font_family_Regular)",
                    // my:{xs:"25px"},
                    mt: { xs: "25px" },
                    mb: { xs: "18px" },
                    color: "var(--text_color)",
                  }}
                >
                  {currentLine?.esim
                    ? content?.step_txt
                    : content?.step_txt_next}
                </Typography>
              </Box>
              {sub_title ? (
                <Typography
                  className={styles.sub_title}
                  sx={{ marginBottom: "20px", padding: "0 !important" }}
                >
                  {sub_title}
                </Typography>
              ) : null}
              {!monthInAdvanceData ? (
                <Box
                  className={styles.loaderCheck}
                  sx={{ marginBottom: "20px" }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {actData?.map((each) => (
                    <>
                      {each?.id === 3 && each?.value <= 0 ? null : (
                        <Box
                          //   className={styles.row}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p="2px"
                          my="10px"
                          sx={{
                            width: {
                              xs: "88%",
                              sm: "75%",
                              md: "60%",
                              lg: "62%",
                            },
                          }}
                          key={each.id}
                        >
                          <Typography
                            className={styles.row_text}
                            sx={{
                              fontFamily: `${
                                each.text === BillText?.TOTAL_AMOUNT_DUE
                                  ? "var(--font_family_Semibold)"
                                  : "var(--font_family_Regular)"
                              }`,
                            }}
                          >
                            {each.text}
                          </Typography>
                          <Typography
                            className={styles.row_value}
                            sx={{
                              fontFamily: `${
                                each.text === BillText?.TOTAL_AMOUNT_DUE
                                  ? "var(--font_family_Semibold)"
                                  : "var(--font_family_Regular)"
                              }`,
                            }}
                          >
                            {each.id === 3
                              ? `-${GetCurrencyFixedToTwo(each.value)}`
                              : GetCurrencyFixedToTwo(each.value)}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ))}
                  {monthInAdvanceData?.couponName && (
                    <Box
                      className={styles.promo_coupon}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p="16px"
                      m="20px"
                      sx={{
                        width: {
                          xs: "80%",
                          sm: "75%",
                          md: "60%",
                          lg: "45%",
                        },
                      }}
                    >
                      <img
                        src={promo_img?.data?.attributes?.url}
                        alt={promo_img?.data?.attributes?.alternativeText}
                        style={{
                          width: "22px",
                          height: "22px",
                          marginRight: "10px",
                        }}
                      />
                      {monthInAdvanceData?.couponName}
                    </Box>
                  )}
                  {isSimInHand && envConfig.IS_AUTOPAY_ENABLED && (
                    <AutoPaySaveCard
                      styles={styles}
                      currentLine={currentLine}
                      setPayBtnLoader={setPayBtnLoader}
                      content={content}
                    />
                  )}

                  {/* {!isSimInHand && (
                    <Typography
                      className={styles.text1}
                      mb="14px"
                      //   sx={{
                      //     width: { xs: "90%", sm: "75%", md: "60%", lg: "45%" },
                      //   }}
                    >
                      {info_txt}
                    </Typography>
                  )} */}
                  {autoPayCard?.ccNumberMask && (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={styles.image_text_container}
                        padding="14px"
                        border="2px solid #F8F8F8"
                        borderRadius="6px"
                      >
                        <img
                          src={
                            autoPayCard?.type?.toUpperCase() === CardTypes?.VISA
                              ? visa?.data?.attributes?.url
                              : autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.MASTERCARD
                              ? master_card?.data?.attributes?.url
                              : autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.JCB
                              ? jcb?.data?.attributes?.url
                              : autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.AMERICANEXPRESS ||
                                autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.AMEX
                              ? american_ex?.data?.attributes?.url
                              : discover?.data?.attributes?.url
                          }
                          alt={
                            autoPayCard && autoPayCard.type === CardTypes.VISA
                              ? visa?.data?.attributes?.alternativeText
                              : american_ex?.data?.attributes?.alternativeText
                          }
                          style={{ width: "42px", height: "27px" }}
                        />
                        <Typography className={styles.text2} pl="10px">
                          {num_mask} {autoPayCard?.ccNumberMask}
                        </Typography>
                        <Typography
                          className={styles.text3}
                          ml="10px"
                          onClick={manageCardFg}
                        >
                          {chng_btn}
                        </Typography>
                      </Box>
                    </>
                  )}
                  {autoPayCard?.ccNumberMask ||
                  (isSimInHand &&
                    !envConfig?.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND) ? (
                    <>
                      <ButtonCust
                        variantType={envConfig.PRIMARY_BUTTON_TYPE}
                        sx={{ m: "30px 0 40px 0", padding: "13px 23px" }}
                        onClick={
                          // isSimInHand &&
                          // !envConfig?.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND
                          //   ? () => {
                          //       if (enablePayForm) {
                          //         dispatch(showPayFormFn(true));
                          //         setPayBtnLoader(true);
                          //       } else {
                          //         dispatch(showSaveCardAlertDialog(true));
                          //       }
                          //     }
                          //   :
                          payNowConfirm
                        }
                        loading={payBtnLoader}
                        disabled={isCardExpired}
                      >
                        {pay_btn}
                      </ButtonCust>
                    </>
                  ) : (
                    <Box sx={{ pt: 4 }}></Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Stack>
  );
};
export default ActivationPayNow;
