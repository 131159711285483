import { Box, Checkbox, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonCust, SelectCust, SimpleModal } from "../../../widgets";
import styles from "./OneTimeCharge.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { type4TaxCalculation } from "../../../../features/account/accountSlice";
import {
  shipmentOrderNew,
  shipmentOrder,
} from "../../../../features/trackorder/trackorderSlice";
import { useNavigate } from "react-router-dom";
import { UpdateCustomerSource } from "../../../../features/checkout/checkoutSlice";
import { CardTypes } from "../../../../enums";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import EnvConfig from "../../../../config/env.config.json";
import { getIsCardExpired } from "../../../../utils";

type Props = {
  onClose?: any;
  changeCard: any;
  changeAddress: any;
  latestAddress: any;
  loaderHandler: any;
  btnLoader: boolean;
  currentLine: any;
  content: any;
  kitBasePrice: any;
  sendSimOptions: any;
};
const { header_txt, card_section, main_class } = styles;
function PaymentCartComp(props: Props) {
  const {
    onClose,
    changeCard,
    changeAddress,
    latestAddress,
    loaderHandler,
    btnLoader,
    currentLine,
    content,
    sendSimOptions,
    kitBasePrice,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let customerDetails: any = localStorage.getItem("customerDetail");
  let storedDeliveryType = localStorage.getItem("oneTimeChargeDeliveryType");
  customerDetails = customerDetails ? JSON.parse(customerDetails) : null;
  const [sendSimOption, setSimOption] = useState(
    sendSimOptions?.[0] ? sendSimOptions : []
  );
  const { getCCDetails, type4Tax, tax4Loader } = useAppSelector(
    (state: any) => state?.account || {}
  );
  const {
    title,
    card_txt,
    change_add,
    change_txt,
    confirm_pay,
    tax_txt,
    total_txt,
    wel_kit,
    chkbox_txt,
    err_msg,
    visa,
    american_exp,
    master_card,
    jcb,
    discover,
    num_mask,
  } = content?.tm_chgs || {};

  const { creditCardData } = useAppSelector(
    (state: any) => state?.billing || {}
  );
  const { ccuuid } = creditCardData;

  const { totalTax } = type4Tax || {};

  const currentCard = getCCDetails?.filter(
    (val: any) => val?.isAutoPay || val?.isDefault
  );
  let isCardExpired = false;
  if (currentCard?.dateOfExpiry) {
    isCardExpired = getIsCardExpired(currentCard);
  }
  const [deiveryType, setDeliveryType] = useState<any>(null);
  const [currentDelivery, setCurrentDelivery] = useState<any>(null);
  const [deliveryCost, setDeliveryCost] = useState<any>(null);
  const [tick, setTick] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [addressData, setAddressData] = useState<any>(
    latestAddress ? latestAddress : {}
  );

  const [chargeData, setChargeData] = useState([
    { label: wel_kit, value: 0, id: 1 },
    { id: 2 },
    { label: tax_txt, value: totalTax, id: 3 },
    { label: total_txt, value: 0, id: 4 },
  ]);

  useEffect(() => {
    latestAddress === null &&
      setAddressData({ ...customerDetails?.addresses[1] });
    fetchData();
  }, [kitBasePrice]);
  useEffect(() => {
    const newAddress: any = {
      address1: latestAddress?.address1,
      city: latestAddress?.city,
      country: latestAddress?.country,
      zip: latestAddress?.zip,
      state: latestAddress?.state,
    };
    latestAddress && setAddressData({ ...addressData, ...newAddress });
  }, [latestAddress]);
  useEffect(() => {
    const totalDueAmt = chargeData?.[0]?.value + totalTax + deliveryCost;
    const resetData: any = [...chargeData];
    resetData[2].value = totalTax;
    resetData[3].value = totalDueAmt;
    setChargeData(resetData);
  }, [totalTax, deliveryCost]);

  useEffect(() => {
    const firstOb: any = sendSimOption?.[0];
    firstOb &&
      callTaxCalculation(
        storedDeliveryType ? storedDeliveryType : firstOb?.value
      );
  }, [sendSimOption]);
  const fetchData = async () => {
    let firstObj: any;

    firstObj = sendSimOptions?.[0];
    setSimOption(sendSimOptions);
    setDeliveryType(firstObj?.value);
    setCurrentDelivery(firstObj);

    const resetData: any = [...chargeData];
    resetData[0].value = kitBasePrice;
    setChargeData(resetData);
    setDeliveryCost(firstObj?.cost);
  };
  const onChangeHandler = (e: any) => {
    localStorage.setItem("oneTimeChargeDeliveryType", e);
    setDeliveryType(e);
    callTaxCalculation(e);
  };
  const callTaxCalculation = (selectedServiceType: any) => {
    let x = sendSimOption?.filter((vals: any) => {
      return selectedServiceType === vals?.serviceType;
    });
    setCurrentDelivery(x);
    const cost: any = x?.[0] || null;
    const payload = {
      address: { ...customerDetails?.addresses[1], type: "type1" },
      amount: cost?.shippingAmount,
      type: 1,
    };
    delete payload.address.phone;
    setDeliveryCost(cost?.shippingAmount);
    dispatch(type4TaxCalculation(payload));
  };
  const confirmToPay = () => {
    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 4);
    const payload: any = {
      cId: customerDetails.id,
      reachCarrierId: currentDelivery?.[0]?.reachCarrierId,
      deliveryDays: currentDelivery?.[0]?.deliveryDays,
      serviceType: currentDelivery?.[0]?.serviceType,
      serviceCode: currentDelivery?.[0]?.serviceCode,
      carrierCode: currentDelivery?.[0]?.carrierCode,
      shipmentType: "PHYSICAL",
      category: "SIM",
      shippingAmount: currentDelivery?.[0]?.cost,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: totalTax,
      taxId: type4Tax?.taxId,
      taxInfo: type4Tax,
      totalAmount: totalTax + currentDelivery?.[0]?.cost || 0,
      ccUUID: currentCard?.[0]?.uuid,
      shipTo: {
        city: addressData?.city,
        country: customerDetails?.addresses?.[0]?.country,
        name: customerDetails?.addresses?.[0]?.name,
        phone: customerDetails?.addresses?.[0]?.phone,
        postalCode: addressData?.zip,
        residential: "false",
        state: addressData?.state,
        street1: addressData?.address1,
      },
      items: [
        {
          quantity: 1,
          unitPrice: 0,
        },
      ],
    };
    let shipmentOrderApi = content?.chg_is_lbl
      ? shipmentOrderNew
      : shipmentOrder;
    dispatch(shipmentOrderApi(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        loaderHandler(false);
        const py = {
          activationJourneyStatus: "IN_PROGRESS",
          esim: false,
          physicalSimsOrdered: 1,
          simType: "PHYSICAL",
          isSimInHand: false,
          simId: "",
        };
        dispatch(
          UpdateCustomerSource({
            customerId: currentLine.id,
            data: py,
          })
        );

        setTimeout(() => {
          navigate(RoutingURLs.trackOrder);
        }, 1000);
      } else {
        setErrorMsg(true);
      }
      loaderHandler(false);
    });
    loaderHandler(true);
  };
  const getCardImageUrl = (cardType: any) => {
    switch (cardType) {
      case CardTypes.VISA:
        return visa?.data?.attributes?.url;
      case CardTypes.MASTERCARD:
        return master_card?.data?.attributes?.url;
      case CardTypes.JCB:
        return jcb?.data?.attributes?.url;
      case CardTypes.AMERICANEXPRESS:
      case CardTypes.AMEX:
        return american_exp?.data?.attributes?.url;
      default:
        return discover?.data?.attributes?.url;
    }
  };
  return (
    <>
      <Box
        sx={{
          width: { xs: "90%", sm: "50%" },
          margin: "0 auto",
          background: "var(--white)",
          marginY: "60px",
          borderRadius: "10px",
          maxWidth: content?.step_txt_next ? "600px" : "unset",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "2px solid var(--lightgrey_6)",
            p: "33px 0",
          }}
        >
          {/* <Box
            component="img"
            alt={content?.swap_shipping_charge_icon?.data?.attributes?.url}
            src={
              content?.swap_shipping_charge_icon?.data?.attributes?.url || ""
            }
            mr="10px"
            className={styles?.profile_img}
            width="51px"
            height="35px"
          /> */}
          <Box
            sx={{
              fontFamily: "var(--font_family_Bold)",
              fontWeight: "var(--font_weight_4)",
              fontSize: "24px",
              lineHeight: "40px",
              color: "var(--black)",
            }}
          >
            {content?.swap_shipping_charge}
          </Box>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Box component="p">{content?.swap_step_txt}</Box>
          <Box component="p">{content?.swap_shipping_charge_txt}</Box>
        </Box>
        <Box
          sx={{
            pl: {
              xs: content?.chg_is_lbl ? "0px" : "50px",
              sm: content?.chg_is_lbl ? "80px" : "50px",
            },
            width: {
              xs: content?.chg_is_lbl ? "100%" : "inherit",
              md: content?.chg_is_lbl ? "80%" : "inherit",
            },
            pr: {
              xs: "0px",
              sm: content?.chg_is_lbl ? "80px" : "0px",
            },
            my: "20px",
          }}
        >
          {content?.chg_is_lbl
            ? chargeData
                ?.filter((each: any) => each.id !== 1)
                ?.map((each: any) => {
                  const { id } = each;
                  return (
                    <>
                      <Box
                        className={styles.row}
                        display="flex"
                        width={{ sm: "88%" }}
                        justifyContent="space-between"
                        alignItems="center"
                        p="12px"
                        key={id}
                      >
                        {id !== 2 ? (
                          <>
                            <Typography
                              className={styles?.row_label}
                              sx={{
                                fontFamily:
                                  id === 4
                                    ? "var(--font_family_Semibold) !important"
                                    : "var(--font_family_Regular)",
                                fontWeight:
                                  id === 4
                                    ? "var(--font_weight_2) !important"
                                    : "var(--font_weight_0)",
                              }}
                            >
                              {each.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily:
                                  id === 4
                                    ? "var(--font_family_Semibold) !important"
                                    : "var(--font_family_Regular)",
                                fontWeight:
                                  id === 4
                                    ? "var(--font_weight_2) !important"
                                    : "var(--font_weight_0)",
                              }}
                              className={styles.row_label}
                            >
                              {tax4Loader && id > 1 ? (
                                <Skeleton style={{ width: "50px" }} />
                              ) : (
                                id > 1 && GetCurrencyFixedToTwo(each.value)
                              )}
                              {id <= 1 && GetCurrencyFixedToTwo(each.value)}
                            </Typography>
                          </>
                        ) : (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            className={styles.selectotc}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography className={styles?.row_label}>
                              Shipping charges
                            </Typography>
                            <Typography className={styles.row_label}>
                              {GetCurrencyFixedToTwo(deliveryCost)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })
            : chargeData?.map((each: any) => {
                const { id } = each;
                return (
                  <>
                    <Box
                      className={styles.row}
                      display="flex"
                      width={{ sm: "88%" }}
                      justifyContent="space-between"
                      alignItems="center"
                      p="12px"
                      key={id}
                    >
                      {id !== 2 ? (
                        <>
                         <Typography
                              className={styles?.row_label}
                              sx={{
                                fontFamily:
                                  id === 4
                                    ? "var(--font_family_Semibold) !important"
                                    : "var(--font_family_Regular)",
                                fontWeight:
                                  id === 4
                                    ? "var(--font_weight_2) !important"
                                    : "var(--font_weight_0)",
                              }}
                            >
                              {each.label}
                            </Typography>
                          <Typography className={styles.row_value}>
                            {tax4Loader && id > 1 ? (
                              <Skeleton style={{ width: "50px" }} />
                            ) : (
                              id > 1 && GetCurrencyFixedToTwo(each.value)
                            )}
                            {id <= 1 && GetCurrencyFixedToTwo(each.value)}
                          </Typography>
                        </>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          className={styles.selectotc}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          Shipping charges
                          <Typography className={styles.row_value}>
                            {GetCurrencyFixedToTwo(deliveryCost)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </>
                );
              })}
        </Box>
        <Box>
          {
            <>
              <Box
                display="flex"
                justifyContent="center"
                border="1px solid #D9D9D9"
                margin="auto"
                borderRadius="5px"
                sx={{
                  width: {
                    xs: "85%",
                    md: "65%",
                  },
                  padding: "15px",
                }}
              >
                <img
                  src={
                    currentCard?.[0].type
                      ? getCardImageUrl(currentCard[0].type.toUpperCase())
                      : null
                  }
                  alt={
                    currentCard && currentCard[0].type === CardTypes.VISA
                      ? visa?.data?.attributes?.alternativeText
                      : american_exp?.data?.attributes?.alternativeText
                  }
                  style={{ width: "42px", height: "27px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ wordBreak: "break-all" }}>
                    <Typography
                      className={styles.card_text}
                      pl="10px"
                      fontFamily={
                        content?.step_txt_next
                          ? "var(--font_family_Regular)"
                          : "var(--font_family_Medium)"
                      }
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "12px !important",
                          sm: "14px !important",
                        },
                        lineHeight: {
                          xs: "25px !important",
                          sm: "28px !important",
                        },
                      }}
                    >
                      {num_mask} {currentCard?.[0]?.ccNumberMask}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      fontWeight: "var(--font_weight_2)",
                      lineHeight: "28px",
                      color: "var(--primary_color) !important",
                      cursor: "pointer",
                      fontSize: { xs: "12px", sm: "16px !important" },
                    }}
                    ml="10px"
                    onClick={() => changeCard(addressData)}
                  >
                    {change_txt}
                  </Typography>
                </Box>
              </Box>
            </>
          }
          <Box
            sx={{
              px: { xs: 0, sm: 4 },
              textAlign: "center",
            }}
          >
            <ButtonCust
              sx={{
                m: "40px 0 53px 0",
                padding: "13px 23px",
                width: {
                  xs: content?.step_txt_next ? "250px" : "fit-content",
                  sm: content?.step_txt_next ? "312px" : "fit-content",
                },
              }}
              onClick={confirmToPay}
              loading={btnLoader}
              disabled={isCardExpired}
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            >
              {confirm_pay}
            </ButtonCust>
          </Box>
          {errorMsg && (
            <Box
              sx={{
                color: " var(--danger)",
                textAlign: "center",
                fontFamily: "var(--font_family_Bold)",
                padding: "20px",
              }}
            >
              {err_msg}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default PaymentCartComp;
